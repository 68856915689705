import React from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Form, Button, DatePicker } from 'antd';
import { GetPartnerMonthlyFeet } from "../../../action/authAction";
import intl from 'react-intl-universal';
import moment from 'moment';
import { getLangType } from "../../../util/function";
let currency_symbol = sessionStorage.getItem("currency_symbol")

const { MonthPicker } = DatePicker;
class orderChargeFeeList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [], meta: "", date: "" };
        this.columns = [
            {
                title: "ID",
                dataIndex: 'id',
                width: '5%',
                align: "center",
            },
            {
                title: intl.get("home.table.ordernumber"),
                width: '15%',
                align: "center",
                render: (record) => {
                    return <Button
                        type='link'
                        onClick={() => {
                            this.props.history.push("/OrderDetail/" + record.order.id)
                        }}
                    >
                        {record.spu_discount_id && "【Deal】"}
                        {record.is_user_coupon && "【Coupon】"}
                        {record.order && record.order.order_sn}
                    </Button>
                }
            },
            {
                title: intl.get('nav.CostFlow.name'),
                dataIndex: 'name',
                width: '15%',
                align: "center",
            },
            {
                title: intl.get('nav.CostFlow.cost'),
                dataIndex: 'free',
                width: '10%',
                align: "center",
                render: (record) => {
                    let value = ""
                    if (!!record[0] && record[0] === '-') {

                        // value = "-" + currency_symbol + record.slice(1)
                        value = currency_symbol + record.slice(1)
                    } else {
                        value = currency_symbol + record
                    }
                    return <span>{value}</span>
                }
            },
            {
                title: intl.get('nav.PayRoute'),
                dataIndex: 'payee',
                width: '10%',
                align: "center",
                // render: (record) => {
                //     // return <span>{currency_symbol}{record}</span>
                // }
            },
            {
                title: intl.get('subscription.status'),
                dataIndex: "type",
                width: '10%',
                align: "center",
                render: (record) => {
                    return <span>{record === "order_charge_money" ? intl.get("home.table.useStatus.paid") : intl.get("home.table.useStatus.refunded")}</span>
                }
            },
            {
                title: intl.get("nav.goodsManagement.promotionalgoods.isBuy"),
                dataIndex: "order",
                width: '10%',
                align: "center",
                render: (record) => {
                    return record.is_already_buy === 1 ? <span style={{ color: "green" }}>Yes</span> : <span style={{ color: "red" }}>No</span>
                }
            },
            {
                title: intl.get('order.table.remark'),
                dataIndex: 'explain',
                width: '15%',
                align: "center",
            },

            {
                title: intl.get('package.created'),
                dataIndex: 'created_at',
                width: '15%',
                align: "center",
                render: (record) => {
                    return record.date.substring(0, record.date.length - 7)
                }
            },

        ];
    }
    componentDidMount() {
        this.HandleGetData(1)
    }

    async HandleGetData(page) {
        let res = await GetPartnerMonthlyFeet(page, "&type=order_charge_money,order_charge_money_refund", this.state.date)
        this.setState({ data: res.data.data, meta: res.data.meta.pagination })
    }

    expandedRowRender = (record) => {
        const columns = [
            {
                title: intl.get('nav.CostFlow.name'),
                dataIndex: "name",
                width: '10%',
                align: "center"
            },
            {
                title: intl.get('nav.CostFlow.feeType'),
                dataIndex: "rate",
                width: '10%',
                align: "center",
                render: (record) => {
                    let index = record && record.indexOf("%")
                    if (index) {
                        return index > 0 ? record : currency_symbol + record
                    }

                }
            },
            {
                title: intl.get('nav.CostFlow.cost'),
                width: '10%',
                align: "center",
                render: (record) => {
                    return record.symbol + currency_symbol + record.money
                }
            },


        ];
        const data = record.charge_data;
        return <Table columns={columns} className="extraTable" bordered rowKey={record => record.id} dataSource={data} pagination={false} />;
    };

    render() {
        let { meta } = this.state
        return (
            <div>
                <div style={{ marginBottom: "5px", display: 'flex', justifyContent: "space-between" }}>
                    <div />
                    <MonthPicker
                        placeholder="Select month"
                        allowClear
                        onChange={(e) => {
                            let date = moment(e).format('YYYY-MM')
                            this.setState({ date }, () => {
                                this.HandleGetData(1)
                            })
                        }} />
                </div>
                <Table
                    rowKey={record => record.id}
                    // bordered
                    dataSource={this.state.data}
                    columns={this.columns}
                    expandedRowRender={this.expandedRowRender}
                    pagination={{
                        pageSize: meta.per_page,
                        total: meta.total
                    }}
                    onChange={(e) => {
                        this.HandleGetData(e.current)
                    }}
                />
            </div>


        );
    }
}
export default withRouter(Form.create()(orderChargeFeeList))

