import React from 'react'
import { withRouter } from 'react-router-dom'
import 'braft-editor/dist/index.css'
import BraftEditor from 'braft-editor'
import { Form, Input, Button, Select, message, Switch, Upload, Icon, Checkbox, Row, Col, Modal, Tooltip } from 'antd';
import Table from "./table"
import { getCategory, getGoodsSpecification, AddGoods, getGoodsAnnexTypeList } from "../../action/goodAction";
import { getPartner, getLangList } from "../../action/authAction";
import intl from 'react-intl-universal';
import "../../index.css"
import { getLangType } from "../../util/function";

let currency_symbol = sessionStorage.getItem("currency_symbol")
const { TextArea } = Input;
function beforeUpload(file) {
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
        message.destroy()
        message.error(intl.get('file.size.placehorder'));
    }
    return isLt1M;
}


function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

class NewGoods extends React.Component {
    // 构造
    constructor(props) {
        super(props);
        // 初始状态
        this.state = {
            // GoodsTypeData: [],
            CategoryData: "",
            GoodsSpecificationData: "",
            // GoodsArrData: [],
            openShop: 1,
            recommend: 0,
            imageUrl: "",
            goodImage: "",
            loading: false,
            GoodsType: "",
            Category: "",
            DefaultCategory: "",
            GoodsSpecificationItem: "",
            GoodsArr: "",
            dataSource: [],
            specification_id: "",
            ChooleSpecification: false,
            ChooleGoodsAnnexType: false,
            editorState: BraftEditor.createEditorState(null),
            price: 0,
            AnnexTypeData: '',
            ChooseAnnexTypeData: '',
            isModalVisible: false,
            ChooseDefaultData: [],
            defaultPrice: "",
            editingKey: false,
            is_open_goods_annex: false,
            is_sku: false,
            is_open_recommend: false,
            disable_language: true,
            is_gst: true,
            is_disable_language: false,
        };
    }





    async componentDidMount() {
        let CategoryData = await getCategory()
        let GoodsSpecificationData = await getGoodsSpecification()
        let AnnexTypeData = await getGoodsAnnexTypeList()
        let DefaultCategory = CategoryData.data.data.find(el => el.is_default === 1)
        let res = await getPartner()
        let data = res.data
        let language = await getLangList()
        let languageData = language.data.data
        let languageList = []
        for (let i in languageData) {
            if (languageData[i].is_use === 1) {
                languageList.push(languageData[i])
            }
        }
        this.setState({
            CategoryData: CategoryData.data.data,
            AnnexTypeData: AnnexTypeData.data.data,
            GoodsSpecificationData: GoodsSpecificationData.data.data,
            DefaultCategory: DefaultCategory,
            Category: DefaultCategory && DefaultCategory.id,
            is_open_goods_annex: data.is_open_goods_annex == 1 ? true : false,
            is_sku: data.is_sku == 1 ? true : false,
            is_open_recommend: data.is_open_recommend == 1 ? true : false,
            is_disable_language: languageList.length === 1 ? true : false
        })

    }


    componentWillUnmount() {
        this.setState = () => false;
    }


    HandleGetTableData = (data, ids, item, editingKey) => {
        if (editingKey) {
            this.setState({
                editingKey: true
            })
        } else {
            this.setState({
                editingKey: false
            })
        }
        var temp = []; //一个新的临时数组
        for (let i in ids) {
            if (temp.indexOf(ids[i]) === -1) {
                temp.push(ids[i]);
            }
        }
        this.setState({
            dataSource: data,
            specification_id: temp
        })
        for (let k in data) {
            if (parseInt(data[k].isDefault) === 1) {
                this.setState({ defaultPrice: data[k].price })
            }
        }
        if (item && item.isDefault == 1) {
            this.setState({
                defaultPrice: item.price
            })
            this.props.form.setFieldsValue({ price: item.price })
        }
    }



    handleSubmit = (e) => {
        e.preventDefault();
        const { specification_id, dataSource, ChooseAnnexTypeData, ChooleGoodsAnnexType, editorState } = this.state
        this.props.form.validateFieldsAndScroll((err, values) => {
            values.recommend = this.state.recommend
            values.category_id = this.state.Category
            values.state = this.state.openShop
            values.disable_language = this.state.disable_language ? "0" : "1"
            values.is_gst = this.state.is_gst ? "1" : "0"
            values.goods_specification_ids = specification_id && specification_id.toString()
            values.detail = editorState && editorState.toHTML()
            let SubData = []
            if (dataSource.length > 0) {
                for (let k in dataSource) {
                    if (values.rrp && parseInt(values.rrp) < parseInt(dataSource[k].price)) {
                        message.error(intl.get("nav.goodsManagement.rrp_cannot_price"))
                        return false
                    }
                    let sku = "skus[" + dataSource[k].Id + "]"
                    SubData[sku + "[price]"] = dataSource[k].price.toString()
                    SubData[sku + "[stock]"] = dataSource[k].stock.toString()
                    SubData[sku + "[is_default]"] = dataSource[k].isDefault
                }
            }
            let goods_annex_types = []

            for (let i in ChooseAnnexTypeData) {
                let goods_annex_type_item_ids = []
                if (ChooseAnnexTypeData[i] && ChooseAnnexTypeData[i].goodsAnnexTypeItem && ChooseAnnexTypeData[i].goodsAnnexTypeItem.data) {
                    let goodsAnnexTypeItem = ChooseAnnexTypeData[i].goodsAnnexTypeItem.data
                    for (let k in goodsAnnexTypeItem) {
                        goods_annex_type_item_ids.push(goodsAnnexTypeItem[k].id)
                    }
                }
                goods_annex_types[i] = { "goods_annex_type_id": ChooseAnnexTypeData[i].id, "goods_annex_type_item_ids": goods_annex_type_item_ids }
            }
            if (ChooleGoodsAnnexType) {
                values["goods_annex_types"] = JSON.stringify(goods_annex_types)
            }
            Object.assign(values, SubData)
            if (this.state.editingKey) {
                message.error(intl.get("nav.goodsManagement.save_sku_first"))
                return false
            }
            if (!err) {
                if (values.rrp) {
                    if (parseInt(values.rrp) < parseInt(values.price)) {
                        message.error(intl.get("nav.goodsManagement.rrp_cannot_price"))
                        return false
                    }
                }
                this.SubmitData(values)

            }
        });
    };

    async SubmitData(data) {
        let goodImage = this.state.goodImage
        let formData = new FormData();
        let imgposition = [];

        for (let i in data) {
            if (data[i]) {
                formData.append(i, data[i])
            }
        }
        if (!data.rrp) {
            formData.append("rrp", "")
        }

        if (!this.state.goodImage) {
            message.error(intl.get('goods.list.img.horder'))
            return
        }
        if (!this.state.Category) {
            message.error(intl.get('goods.list.classification'))
            return
        }
        if (goodImage && goodImage.length > 0) {
            for (let i in goodImage) {
                imgposition.push(i);
                formData.append('image_files[' + i + ']', goodImage[i].originFileObj);
            }
        }
        if (imgposition.length > 0) {
            formData.append('img_data', imgposition.toString());
        }
        let price = this.props.form.getFieldValue("price")
        let { defaultPrice } = this.state
        if (parseFloat(defaultPrice)) {
            if (parseFloat(defaultPrice) === parseFloat(price)) {
                AddGoods(formData).then(res => {
                    this.props.history.push("/goodsList")
                })
            } else {
                message.error(intl.get("goods.list.price.error"))
            }
        } else {
            AddGoods(formData).then(res => {
                this.props.history.push("/goodsList")
            })
        }


    }


    checkPsd(rule, value, callback) {
        // let abstract = this.props.form.getFieldValue('abstract');
        let len = 0;
        for (let i = 0; i < value.length; i++) {
            if (value.charCodeAt(i) > 127 || value.charCodeAt(i) == 94) {
                len += 2;
            } else {
                len++;
            }
        }
        if (len > 80) {
            callback(new Error(intl.get("goods.list.name.horder.max")));
        } else {
            // this.props.form.resetFields(['abstract'])
            callback();
        }
    }


    HandleChooseCategory = e => {

        this.setState({
            Category: e
        });
    };
    handleChooseGoodsType = e => {
        this.setState({
            GoodsType: e
        });
    };


    handleCheckState = (checked) => {
        if (checked) {
            this.setState({ openShop: 1 })
        } else {
            this.setState({ openShop: 0 })
        }
    }

    recommendHandle = (checked) => {
        if (checked) {
            this.setState({ recommend: 1 })
        } else {
            this.setState({ recommend: 0 })
        }
    }

    handleUpload = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file) {
            let IsOk = beforeUpload(info.file);
            if (IsOk) {
                this.setState({
                    goodImage: info.fileList
                })
            }
        }
    };

    handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        this.setState({
            loading: true,
            imageUrl: file.url || file.preview
        })
    };



    handleChooseGoodsArr = (e) => {
        this.setState({ GoodsArr: e })
    }



    HandleChooleSpecification = (e) => {
        let price = this.props.form.getFieldValue('price')
        if (price) {
            this.setState({ ChooleSpecification: e.target.checked })
        } else {
            this.setState({ ChooleSpecification: false })
            message.error(intl.get("nav.goodsManagement.fill_in_price"))
        }

    }


    handleChange = (editorState) => {
        this.setState({ editorState })
    }
    handleCancel = () => {
        this.setState({
            loading: false
        });
    };
    unique = (arr) => {
        //Set数据结构，它类似于数组，其成员的值都是唯一的
        return Array.from(new Set(arr)); // 利用Array.from将Set结构转换成数组
    }

    HandleDeleteAnnexTypeItem = (item) => {
        let { ChooseAnnexTypeData } = this.state
        for (let i in ChooseAnnexTypeData) {
            if (ChooseAnnexTypeData[i].id === item.id) {
                ChooseAnnexTypeData.splice(i, 1)
            }
        }
        this.setState({ ChooseAnnexTypeData }, () => this.HandleChooseDefault())
    }

    HandleChooseDefault = () => {
        let ChooseDefaultData = []
        let { ChooseAnnexTypeData, AnnexTypeData } = this.state
        for (let i in AnnexTypeData) {
            for (let k in ChooseAnnexTypeData) {
                if (ChooseAnnexTypeData[k].id === AnnexTypeData[i].id) {
                    ChooseDefaultData.push(AnnexTypeData[i])
                }
            }
        }
        this.setState({ ChooseDefaultData: [...ChooseDefaultData] })
    }

    render() {
        const { CategoryData, DefaultCategory, GoodsSpecificationData, openShop, imageUrl, ChooleSpecification, ChooleGoodsAnnexType, price, loading, recommend, AnnexTypeData, ChooseAnnexTypeData, isModalVisible, goodImage, ChooseDefaultData, is_open_goods_annex, is_sku, is_open_recommend, disable_language, is_gst, is_disable_language } = this.state
        const { getFieldDecorator } = this.props.form;
        const { Option } = Select;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };

        let AnnexTypeNum = 0
        for (let i in ChooseAnnexTypeData) {
            let goodsAnnexTypeItemData = ChooseAnnexTypeData[i].goodsAnnexTypeItem && ChooseAnnexTypeData[i].goodsAnnexTypeItem.data
            for (let k in goodsAnnexTypeItemData) {
                if (goodsAnnexTypeItemData[k].is_default === 1) {
                    AnnexTypeNum = AnnexTypeNum + parseFloat(goodsAnnexTypeItemData[k].price)
                }
            }

        }
        if (CategoryData && GoodsSpecificationData) {
            return (
                <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView" style={{ minWidth: "1200px" }}>
                    <Form.Item
                        label={intl.get('goods.list.name')}
                    >
                        {getFieldDecorator('name', {
                            rules: [{
                                required: true,
                                message: intl.get('goods.list.name.horder'),
                                whitespace: true,
                            }, { max: 64, message: intl.get('goods.list.name.horder.max') }],

                        })(<Input />)}
                    </Form.Item>
                    <Form.Item
                        label={<span>
                            {intl.get('goods.list.img')}
                            <Tooltip title={intl.get("nav.goodsManagement.goods.prompt")}>
                                <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                            </Tooltip>
                        </span>}
                    >
                        {getFieldDecorator('goods_image', {
                            rules: [{ required: true }],
                        })(
                            <div>
                                <Upload
                                    accept=".jpg,.png"
                                    listType="picture-card"
                                    fileList={goodImage}
                                    onPreview={this.handlePreview}
                                    onChange={this.handleUpload}
                                    beforeUpload={() => {
                                        return false;
                                    }}
                                >
                                    {goodImage.length >= 10 ? null : (
                                        <div>
                                            {/* <PlusOutlined /> */}
                                            <div className="ant-upload-text">Add Picture</div>
                                        </div>
                                    )}
                                </Upload>
                                <Modal
                                    visible={loading}
                                    // title={previewTitle}
                                    footer={null}
                                    onCancel={this.handleCancel}
                                >
                                    <img alt="example" style={{ width: '100%' }} src={imageUrl} />
                                </Modal>
                            </div>
                        )}
                    </Form.Item>
                    <Form.Item
                        label={intl.get('goods.list.abstract')}
                    >
                        {getFieldDecorator('abstract', {
                            rules: [{ required: false }, { validator: (rule, value, callback) => { this.checkPsd(rule, value, callback) } }],
                            initialValue: ""
                        })(<TextArea />)}
                    </Form.Item>
                    <Form.Item
                        label={<span>
                            {intl.get('goods.list.rrp_price')}
                            <Tooltip title={intl.get("goods.list.rrp_price_tips")}>
                                <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                            </Tooltip>
                        </span>}
                    >
                        {getFieldDecorator('rrp', {
                        })(<Input prefix={currency_symbol} />)}
                    </Form.Item>
                    <Form.Item
                        label={intl.get('goods.list.price')}
                    >
                        {getFieldDecorator('price', {
                            rules: [{ required: true, message: intl.get('goods.list.price.horder'), whitespace: true }],
                        })(<Input prefix={currency_symbol} onChange={(e) => {
                            e.persist()
                            this.setState({
                                price: e.target.value
                            })
                        }} />)}
                    </Form.Item>


                    <Form.Item
                        label={
                            <span className="ant-form-item-required">{intl.get('goods.list.type')}</span>
                        }
                    >
                        <Select defaultValue={DefaultCategory ? DefaultCategory.name : intl.get("goods.list.classification")} style={{ width: "100%" }} onChange={this.HandleChooseCategory}>
                            {
                                CategoryData.length !== 0 && CategoryData.map(item => {
                                    return <Option value={item.id} key={item.id}>{item.name}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={intl.get('goods.list.sort')}
                    >
                        {getFieldDecorator('sort', {
                            rules: [{ required: false }],
                            initialValue: 0
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item
                        label={intl.get('package.service.goods_count')}
                    >
                        {getFieldDecorator('stock', {
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item
                        label={intl.get('goods.list.unit_number')}
                    >
                        {getFieldDecorator('unit_number', {
                            rules: [{ required: false }],
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item label={<span>{intl.get('goods.list.isOk')}</span>}>
                        <Switch checked={openShop === 1} onChange={this.handleCheckState} />
                    </Form.Item>
                    {
                        !is_disable_language &&
                        <Form.Item label={<span>{intl.get('goods.list.disableLanguage')}</span>}>
                            <Switch checked={disable_language}
                                onChange={() => {
                                    this.setState({
                                        disable_language: !disable_language
                                    })
                                }} />
                        </Form.Item>
                    }
                    {
                        is_open_recommend &&
                        <Form.Item label={<span>{intl.get('goods.list.selected')}</span>}>
                            <Switch checked={recommend === 1} onChange={this.recommendHandle} />
                        </Form.Item>
                    }

                    {/* <Form.Item label={<span>{intl.get('goods.list.is_gst')}</span>}>
                        <Switch checked={is_gst}
                            onChange={() => {
                                this.setState({
                                    is_gst: !is_gst
                                })
                            }} />
                    </Form.Item> */}

                    {
                        is_sku &&
                        <Form.Item label={<span>{intl.get('goods.list.isspeci')}</span>
                        }>
                            <Checkbox checked={ChooleSpecification} onChange={this.HandleChooleSpecification} />
                        </Form.Item>
                    }


                    {
                        ChooleSpecification ?
                            <Table
                                price={price}
                                rrp={this.props.form.getFieldValue('rrp')}
                                GoodsSpecificationData={GoodsSpecificationData}
                                getData={this.HandleGetTableData}
                            // defaultPrice={price}
                            /> : null
                    }
                    {
                        is_open_goods_annex &&
                        <Form.Item label={<span>{intl.get('nav.goodsManagement.isitional')}</span>}>
                            <Checkbox onChange={(e) => {
                                this.setState({ ChooleGoodsAnnexType: e.target.checked })
                            }} />
                        </Form.Item>
                    }

                    {
                        ChooleGoodsAnnexType && <Form.Item
                            label={intl.get('goods.list.goodsAnnexType')}
                        >
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <span>{currency_symbol}{AnnexTypeNum}</span>
                                <Button type="primary"
                                    style={{ height: "25px" }}
                                    onClick={() => this.setState({ isModalVisible: true })}
                                >
                                    {intl.get('nav.goodsManagement.itemadditional.add')}
                                </Button>
                            </div>
                            {
                                ChooseAnnexTypeData ? ChooseAnnexTypeData.map((items, index) => {
                                    return <div key={index}>
                                        <div style={{ width: "300px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <span style={{ fontWeight: "600" }}>{items.name}</span>
                                            <Button type="link"
                                                // style={{ height: "25px" }}
                                                onClick={() => this.HandleDeleteAnnexTypeItem(items)}
                                            >
                                                {intl.get('home.table.operation.btn.delete')}
                                            </Button>
                                        </div>
                                        <div style={{ marginTop: "-5px" }}>
                                            <Row>
                                                {
                                                    items.goodsAnnexTypeItem.data.map((item, key) => {
                                                        return <Col span={24} key={key}>
                                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                                {
                                                                    item.is_default === 1 ?
                                                                        <span style={{ width: "8px", height: "8px", borderRadius: "5px", backgroundColor: "#000", marginRight: "5px" }} /> :
                                                                        <span style={{ width: "8px", height: "8px", borderRadius: "5px", backgroundColor: "#fff", marginRight: "5px" }} />
                                                                }

                                                                <span style={{ lineHeight: "25px" }}>{item.name + "   " + currency_symbol + item.price}</span>
                                                            </div>
                                                        </Col>
                                                    })
                                                }
                                            </Row>
                                        </div>
                                    </div>
                                }) : <div style={{ textAlign: "center", height: "50px" }}>{intl.get("nav.goodsManagement.noAdditionalGoods")}</div>
                            }
                        </Form.Item>
                    }

                    <Form.Item
                        label={intl.get('goods.list.info')}
                    >
                        <div style={{
                            // width: "80%",
                            border: "1px solid #dedede",
                        }}>
                            <BraftEditor
                                value={this.state.editorState}
                                language={Number(getLangType()) == 1 ? "zh" : "en"}
                                onChange={this.handleChange} />
                        </div>
                    </Form.Item>

                    <Form.Item {...tailFormItemLayout}>
                        <Button
                            type="primary"
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                                this.props.history.goBack()
                            }}
                        >
                            {intl.get('seat.table.btn.cancel')}
                        </Button>
                        <Button type="primary" htmlType="submit">
                            {intl.get('cashwithdrawal.form.submit')}
                        </Button>
                    </Form.Item>
                    <Modal title={intl.get("nav.goodsManagement.additional")}
                        visible={isModalVisible}
                        centered={true}
                        onCancel={() => this.setState({ isModalVisible: false })}
                        footer={null}
                        width={800}
                    >
                        <Checkbox.Group style={{ width: '100%' }} value={ChooseDefaultData} onChange={(value) => {
                            this.setState({
                                ChooseAnnexTypeData: value
                            }, () => this.HandleChooseDefault())
                        }}>
                            <Row>
                                {AnnexTypeData.map((item, index) => {
                                    return <Col span={8} key={index} style={{ marginBottom: "10px" }}>
                                        <Checkbox value={item} defaultValue>{item.name}</Checkbox>
                                    </Col>
                                })}
                            </Row>
                        </Checkbox.Group>
                    </Modal>
                </Form>
            );
        } else {
            return (<div />)
        }



    }
}

export default withRouter(Form.create()(NewGoods))