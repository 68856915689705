import React from 'react'
import { Descriptions, Card, Divider } from 'antd';
import { QueryStatistics, getPartner,QueryCount } from "../../action/authAction";
import intl from 'react-intl-universal';

class SubscriptionDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            is_authorization_code: false,
            is_coupon: false,
            is_discount: false,
        };
    }

    async componentDidMount() {
        let res = await QueryCount();

        console.log("res==",res);


        let shopData = await getPartner()
        this.setState({
            data: res.data,
            is_authorization_code: shopData.data.is_authorization_code === 1,
            is_coupon: shopData.data.is_coupon === 1,
            is_discount: shopData.data.is_discount === 1,
        })
    }
    render() {
        const { data, is_authorization_code, is_coupon, is_discount } = this.state;
        const { order, spu, coupon, spu_discount ,partnerCount } = data;
        return (
            <Card>


                 <Descriptions title = '商家数量'>
                    <Descriptions.Item label='商家数量'>{partnerCount?partnerCount:0}</Descriptions.Item>
                    
                </Descriptions>



                {/*
                <Descriptions title={intl.get('data.spu')}>
                    <Descriptions.Item label={intl.get('data.spu.total')}>{spu?.all_count}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('data.spu.year')}>{spu?.year_count}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('data.spu.quarter')}>{spu?.quarter_count}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('data.spu.month')}>{spu?.month_count}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('data.spu.today')}>{spu?.today_count}</Descriptions.Item>
                </Descriptions>
                {
                    is_authorization_code &&
                    <div>
                        <Divider />
                        <Descriptions title={intl.get('data.order')}>
                            <Descriptions.Item label={intl.get('data.order.total')}>{order?.all_count}</Descriptions.Item>
                            <Descriptions.Item label={intl.get('data.order.year')}>{order?.year_count}</Descriptions.Item>
                            <Descriptions.Item label={intl.get('data.order.quarter')}>{order?.quarter_count}</Descriptions.Item>
                            <Descriptions.Item label={intl.get('data.order.month')}>{order?.month_count}</Descriptions.Item>
                            <Descriptions.Item label={intl.get('data.order.today')}>{order?.today_count}</Descriptions.Item>
                        </Descriptions>
                    </div>
                }

                {
                    is_coupon &&
                    <div>
                        <Divider />
                        <Descriptions title={intl.get('data.coupon')}>
                            <Descriptions.Item label={intl.get('data.coupon.total')}>{coupon?.all_count}</Descriptions.Item>
                            <Descriptions.Item label={intl.get('data.coupon.year')}>{coupon?.year_count}</Descriptions.Item>
                            <Descriptions.Item label={intl.get('data.coupon.quarter')}>{coupon?.quarter_count}</Descriptions.Item>
                            <Descriptions.Item label={intl.get('data.coupon.month')}>{coupon?.month_count}</Descriptions.Item>
                            <Descriptions.Item label={intl.get('data.coupon.today')}>{coupon?.today_count}</Descriptions.Item>
                        </Descriptions>
                    </div>
                }
                {
                    is_discount &&
                    <div>
                        <Divider />
                        <Descriptions title={intl.get('data.deal')}>
                            <Descriptions.Item label={intl.get('data.deal.total')}>{spu_discount?.all_count}</Descriptions.Item>
                            <Descriptions.Item label={intl.get('data.deal.year')}>{spu_discount?.year_count}</Descriptions.Item>
                            <Descriptions.Item label={intl.get('data.deal.quarter')}>{spu_discount?.quarter_count}</Descriptions.Item>
                            <Descriptions.Item label={intl.get('data.deal.month')}>{spu_discount?.month_count}</Descriptions.Item>
                            <Descriptions.Item label={intl.get('data.deal.today')}>{spu_discount?.today_count}</Descriptions.Item>
                        </Descriptions>

                    </div>
                }*/}




            </Card>

        );
    }
}

export default SubscriptionDetail

