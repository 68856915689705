import React from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Form, Button, DatePicker, Tooltip, Modal, Descriptions, Divider } from 'antd';
import { GetPartnerMonthlyFeet } from "../../../action/authAction";
import intl from 'react-intl-universal';
import moment from 'moment';
import { getLangType } from "../../../util/function";
let currency_symbol = sessionStorage.getItem("currency_symbol")

const { MonthPicker } = DatePicker;
class AnnouncementList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            meta: "",
            url: "&is_order=0&income_type=" + encodeURIComponent('+'),
            date: "",
            visible: false,
            ItemData: "",
        };
        this.columns = [
            {
                title: "ID",
                dataIndex: 'id',
                width: '3%',
                align: "center",
            },
            // {
            //     title: intl.get("home.table.ordernumber"),
            //     width: '15%',
            //     align: "center",
            //     render: (record) => {
            //         return <Button
            //             type='link'
            //             onClick={() => {
            //                 this.props.history.push("/OrderDetail/" + record.order.id)
            //             }}
            //         >{record.order && record.order.order_sn}</Button>
            //     }
            // },
            {
                title: intl.get('nav.CostFlow.name'),
                dataIndex: 'name',
                width: '15%',
                align: "center",
            },
            {
                title: intl.get('nav.CostFlow.cost'),
                dataIndex: 'free',
                width: '10%',
                align: "center",
                render: (record) => {
                    let value = ""
                    if (!!record[0] && record[0] === '-') {
                        value = "-" + currency_symbol + record.slice(1)
                    } else {
                        value = currency_symbol + record
                    }
                    return <span>{value}</span>
                }
            },
            // {
            //     title: intl.get('nav.PayRoute'),
            //     dataIndex: 'payee',
            //     width: '10%',
            //     align: "center",
            //     // render: (record) => {
            //     //     // return <span>{currency_symbol}{record}</span>
            //     // }
            // },
            // {
            //     title: intl.get('subscription.status'),
            //     dataIndex: ['order', 'is_pay'],
            //     width: '10%',
            //     align: "center",
            //     render: (record) => {
            //         return <span>{record === 1 ? intl.get("home.table.useStatus.paid") : intl.get("home.table.useStatus.refunded")}</span>
            //     }
            // },
            // {
            //     title: intl.get('order.table.remark'),
            //     dataIndex: 'explain',
            //     width: '15%',
            //     align: "center",
            // },

            {
                title: intl.get('package.created'),
                dataIndex: 'created_at',
                width: '15%',
                align: "center",
                render: (record) => {
                    return record.date.substring(0, record.date.length - 7)
                }
            },
            {
                title: intl.get('home.table.operation'),
                width: '6%',
                align: "center",
                render: (record) => {
                    return <Tooltip title={intl.get('home.table.operation.btn.view')}>
                        <Button
                            shape="circle"
                            icon="eye"
                            onClick={() => {
                                this.setState({
                                    visible: true,
                                    ItemData: record,
                                })
                            }}
                        />
                    </Tooltip>

                },
            }

        ];
    }
    componentDidMount() {
        this.HandleGetData(1)
    }

    async HandleGetData(page) {
        let res = await GetPartnerMonthlyFeet(page, this.state.url, this.state.date)
        this.setState({ data: res.data.data, meta: res.data.meta.pagination })
    }

    render() {
        let { meta, url, ItemData, visible } = this.state
        let free = ItemData.free
        let value = ""
        if (free && free[0] && free[0] === '-') {
            value = "-" + currency_symbol + free.slice(1)
        } else {
            value = currency_symbol + free
        }
        return (
            <div>
                <div style={{ marginBottom: "5px", display: 'flex', justifyContent: "space-between" }}>
                    <div>
                        <Button
                            style={{ marginRight: "5px", backgroundColor: url === "&is_order=0&income_type=" + encodeURIComponent('+') ? "#5867dd" : "", color: url === "&is_order=0&income_type=" + encodeURIComponent('+') ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    url: "&is_order=0&income_type=" + encodeURIComponent('+')
                                }, () => {
                                    this.HandleGetData(1)
                                })
                            }}>
                            {intl.get('nav.CostFlow.income')}
                        </Button>
                        <Button
                            style={{ marginRight: "5px", backgroundColor: url === "&is_order=0&income_type=-" ? "#5867dd" : "", color: url === "&is_order=0&income_type=-" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    url: "&is_order=0&income_type=-"
                                }, () => {
                                    this.HandleGetData(1)
                                })
                            }}>
                            {intl.get('nav.CostFlow.expenditure')}
                        </Button>

                    </div>
                    <MonthPicker
                        placeholder="Select month"
                        allowClear
                        onChange={(e) => {
                            let date = moment(e).format('YYYY-MM')
                            this.setState({ date }, () => {
                                this.HandleGetData(1)
                            })
                        }} />
                </div>
                <Table
                    rowKey={record => record.id}
                    dataSource={this.state.data}
                    columns={this.columns}
                    pagination={{
                        pageSize: meta.per_page,
                        total: meta.total
                    }}
                    onChange={(e) => {
                        this.HandleGetData(e.current)
                    }}
                />
                <Modal
                    title={intl.get("nav.CostFlow.other.billingDetails")}
                    visible={visible}
                    onCancel={() => {
                        this.setState({
                            visible: false,
                            ItemData: "",
                        })
                    }}
                    footer={null}
                >
                    <Descriptions title={intl.get("nav.CostFlow.other.billingDetails")} column={2}>
                        {
                            ItemData?.order?.order_sn &&
                            <Descriptions.Item label={intl.get("home.table.ordernumber")}>{ItemData?.order?.order_sn}</Descriptions.Item>
                        }

                        <Descriptions.Item label={intl.get('nav.CostFlow.name')}>{ItemData?.name}</Descriptions.Item>
                        <Descriptions.Item label={intl.get('nav.CostFlow.cost')}>{value}</Descriptions.Item>
                        {
                            ItemData?.payee &&
                            <Descriptions.Item label={intl.get('nav.PayRoute')}>{ItemData?.payee}</Descriptions.Item>
                        }
                        {
                            ItemData?.order?.is_pay &&
                            <Descriptions.Item label={intl.get('subscription.status')}>{ItemData?.order?.is_pay === 1 ? intl.get("home.table.useStatus.paid") : intl.get("home.table.useStatus.refunded")}</Descriptions.Item>
                        }

                        {
                            ItemData?.explain &&
                            <Descriptions.Item label={intl.get('order.table.remark')}>{ItemData?.explain}</Descriptions.Item>
                        }
                        <Descriptions.Item label={intl.get('package.created')}>{ItemData?.created_at?.date.substring(0, ItemData.created_at.date.length - 7)}</Descriptions.Item>
                    </Descriptions>
                </Modal>
            </div>


        );
    }
}

export default withRouter(Form.create()(AnnouncementList))

