import React from "react";
import { Table, Popconfirm, Button, Select ,Tooltip, Popover, Icon  } from 'antd';
import { getApplyPackageList, UpdateApplyPackage } from "../../action/authAction";
import { getShopList, getShopDetail } from "../../action/shopAction"
const { Option } = Select;

class ApplyPackageList extends React.Component {
    constructor(props) {
        super(props);
        //this.state = { data: [] };

        this.state = {
            data: "", 
            shopList: "",  
            partner_id: "",


        };




        this.columns = [
            {
                title: 'id',
                dataIndex: 'id',
                width: '5%',
                editable: false,
                align: "center",
            },
            {
                title: '套餐名称',
                dataIndex: ['package', 'name'],
                width: '10%',
                editable: true,
                align: "center",
            },
            // {
            //     title: '订单服务费固定抽成',
            //     dataIndex: 'order_fixed_fee',
            //     align: "center",
            // },
            {
                title: '初始费',
                dataIndex: ['package', 'setup_fee'],
                width: '10%',
                align: "center",
                render: (record) => {
                    if (record || record === 0) {
                        return <span>${record}</span>
                    }
                }
            },
            {
                title: '订单固定服务费',
                dataIndex: ['package', 'order_fixed_fee'],
                width: '10%',
                editable: true,
                align: "center",
                render: (record) => {
                    if (record || record === 0) {
                        return <span>${record}</span>
                    }

                }
            },
            {
                title: '活动订单服务费',
                dataIndex: ['package', 'activity_service_rate'],
                width: '10%',
                editable: true,
                align: "center",
                render: (record) => {
                    if (record || record === 0) {
                        return <span>{record}%</span>
                    }

                }
            },
            {
                title: '商品订单服务费',
                dataIndex: ['package', 'rate'],
                width: '10%',
                editable: true,
                align: "center",
                render: (record) => {
                    if (record || record === 0) {
                        return <span>{record}%</span>
                    }
                }
            },
            {
                title: '申请商家',
                dataIndex: ['partner', 'name'],
                width: '10%',
                editable: true,
                align: "center",
            },

             
        ];
    }

    async componentDidMount() {

        let resp = await getShopList("", "") 
        console.log("shoplist===",resp);

        let data = resp.data.data
        console.log(data) 
        if(data.length > 0 ){
            this.setState({
                shopList: data,
                partner_id: data[0].id
            }, () => {
                this.HandleGetData()
            })
        }else{
              this.setState({
                    shopList: [],
                    partner_id: ''
                }, () => {
                    this.HandleGetData()
                })   
        }




        this.HandleGetData()
    }

    async HandleGetData() {

         let {  partner_id } = this.state

        let res = await getApplyPackageList(partner_id);
        this.setState({ data: res.data.data })
    }



    edit(key) {
        UpdateApplyPackage(key.id, { "is_dispose": 1 }).then(res => {
            this.HandleGetData()
        })
    }


    render() {
        const { shopList, partner_id , data } = this.state
        return (
            <div> 

                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                        <div> 
                             {
                            shopList &&
                                 <Select showSearch
                                    placeholder="请选择店铺"
                                    style={{ width: 300, marginLeft: "0px", marginRight: "10px" }}
                                    onChange={(e, value) => {
                                        this.setState({
                                            partner_id: e,
                                            url: ""
                                        }, () => this.HandleGetData())

                                    }}
                                    value={partner_id}
                                >
                                    {
                                        shopList.map(item => {
                                            return <Option value={item.id} key={item.id}>{item.title}</Option>
                                        })
                                    }
                                </Select>
                                }

                                <Tooltip title={'请选择店铺'}>
                                    <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                                </Tooltip> 
                        </div> 
                    </div>


 

                <Table
                    rowKey={(record, index) => record.id.toString()}
                    dataSource={this.state.data}
                    columns={this.columns}
                    rowClassName="editable-row"
                    pagination={{
                        hideOnSinglePage: true
                    }}
                    className="waperBox"
                />
            </div>
        );
    }
}

export default ApplyPackageList

