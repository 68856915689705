import { BaseUrl } from './function';
export const API = {
    BASEURL: BaseUrl() + "/api/empartner/",
    EmailBASEURL: BaseUrl() + "/api/",
    auth: {
        LOGIN: "login",
        partnerAdminLogin: "partnerAdminLogin",
        partnerAddress: "partner", //?include=partnerAddress,region
        PARTNER: "partner",
        UPDATE: "/update"
    },
    category: {
        NEWCATEGORY: "category/",
        EDITCATEGORY: "category/update/"
    },
    goodsType: "goodsType/",
    goodsSpecification: {
        goodsSpecification: "goodsSpecification",
        goodsSpecificationItem: "goodsSpecificationItem",
        include: "?include=goodsSpecificationItem"
    },
    goodsAttr: "goodsAttr",
    spuDiscount: "spuDiscount",
    spuManyHelp: "spuManyHelp",
    coupon: "coupon",
    userCouponCheck: "userCouponCheck",
    userCoupon: "userCoupon",
    NewGood: {
        spu: "spu",
        include: "?include=spuSku,spuGoodsSpecification,spuGoodsAttr,spuRecommendCategory,spuGoodsAnnexType.spuGoodsAnnexTypeItem",
        include2: "&include=spuSku,spuGoodsSpecification,spuGoodsAttr,category",
        UPDATE: "/update",
        recommend: "&recommend=1"
    },
    page: "?page=",
    search: "&search=",
    NewAd: {
        advertisement: "advertisement",
        update: "/update",
        list: "?type=1",
        include: "?include=spu,category"
    },
    Menu: "partnerMenuImage",
    Tag: "partnerTag",
    partnerAnnouncement: "/partnerAnnouncement",
    systemAnnouncement: "/systemAnnouncement?is_show=1&type=2",
    partnerPhoto: "/partnerPhoto",
    wallet: {
        wallet: "/wallet",
        withdraw: "/withdraw",
        money: "/money",
        WithdrawLog: "/withdrawLog"
    },
    order: {
        order: "order",
        orderLine: "orderLine",
        include: "?include=user",
        includeUser: "?include=orderSpu,user",
        phone: "&phone=",
        orderSn: "&order_sn=",
        orderReceiving: "orderReceiving/",
        confirmOrder: "confirmOrder",
        exportOrder: "exportOrder",
        orderExportSecret: "orderExportSecret"
    },
    withdrawLog: {
        withdrawLog: "withdrawLog",
        dispose: "/dispose"
    },
    partnerSeat: "partnerSeat",
    partnerDeliveryFree: "partnerDeliveryFree",
    partnerPackCharge: "partnerPackCharge",
    user: "user",
    queryAddress: BaseUrl() + "/api/addressQuery?query=",
    partnerDeliveryAddress: "partnerDeliveryAddress",
    partnerServiceTypeDiscount: "partnerServiceTypeDiscount",
    redPackageIndex: "redPackageIndex",
    prizeActivity: "prizeActivity/",
    partnerAdminUniqueShow: "partnerAdminUniqueShow?unique=",
    partnerAdmin: "partnerAdmin",
    partnerLangue: "empartnerLangue",
    plugIn: "addon",
    package: "package",
    partnerApplyPackage: "partnerApplyPackage",
    packageLog: "packageLog",
    card: "card",
    businessHours: "businessHours",
    holiday: "holiday",
    holidayBusiness: "holidayBusiness",
    goodsAnnexType: {
        goodsAnnexType: "goodsAnnexType",
        includeType: "?include=goodsAnnexTypeItem",
        goodsAnnexTypeItem: "goodsAnnexTypeItem",
        includeItem: "?include=goodsAnnexType",
    },
    style: "style",
    partnerNav: "partnerNav",
    manualPrintOrder: "manualPrintOrder",
    printer: "printer",
    config: "config?partner_id=0",
    partnerPackageFeeLog: "partnerPackageFeeLog",
    queryPrinterStatus: "queryPrinterStatus?sn=",
    getConfig: "getConfig/region_id",
    verifyCode: "verifyCode",
    document: "document",


    //补充商家列表
    shopList: {
        partner: "empartnerlist",
        //include: "?include=partnerAddress,partnerFoodLicense",
        include:'',
        page: "?page=",
        page2: "&page="
    },


 




}
