import React from "react";
import { withRouter } from 'react-router-dom'
import { Input, Table, Icon, Popconfirm, Form, Button, Select, DatePicker, Modal, Switch, Tooltip, Popover } from 'antd';
import { getOrderList, getRefundOrderList, deleteOrder, manualPrintOrder, getPrinterList, putOrderStatus, confirmOrderOrder, GetOrderExportSecret, ExportOrderList } from "../../action/goodAction";
import { UpdateShopMessage, getPartner, GetConfigSet } from "../../action/authAction"
import intl from 'react-intl-universal';
import moment from 'moment';
const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;
let currency_symbol = sessionStorage.getItem("currency_symbol")

class orderList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            editingKey: '',
            printerList: "",
            printerId: "",
            meta: "",
            url: "",
            one_url: "",
            two_url: "",
            visible: false,
            setVisible: false,
            value: "",
            is_error: false,
            itemId: "",
            spu_discount_id: "",
            is_show_order: false,
            is_order_receiving: false,
            is_print_off: false,
            is_printer: false,
            orderValue: "",
            phoneValue: "",
            partner_id: "",
            partner_name: "",
            is_discount: "",
            is_coupon: "",
            min_pay_fee_on_online: "",
        };
        this.columns = [
            {
                title: intl.get('common.table.id'),
                dataIndex: 'id',
                width: '4%',
                align: "center",
            },
            // {
            //     title: intl.get('home.table.uuid'),
            //     dataIndex: 'uuid',
            //     width: '6%',
            //     align: "center",
            // },
            {
                title: intl.get('home.table.ordernumber'),
                dataIndex: 'order_sn',
                width: '8%',
                align: "center",
            },
            {
                title: intl.get('nav.CostFlow.name'),
                width: '10%',
                align: "center",
                render: (text, record) => {
                    const data = record.orderSpu && record.orderSpu.data
                    if (record.spu_discount_id > 0) {
                        let attach_data = data[0].attach_data.spu_discount
                        return <div style={{ display: "flex" }}>
                            <img src={attach_data.image} style={{ width: "50px", height: "50px", objectFit: "contain", marginRight: "10px" }} alt="" />
                            <div>
                                {
                                    record.spu_many_help_id > 0 ?
                                        <span>
                                            <span style={{ color: "#ff0000" }}>[{intl.get("nav.goodsManagement.cutgoodsType")}]</span>{attach_data.name}
                                        </span> :
                                        <span>
                                            <span style={{ color: "#ff0000" }}>[{intl.get("nav.goodsManagement.Promotion")}]</span>{attach_data.name}
                                        </span>
                                }
                            </div>
                        </div>
                    } else {
                        return (
                            <div>
                                {
                                    data && data.map(item => {
                                        return (<div key={item.id.toString() + item.order_id} style={{ display: "flex", marginBottom: "5px" }}>
                                            <img src={item.attach_data.image} style={{ width: "50px", height: "50px", objectFit: "contain", marginRight: "10px" }} alt="" />
                                            <div className="good-name" style={{ width: "70px" }}>
                                                <div style={{ textAlign: "left" }} title={item.attach_data.name}>{item.attach_data.name}</div>
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    {
                                                        item.attach_data && item.attach_data.spu_sku && item.attach_data.spu_sku.spu_sku_goods_specification_item && item.attach_data.spu_sku.spu_sku_goods_specification_item.map((key, index) => {
                                                            return (
                                                                <span key={item.order_id + key.name + key.goods_specification_id + index + Math.random()} style={{ color: "#878FA5", fontSize: 12, textAlign: "left", margin: "5px 0px" }}>{key.name}</span>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                <div>
                                                    {
                                                        item.attach_data && item.attach_data.spu_goods_annex_type && item.attach_data.spu_goods_annex_type.map(key => {
                                                            let spu_goods_annex_type_item = key.spu_goods_annex_type_item
                                                            return (
                                                                <div key={item.order_id + Math.random()}>
                                                                    {spu_goods_annex_type_item && spu_goods_annex_type_item.map((type_key, i) => {
                                                                        let goods_annex_type_item = type_key.goods_annex_type_item
                                                                        return <p key={i + goods_annex_type_item.name + Math.random()} style={{ color: "#878FA5", fontSize: 12, textAlign: "left", margin: "5px 0px" }}>{goods_annex_type_item.name} x {goods_annex_type_item.quantity}</p>
                                                                    })}
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>)
                                    })
                                }
                            </div>
                        )
                    }

                }
            },
            {
                title: intl.get('user.table.phone'),
                dataIndex: ["user", "phone"],
                width: '10%',
                align: "center",
            },
            {
                title: intl.get('home.table.price'),
                dataIndex: 'price',
                width: '5%',
                align: "center",
                render: (record) => {
                    return <span>{currency_symbol}{record}</span>
                }
            },
            // {
            //     title: intl.get('home.table.redpack'),
            //     dataIndex: 'use_user_money',
            //     width: '5%',
            //     align: "center",
            // },
            // {
            //     title: intl.get('home.table.delivery'),
            //     dataIndex: 'delivery_price',
            //     width: '6%',
            //     align: "center",
            // },
            // {
            //     title: intl.get('home.table.packingCharge'),
            //     dataIndex: 'partner_pack_charge',
            //     width: '6%',
            //     align: "center",
            // },
            // {
            //     title: intl.get('home.table.service'),
            //     dataIndex: 'service_charge',
            //     width: '6%',
            //     align: "center",
            // },

            // {
            //     title: intl.get('home.table.useStatus'),
            //     width: '2%',
            //     align: "center",
            //     render: (text, record) => {
            //         return (
            //             <span style={{ color: record.is_finish === 1 ? "green" : "#ff0000" }}>{record.is_finish === 1 ? intl.get('home.table.useStatus.name_y') : intl.get('home.table.useStatus.name_n')}</span>
            //         )
            //     }
            // },
            // {
            //     title: intl.get('home.table.scanning_mode'),
            //     width: '5%',
            //     align: "center",
            //     render: (text, record) => {
            //         let scan_code_type = ""
            //         if (record.scan_code_type === 1) {
            //             scan_code_type = intl.get("nav.setting.seat")
            //         }
            //         if (record.scan_code_type === 2) {
            //             scan_code_type = intl.get("nav.goodsManagement.sales_promotion")
            //         }
            //         if (record.scan_code_type === 3) {
            //             scan_code_type = intl.get("nav.setting.seat") + "、" + intl.get("nav.goodsManagement.sales_promotion")
            //         }
            //         // scan_code_type
            //         return (
            //             <span>{scan_code_type}</span>
            //         )
            //     }
            // },
            {
                title: intl.get("home.table.ordertime"),
                width: '8%',
                align: "center",
                render: (record) => {
                    return record.created_at && record.created_at.date.substring(0, 19)
                }
            },
            // {
            //     title: "退款到",
            //     width: '8%',
            //     align: "center",
            //     render: (record) => {
            //         return record.is_refunded === 1 ? "银行卡" : "钱包"
            //     }
            // },
            // {
            //     title: intl.get("setup.form.way"),
            //     width: '5%',
            //     dataIndex: "payee",
            //     align: "center",
            // },
            // {
            //     title: intl.get("orders.status"),
            //     width: '5%',
            //     align: "center",
            //     dataIndex: "order_status",
            //     render: (record) => {
            //         switch (record) {
            //             case "un_pay":
            //                 return intl.get("home.table.useStatus.un_pay");
            //                 break;
            //             case "wait_order_receiving":
            //                 return intl.get("home.table.useStatus.wait_order_receiving");
            //                 break;
            //             case "un_order_receiving":
            //                 return intl.get("home.table.useStatus.un_order_receiving");
            //                 break;
            //             case "for_consumption":
            //                 return intl.get("home.table.useStatus.for_consumption");
            //                 break;
            //             case "finished":
            //                 return intl.get("home.table.useStatus.finished");
            //                 break;
            //             case "canceled":
            //                 return intl.get("home.table.useStatus.canceled");
            //                 break;
            //             case "rejected":
            //                 return intl.get("home.table.useStatus.rejected");
            //                 break;
            //             case "refunded":
            //                 return intl.get("home.table.useStatus.refunded");
            //                 break;


            //         }
            //     }
            // },
            // {
            //     title: intl.get("nav.goodsManagement.promotionalgoods.discount_type"),
            //     width: '5%',
            //     align: "center",
            //     render: (record) => {
            //         return record.spu_many_help_id !== 0 ? intl.get("nav.goodsManagement.cutgoodsType") : ""
            //     }
            // },
            // {
            //     title: intl.get('nav.setting.printer'),
            //     width: '3%',
            //     align: "center",
            //     render: () => {
            //         const { printerList } = this.state
            //         return (
            //             <Select defaultValue={printerList[0] ? printerList[0].name : "Select Printer"}
            //                 onChange={(value) => {
            //                     this.setState({
            //                         printerId: value
            //                     })
            //                 }}
            //             >
            //                 {
            //                     printerList.map((item, index) => {
            //                         return <Option key={index} value={item.id}>{item.name}</Option>
            //                     })
            //                 }
            //             </Select>
            //         )
            //     }
            // },

            // {
            //     title: intl.get("nav.goodsManagement.promotionalgoods.isBuy"),
            //     width: '5%',
            //     align: "center",
            //     render: (record) => {
            //         return record.is_already_buy === 1 ? <span style={{ color: "green" }}>Yes</span> : <span style={{ color: "red" }}>No</span>
            //     }
            // },
            {
                title: intl.get('home.table.operation'),
                dataIndex: 'operation',
                width: '6%',
                align: "center",
                render: (text, record) => {
                    return <Popover
                        style={{ width: 500 }}
                        content={
                            <div style={{ display: "flex" }}>
                                {
                                    record.order_status === "for_consumption" &&
                                    <Popconfirm title={intl.get('home.table.operation.btn.isOrder')}
                                        style={{ marginRight: "5px" }}
                                        onConfirm={() => {
                                            confirmOrderOrder({ "unique_code": record.unique_code }).then(res => {
                                                this.HandleGetData(this.state.spu_discount_id, this.state.url, 1)
                                            })
                                        }}>
                                        <Tooltip title={intl.get('home.table.operation.btn.isOrder')}>
                                            <Button
                                                style={{ marginRight: "5px" }}
                                                shape="circle"
                                                icon="check-circle"
                                            />
                                        </Tooltip>
                                    </Popconfirm>
                                }
                                {
                                    record.order_status === "wait_order_receiving" ?
                                        <div>
                                            <Tooltip title={intl.get('home.table.operation.btn.refuse')}>
                                                <Button
                                                    style={{ marginRight: "5px" }}
                                                    shape="circle"
                                                    icon="stop"
                                                    onClick={() => {
                                                        this.setState({
                                                            visible: true,
                                                            itemId: record.id
                                                        })
                                                        // this.props.history.push("/OrderDetail/" + record.id)
                                                    }} />
                                            </Tooltip>

                                            <Popconfirm title={intl.get('home.table.operation.btn.isAgree')}
                                                style={{ marginRight: "5px" }}
                                                onConfirm={() => {
                                                    putOrderStatus(record.id, { "status": 1 }).then(res => {
                                                        this.HandleGetData(this.state.spu_discount_id, this.state.url, 1)
                                                    }).catch(error => {
                                                        console.log(error.response)
                                                    })
                                                }}>
                                                <Tooltip title={intl.get('home.table.operation.btn.agree')}>
                                                    <Button
                                                        style={{ marginRight: "5px" }}
                                                        shape="circle"
                                                        icon="check-circle"
                                                    />
                                                </Tooltip>
                                            </Popconfirm>
                                        </div>
                                        :
                                        <div>
                                            {
                                                this.state.is_printer && this.state.is_print_off &&
                                                <Popconfirm title={intl.get('home.table.operation.btn.printHorder')}
                                                    style={{ marginRight: "5px" }}
                                                    onConfirm={() => {
                                                        manualPrintOrder(record.id, { "w": "1" }).then(res => {
                                                        })
                                                    }}>
                                                    <Tooltip title={intl.get('home.table.operation.btn.print')}>
                                                        <Button
                                                            style={{ marginRight: "5px" }}
                                                            shape="circle"
                                                            icon="printer"
                                                        />
                                                    </Tooltip>
                                                </Popconfirm>
                                            }
                                        </div>


                                }
                                <Tooltip title={intl.get('home.table.operation.btn.view')}>
                                    <Button
                                        shape="circle"
                                        icon="eye"
                                        onClick={() => {
                                            this.props.history.push("/OrderDetail/" + record.id)
                                        }}
                                    />
                                </Tooltip>
                            </div>
                        }
                        trigger="hover"
                    >
                        <Button
                            style={{ marginRight: "5px" }}
                            shape="circle"
                            icon="more"
                        />
                    </Popover>
                },
            },

        ];
    }

    async componentDidMount() {
        const id = this.props.location.pathname.substring(11)
        let shopData = await getPartner()
        let configData = await GetConfigSet()
        this.setState({
            url: "",
            spu_discount_id: id,
            is_show_order: shopData.data.is_show_order === 1 ? true : false,
            is_order_receiving: shopData.data.is_order_receiving === 1 ? true : false,
            is_print_off: shopData.data.is_print_off === 1 ? true : false,
            is_printer: shopData.data.is_printer === 1 ? true : false,
            partner_id: shopData.data.id,
            partner_name: shopData.data.name,
            is_discount: shopData.data.is_discount,
            is_coupon: shopData.data.is_coupon,
            min_pay_fee_on_online: configData.data.data.find(el => el.key === "min_pay_fee_on_online").value,
        }, () => {
            this.HandleGetData(this.state.spu_discount_id, this.state.url, 1)
        })
    }

    async HandleGetData(spu_discount_id, data, page) {
        let res = await getOrderList(spu_discount_id, data, page);
        let resp = await getPrinterList()
        this.setState({
            data: res.data.data,
            meta: res.data.meta.pagination,
            printerList: resp.data.data,
            printerId: resp.data.data && resp.data.data[0] && resp.data.data[0].id
        })
    }
    async HandleGetRefoundData(spu_discount_id, data, page) {
        let res = await getRefundOrderList(spu_discount_id, data, page);
        let resp = await getPrinterList()
        this.setState({
            data: res.data.data,
            meta: res.data.meta.pagination,
            printerList: resp.data.data,
            printerId: resp.data.data && resp.data.data[0] && resp.data.data[0].id
        })
    }

    async handleDelete(data) {
        await deleteOrder(data.id)
        const dataSource = [...this.state.data];
        this.setState({ data: dataSource.filter(item => item.id !== data.id) });
    }

    HandleExportOrder = () => {
        let { partner_id } = this.state
        GetOrderExportSecret().then(res => {
            let url = "?partner_id=" + partner_id + "&secret=" + res.data
            ExportOrderList(url).then(data => {
                this.downLoadDataQuery(data.data)
            })
        })



    }

    downLoadDataQuery = (resData) => {
        let fileName = this.state.partner_name + "_" + this.state.partner_id
        const blob = new Blob([resData], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        // const blob = new Blob(['\uFEFF' + resData], { type: 'application/vnd.ms-excel;charset=utf-8' });
        const downloadElement = document.createElement('a');
        const href = URL.createObjectURL(blob); //创建下载链接
        downloadElement.href = href;
        downloadElement.download = fileName + '.xlsx';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);// 下载完成移除元素
        window.URL.revokeObjectURL(href)
    }


    render() {
        const { data, meta, url, one_url, two_url, value, is_error, itemId, spu_discount_id, is_show_order, orderValue, phoneValue, is_order_receiving, is_discount, is_coupon, min_pay_fee_on_online } = this.state
        const columns = this.columns.map(col => {
            return {
                ...col,
                onCell: record => ({
                    record,
                    title: col.title,
                }),
            };
        });
        return (
            <div>
                <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "column" }}>
                    <div style={{ marginBottom: "10px", display: "flex", flex: 1, justifyContent: "flex-end", alignItems: "center" }}>
                        <Tooltip title={intl.get("home.table.isShowOrder_tips")}>
                            <Icon type="question-circle" />
                        </Tooltip>
                        <span style={{ marginLeft: "5px" }}>{intl.get("home.table.isShowOrder")}：</span>
                        <Switch checked={is_show_order} onChange={() => {
                            UpdateShopMessage({ "is_show_order": !is_show_order ? "1" : "0" }).then(res => {
                                this.setState({
                                    is_show_order: !is_show_order
                                })
                            })
                        }} />
                    </div>
                    <div style={{ display: 'flex', flex: 1, justifyContent: "space-between", marginBottom: "10px" }}>
                        <Search
                            style={{ width: "32%" }}
                            placeholder={intl.get("home.placehorder")}
                            enterButton={intl.get("home.search")}
                            value={orderValue}
                            onChange={(e) => {
                                this.setState({
                                    orderValue: e.target.value,
                                })
                            }}
                            onSearch={(val) => {
                                this.setState({
                                    url: "&order_sn=" + val + one_url + two_url,
                                    phoneValue: "",
                                }, () => {
                                    this.HandleGetData(spu_discount_id, this.state.url, 1)
                                })
                            }}
                        />
                        <Search
                            style={{ width: "32%" }}
                            placeholder={intl.get("home.placehorder_p")}
                            enterButton={intl.get("home.search")}
                            value={phoneValue}
                            onChange={(e) => {
                                this.setState({
                                    phoneValue: e.target.value
                                })
                            }}
                            onSearch={(val) => {
                                this.setState({
                                    url: "&phone=" + val + one_url + two_url,
                                    orderValue: "",
                                }, () => {
                                    this.HandleGetData(spu_discount_id, this.state.url, 1)
                                })
                            }}
                        />
                        <RangePicker style={{ width: "32%" }}
                            format="YYYY-MM-DD"
                            onChange={(value) => {
                                let start_at = value[0] ? moment(value[0]).format("YYYY-MM-DD") + " 00:00:00" : ""
                                let end_at = value[1] ? moment(value[1]).format("YYYY-MM-DD") + " 23:59:59" : ""
                                this.setState({
                                    url: "&start_at=" + start_at + "&end_at=" + end_at + one_url + two_url,
                                    orderValue: "",
                                    phoneValue: "",
                                }, () => {
                                    this.HandleGetData(spu_discount_id, this.state.url, 1)
                                })
                            }} />
                    </div>


                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                        <div style={{ marginBottom: "5px" }}>
                            <Button
                                style={{ marginRight: "5px", backgroundColor: (url.indexOf("is_discount_order") > -1) || (url.indexOf("is_user_coupon") > -1) ? "" : "#5867dd", color: (url.indexOf("is_discount_order") > -1) || (url.indexOf("is_user_coupon") > -1) ? "rgba(0, 0, 0, 0.65)" : "#fff" }}
                                onClick={() => {
                                    this.setState({
                                        url: "",
                                        one_url: "",
                                        two_url: "",
                                        orderValue: "",
                                        phoneValue: "",
                                    }, () => {
                                        this.columns.splice(5, 1, {
                                            title: intl.get("home.table.ordertime"),
                                            width: '8%',
                                            align: "center",
                                            render: (record) => {
                                                return record.created_at && record.created_at.date.substring(0, 19)
                                            }
                                        })
                                        this.HandleGetData(spu_discount_id, this.state.url, 1)
                                    })
                                }}>
                                {intl.get('home.table.useStatus.all')}
                            </Button>
                            <Button
                                style={{ marginRight: "5px", backgroundColor: one_url === "&is_discount_order=0" ? "#5867dd" : "", color: one_url === "&is_discount_order=0" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                onClick={() => {
                                    this.setState({
                                        url: "&is_discount_order=0" + two_url,
                                        one_url: "&is_discount_order=0",
                                        orderValue: "",
                                        phoneValue: "",
                                    }, () => {
                                        this.HandleGetData(spu_discount_id, this.state.url, 1)
                                    })
                                }}>
                                {intl.get('nav.goodsManagement.ordinaryOrder')}
                            </Button>
                            {
                                (is_discount === 1 || is_coupon === 1) &&
                                <Popover
                                    style={{ width: 500 }}
                                    content={
                                        <div style={{ display: "flex" }}>
                                            {
                                                is_discount === 1 &&
                                                <Button
                                                    style={{ marginRight: "5px", backgroundColor: one_url === "&is_discount_order=1" ? "#5867dd" : "", color: one_url === "&is_discount_order=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                                    onClick={() => {
                                                        this.setState({
                                                            url: "&is_discount_order=1" + two_url,
                                                            one_url: "&is_discount_order=1",
                                                            orderValue: "",
                                                            phoneValue: "",
                                                        }, () => {
                                                            this.HandleGetData(spu_discount_id, this.state.url, 1)
                                                        })
                                                    }}>
                                                    {intl.get('nav.goodsManagement.promotionalOrder')}
                                                </Button>
                                            }

                                            {
                                                is_coupon === 1 &&
                                                <Button
                                                    style={{ marginRight: "5px", backgroundColor: one_url === "&is_user_coupon=1" ? "#5867dd" : "", color: one_url === "&is_user_coupon=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                                    onClick={() => {
                                                        this.setState({
                                                            url: "&is_user_coupon=1" + two_url,
                                                            one_url: "&is_user_coupon=1",
                                                            orderValue: "",
                                                            phoneValue: "",
                                                        }, () => {
                                                            this.HandleGetData(spu_discount_id, this.state.url, 1)
                                                        })
                                                    }}>
                                                    {intl.get('nav.goodsManagement.promotionalCoupon')}
                                                </Button>
                                            }
                                        </div>

                                    }
                                    trigger="hover"
                                >
                                    <Button
                                        style={{
                                             marginRight: "5px",
                                              backgroundColor: (one_url === "&is_discount_order=1"||one_url === "&is_user_coupon=1") ? "#5867dd" : "", 
                                              color: (one_url === "&is_discount_order=1"||one_url === "&is_user_coupon=1") ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                    >
                                        {intl.get('nav.goodsManagement.couponOrder')}
                                    </Button>
                                </Popover>

                            }



                        </div>
                        <div style={{ marginBottom: "5px" }}>
                            {/* <Button
                        style={{ marginRight: "5px", backgroundColor: this.state.two_url === "&is_pay=0" ? "#5867dd" : "", color: this.state.two_url === "&is_pay=0" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                        onClick={() => {
                            this.setState({
                                url: one_url + "&is_pay=0",
                                two_url: "&is_pay=0"
                            }, () => {
                                this.columns.splice(5, 1, {
                                    title: intl.get("home.table.ordertime"),
                                    width: '8%',
                                    align: "center",
                                    render: (record) => {
                                        return record.created_at && record.created_at.date.substring(0, 19)
                                    }
                                })
                                this.HandleGetData(spu_discount_id, this.state.url, 1)
                            })
                        }}>
                        {intl.get('home.table.useStatus.unpaid')}
                    </Button> */}
                            {
                                is_order_receiving &&
                                <Button
                                    style={{ marginRight: "5px", backgroundColor: two_url === "&order_status=wait_order_receiving" ? "#5867dd" : "", color: two_url === "&order_status=wait_order_receiving" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                    onClick={() => {
                                        this.setState({
                                            url: one_url + "&order_status=wait_order_receiving",
                                            two_url: "&order_status=wait_order_receiving",
                                            orderValue: "",
                                            phoneValue: "",
                                        }, () => {
                                            this.columns.splice(5, 1, {
                                                title: intl.get("home.table.ordertime"),
                                                width: '8%',
                                                align: "center",
                                                render: (record) => {
                                                    return record.created_at && record.created_at.date.substring(0, 19)
                                                }
                                            })
                                            this.HandleGetData(spu_discount_id, this.state.url, 1)
                                        })
                                    }}>
                                    {intl.get('home.table.useStatus.wait_order_receiving')}
                                </Button>
                            }

                            <Button
                                style={{ marginRight: "5px", backgroundColor: two_url === "&is_pay=1" ? "#5867dd" : "", color: two_url === "&is_pay=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                onClick={() => {
                                    this.setState({
                                        url: one_url,
                                        two_url: "&is_pay=1",
                                        orderValue: "",
                                        phoneValue: "",
                                    }, () => {
                                        this.columns.splice(5, 1, {
                                            title: intl.get("home.table.time"),
                                            width: '8%',
                                            align: "center",
                                            render: (record) => {
                                                return record.pay_at && record.pay_at.date.substring(0, 19)
                                            }
                                        })
                                        this.HandleGetData(spu_discount_id, this.state.url, 1)
                                    })
                                }}>
                                {intl.get('home.table.useStatus.paid')}
                            </Button>
                            <Button
                                style={{ marginRight: "5px", backgroundColor: two_url === "&order_status=for_consumption" ? "#5867dd" : "", color: two_url === "&order_status=for_consumption" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                onClick={() => {
                                    this.setState({
                                        url: one_url + "&order_status=for_consumption",
                                        two_url: "&order_status=for_consumption",
                                        orderValue: "",
                                        phoneValue: "",
                                    }, () => {
                                        this.columns.splice(5, 1, {
                                            title: intl.get("home.table.time"),
                                            width: '8%',
                                            align: "center",
                                            render: (record) => {
                                                return record.pay_at && record.pay_at.date.substring(0, 19)
                                            }
                                        })
                                        this.HandleGetData(spu_discount_id, this.state.url, 1)
                                    })
                                }}>
                                {intl.get('home.table.useStatus.unfinish')}
                            </Button>

                            <Button
                                style={{ marginRight: "5px", backgroundColor: two_url === "&is_finish=1" ? "#5867dd" : "", color: two_url === "&is_finish=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                onClick={() => {
                                    this.setState({
                                        url: one_url + "&is_finish=1",
                                        two_url: "&is_finish=1",
                                        orderValue: "",
                                        phoneValue: "",
                                    }, () => {
                                        this.columns.splice(5, 1, {
                                            title: intl.get("home.table.dissipate"),
                                            width: '8%',
                                            align: "center",
                                            render: (record) => {
                                                return record.service_at && record.service_at.date.substring(0, 19)
                                            }
                                        })
                                        this.HandleGetData(spu_discount_id, this.state.url, 1)
                                    })
                                }}>
                                {intl.get('home.table.useStatus.completed')}
                            </Button>
                            <Button
                                style={{ marginRight: "5px", backgroundColor: two_url === "&status=9" ? "#5867dd" : "", color: two_url === "&status=9" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                onClick={() => {
                                    this.setState({
                                        url: one_url + "&status=9",
                                        two_url: "&status=9",
                                        orderValue: "",
                                        phoneValue: "",
                                    }, () => {
                                        this.columns.splice(5, 1, {
                                            title: intl.get("home.table.useStatus.refunded_time"),
                                            width: '8%',
                                            align: "center",
                                            render: (record) => {
                                                return record.refund_at && record.refund_at.date.substring(0, 19)
                                            }
                                        })
                                        this.HandleGetRefoundData(spu_discount_id, this.state.url, 1)
                                    })
                                }}>
                                {intl.get('home.table.useStatus.refunded')}
                            </Button>
                        </div>
                    </div>
                    <div>
                        <Tooltip title={intl.get('behavior.Order.amount.setting')}>
                            <Button
                                style={{ marginRight: "15px" }}
                                shape="circle"
                                icon="setting"
                                onClick={() => this.setState({
                                    setVisible: true
                                })}
                            />
                        </Tooltip>
                        <Button
                            style={{ backgroundColor: "#5867dd", color: "#fff" }}
                            onClick={() => this.HandleExportOrder()}>
                            {intl.get('home.table.useStatus.exportOrder')}
                        </Button>
                    </div>

                </div>
                <Table
                    rowKey={record => record.id}
                    dataSource={data}
                    columns={columns}
                    pagination={{
                        pageSize: meta.per_page,
                        total: meta.total
                    }}
                    onChange={(e) => {
                        this.HandleGetData(spu_discount_id, this.state.url, e.current)
                    }}
                    className="waperBox"
                    scroll={{ x: 800 }}
                />
                <Modal
                    title={intl.get("home.table.reject_the_order")}
                    visible={this.state.visible}
                    onOk={() => {
                        if (value === "deny") {
                            putOrderStatus(itemId, { "status": 6 }).then(res => {
                                this.setState({
                                    visible: false
                                })
                                this.HandleGetData(spu_discount_id, this.state.url, 1)
                            })
                        } else {
                            this.setState({ is_error: true })
                        }
                    }}
                    onCancel={() => this.setState({ visible: false })}
                >
                    <div>
                        <span>{intl.get("home.table.confirm_reject")}</span>
                        <Input
                            style={{ marginTop: "20px" }}
                            onChange={(e) => {
                                e.persist()
                                this.setState({
                                    value: e.target.value.toLowerCase()
                                })
                            }} />
                        {
                            is_error && <span>{intl.get("seat.table.please")}"DENY"</span>
                        }

                    </div>

                </Modal>
                <Modal
                    title={intl.get("behavior.Order.amount.setting")}
                    visible={this.state.setVisible}
                    footer={null}
                    onCancel={() => this.setState({ setVisible: false })}
                >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
                        <span>{intl.get("behavior.Online.payment.order")}：</span>
                        <Input
                            style={{ width: "200px" }}
                            disabled
                            prefix={currency_symbol}
                            value={min_pay_fee_on_online}
                        />
                    </div>

                </Modal>
            </div>
        );
    }
}

export default withRouter(Form.create()(orderList))

