import React from 'react'
import { Form, Input, Card, Button } from 'antd';
import { getPartner } from "../../action/authAction";
import intl from 'react-intl-universal';
import licenceImahe from "../../img/token.png"
class UpLiveToken extends React.Component {
    state = {
        up_live_token: ""
    };
    async componentDidMount() {
        let res = await getPartner()
        let data = res.data
        this.setState({
            up_live_token: data.up_live_token,
        })
    }
    render() {
        const { up_live_token } = this.state;
        if (up_live_token) {
            return <Card>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <div style={{ width: "300px", display: "flex", alignItems: "center" }}>
                        <img style={{ width: "60px" }} src={licenceImahe} alt='="' />
                        <div style={{ marginLeft: "20px" }}>
                            <p>{intl.get("nav.plugin.third_party_video_platform")}token:</p>
                            <span>{up_live_token}</span>
                        </div>
                    </div>

                </div>
            </Card>
        } else {
            return null
        }

    }
}

export default UpLiveToken
