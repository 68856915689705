import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, TimePicker, Button, Select, Input, message, Switch } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import intl from 'react-intl-universal';
import moment from 'moment';
import { setBusinesshours, getPartner } from "../../action/authAction"
const { Option } = Select;
const { TextArea } = Input;
class businessHours extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            is_open_on_holiday: true,
            allData: "",
            announcement: ""
        }
    }

    componentDidMount() {
        this.GetData()
    }

    GetData = () => {
        getPartner().then(res => {
            let business_hours = res.data.business_hours
            let weekdays = business_hours.weekdays
            if (weekdays.length > 0) {
                for (let i in weekdays) {
                    let hours = weekdays[i].business_hours
                    let fields = []
                    for (let k in hours) {
                        fields.push(hours[k].split("-"))
                    }
                    weekdays[i].business_hours = fields
                }
            }
            this.setState({
                is_open_on_holiday: business_hours.is_open_on_holiday,
                allData: weekdays,
                announcement: res.data.announcement
            })
        })
    }

    DayData = (data) => {

        let day = ""
        switch (parseInt(data)) {
            case 1:
                day = intl.get('nav.setting.businessHours.week1');
                break;
            case 2:
                day = intl.get('nav.setting.businessHours.week2');
                break;
            case 3:
                day = intl.get('nav.setting.businessHours.week3');
                break;
            case 4:
                day = intl.get('nav.setting.businessHours.week4');
                break;
            case 5:
                day = intl.get('nav.setting.businessHours.week5');
                break;
            case 6:
                day = intl.get('nav.setting.businessHours.week6');
                break;
            case 0:
                day = intl.get('nav.setting.businessHours.week7');
                break;
            default:
                day = intl.get('nav.setting.businessHours.week7');
                break;
        }
        return day
    }

    isRepeat = (arr) => {
        let hash = {};
        for (let i in arr) {

            if (hash[arr[i]]) {
                return this.DayData(arr[i]);
            }
            hash[arr[i]] = true;
        }
        return false;
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let { allData } = this.state
        var obj = [];
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (allData.length > 0) {
                for (let i in allData) {
                    let weekday = allData[i].weekday
                    obj.push(weekday)
                }
            }

            if (this.isRepeat(obj)) {
                message.error(this.isRepeat(obj) + intl.get('nav.setting.businessHours.alreadyExists'))

            } else {
                if (allData.length > 0) {
                    for (let i in allData) {
                        let hours = allData[i].business_hours
                        if (allData[i].weekday === 0) {
                            allData[i].weekday = "0"
                        }
                        for (let k in hours) {

                            let arr1 = hours[k][0].split(":")
                            let arr2 = hours[k][1].split(":")

                            let sum1 = parseInt(arr1[0]) * 60 * 60 + parseInt(arr1[1]) * 60 + parseInt(arr1[2])
                            let sum2 = parseInt(arr2[0]) * 60 * 60 + parseInt(arr2[1]) * 60 + parseInt(arr2[2])
                            if (sum1 > sum2) {
                                message.error(intl.get('nav.setting.businessHours') + (parseInt(i) + 1) + intl.get("nav.setting.businessHoursError"))
                                return false
                            }
                            hours[k] = hours[k][0] + "-" + hours[k][1]
                        }
                    }
                }
                let data = {}
                // data.is_open_on_holiday = is_open_on_holiday ? "1" : "0"
                data.weekdays = allData
                data.announcement = values.announcement
                setBusinesshours(data).then(res => {
                    this.GetData()
                }).catch(error => {
                    this.GetData()
                })

            }
        })
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };
        const { allData, announcement } = this.state
        let obj = [];
        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView">
                <div style={{ width: "700px" }}>
                    {/* <Form.Item
                        label={intl.get('nav.setting.businessHours.isOpenOnHoliday')}
                    >
                        <Switch
                            checked={is_open_on_holiday}
                            onChange={() => {
                                this.setState({
                                    is_open_on_holiday: !is_open_on_holiday
                                })
                            }
                            } />
                    </Form.Item> */}

                    {
                        allData && allData.map((item, i) => {
                            let day = this.DayData(item.weekday)
                            let weekday = item.weekday
                            obj.push(weekday)
                            return (
                                <div key={item.weekday.toString() + i} style={{ marginBottom: "40px" }}>
                                    <Form.Item
                                        label={intl.get('nav.setting.businessHours') + (i + 1)}
                                        style={{ width: "90%", margin: "0px" }}
                                    >
                                        <div>
                                            <Select
                                                style={{ width: "310px" }}
                                                defaultValue={day}
                                                onChange={(value) => {
                                                    let data = [...this.state.allData]
                                                    data[i].weekday = parseInt(value)
                                                    this.setState({
                                                        allData: data
                                                    })
                                                }}>
                                                <Option value="1">{intl.get('nav.setting.businessHours.week1')}</Option>
                                                <Option value="2">{intl.get('nav.setting.businessHours.week2')}</Option>
                                                <Option value="3">{intl.get('nav.setting.businessHours.week3')}</Option>
                                                <Option value="4">{intl.get('nav.setting.businessHours.week4')}</Option>
                                                <Option value="5">{intl.get('nav.setting.businessHours.week5')}</Option>
                                                <Option value="6">{intl.get('nav.setting.businessHours.week6')}</Option>
                                                <Option value="0">{intl.get('nav.setting.businessHours.week7')}</Option>
                                            </Select>
                                            {
                                                allData && allData.length > 1 &&
                                                <MinusCircleOutlined
                                                    style={{ marginLeft: "10px" }}
                                                    onClick={() => {
                                                        let data = [...this.state.allData]
                                                        data.splice(i, 1)
                                                        this.setState({
                                                            allData: data
                                                        })
                                                    }}
                                                />
                                            }

                                        </div>
                                        {item.business_hours.map((field, index) => {
                                            return (
                                                <div key={field.toString() + index}>
                                                    <TimePicker
                                                        format='HH:mm:ss'
                                                        defaultValue={field.length > 0 ? moment(field[0], 'HH:mm:ss') : ""}
                                                        onChange={(val) => {
                                                            item.business_hours[index][0] = moment(val).format('HH:mm:ss')
                                                        }}
                                                    />
                                                    <span style={{ margin: "10px" }}>~</span>
                                                    <TimePicker format='HH:mm:ss'
                                                        defaultValue={field.length > 0 ? moment(field[1], 'HH:mm:ss') : ""}
                                                        onChange={(val) => {
                                                            item.business_hours[index][1] = moment(val).format('HH:mm:ss')
                                                        }}

                                                    />
                                                    {
                                                        item.business_hours.length > 1 &&
                                                        <MinusCircleOutlined
                                                            style={{ marginLeft: "10px" }}
                                                            onClick={() => {
                                                                let data = allData
                                                                data[i].business_hours.splice(index, 1)
                                                                this.setState({
                                                                    allData: data
                                                                })
                                                            }}
                                                        />
                                                    }
                                                </div>
                                            )
                                        })}



                                        <Button
                                            type="dashed"
                                            onClick={() => {
                                                let { allData } = this.state
                                                allData[i].business_hours.push(["00:00:00", "23:59:59"])
                                                this.setState({
                                                    allData
                                                })
                                            }}
                                            style={{ width: '310px' }}
                                        >
                                            Add time
                                        </Button>
                                    </Form.Item>
                                    <Form.Item
                                        label={intl.get('nav.setting.is_rest')}
                                        style={{ width: "90%" }}
                                    >
                                        <Switch checked={item.is_rest === "1"} onChange={(value) => {
                                            let data = [...this.state.allData]
                                            data[i].is_rest = value ? "1" : "0"
                                            this.setState({
                                                allData: data
                                            })

                                        }} />
                                    </Form.Item>
                                </div>
                            )
                        })
                    }

                    <div
                        style={{ textAlign: "center" }}
                    >
                        <Button
                            type="dashed"
                            onClick={() => {
                                let { allData } = this.state
                                // for (let i in allData) {
                                //     if (new Date().getDay() === allData[i].weekday) {
                                //         let day = this.DayData(allData[i].weekday)
                                //         message.error(day + intl.get('nav.setting.businessHours.alreadyExists'))
                                //         return false
                                //     }
                                // }
                                allData.push({ "weekday": new Date().getDay(), "business_hours": [["00:00:00", "23:59:59"]] })
                                this.setState({
                                    allData
                                })
                            }}
                            style={{ width: '310px' }}
                        >
                            Add week
                        </Button>
                    </div>
                    <Form.Item {...formItemLayout}
                        style={{ marginTop: "20px", marginLeft: "-100px" }}
                        label={intl.get('nav.setting.comment')}
                    >
                        {getFieldDecorator('announcement', {
                            initialValue: announcement
                        })(<TextArea />)}
                    </Form.Item>
                    <Form.Item {...tailFormItemLayout}>
                        <Button type="primary" htmlType="submit">
                            {intl.get('cashwithdrawal.form.submit')}
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        );
    }
}

export default withRouter(Form.create()(businessHours))
