import React from 'react'
import 'braft-editor/dist/index.css'
import BraftEditor from 'braft-editor'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Upload, message, Icon, Switch, Popconfirm } from 'antd';
import intl from 'react-intl-universal';
import { getPartnerPhotoDetail, UpdatePartnerPhoto } from "../../action/AdAction";


function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result, img));
    reader.readAsDataURL(img);
}

function beforeUpload(file) {
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
        message.destroy()
        message.error(intl.get('file.size.placehorder'));
    }
    return isLt1M;
}

class EditAlbum extends React.Component {

    state = {
        data: "",
        is_show: true,
        imageUrl: "",
        logo_file: ""
    };


    async componentDidMount() {
        const id = this.props.location.pathname.substring(11)
        let data = await getPartnerPhotoDetail(id)
        this.setState({
            is_show: data.data.is_show === 1 ? true : false,
            imageUrl: data.data.image,
            data: data.data
        })

    }




    SubmitData(data) {
        let formData = new FormData()
        for (let i in data) {
            if (data[i]) {
                formData.append(i, data[i])
            }

        }
        if (this.state.logo_file) {
            formData.append("image_file", this.state.logo_file)
        }
        const id = this.props.location.pathname.substring(11)
        UpdatePartnerPhoto(formData, id).then(res => {
            this.props.history.push("/AlbumList")
        })
    };


    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                values.is_show = this.state.is_show ? "1" : "0"
                this.SubmitData(values)
            }
        });
    };




    handleChange = info => {
        let isOK = beforeUpload(info.file)
        if (isOK) {
            if (info.file) {
                getBase64(info.file, (imageUrl, img) =>
                    this.setState({
                        imageUrl,
                        logo_file: img
                    }),
                );
            }
        }
    };


    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };
        const uploadButton = (
            <div>
                <Icon type='plus' />
                <div className="ant-upload-text">{intl.get('file.btn.name')}</div>
            </div>
        );
        const { is_show, imageUrl, data } = this.state;

        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView">

                <Form.Item
                    label={<span>{intl.get('nav.album.img')}</span>}
                >
                    {getFieldDecorator('album_image', {
                        rules: [{ required: true }],
                    })(
                        <Upload
                            name="avatar"
                            accept=".jpg,.png"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            customRequest={this.handleChange}
                        >
                            {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                        </Upload>
                    )}
                </Form.Item>

                <Form.Item
                    label={intl.get("seat.table.describe")}
                >
                    {getFieldDecorator('describe', {
                        rules: [{ required: false, whitespace: true }],
                        initialValue: data.describe
                    })(<Input />)}
                </Form.Item>

                <Form.Item
                    label={<span>{intl.get('announcementList.table.sort')}</span>}
                >
                    {getFieldDecorator('sort', {
                        initialValue: data.sort
                    })(<Input />)}
                </Form.Item>


                <Form.Item
                    label={intl.get('category.list.isShow')}
                >
                    <Switch checked={is_show} onChange={() => {
                        this.setState({
                            is_show: !is_show
                        })
                    }} />
                </Form.Item>



                <Form.Item {...tailFormItemLayout}>
                    <Popconfirm
                        title={intl.get('seat.table.btn.cancel.placeHorder')}
                        onConfirm={() => {
                            this.props.history.goBack()
                        }}>
                        <Button
                            type="primary"
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                                this.props.history.goBack()
                            }}
                        >
                            {intl.get('seat.table.btn.cancel')}
                        </Button>
                    </Popconfirm>
                    <Button type="primary" htmlType="submit">
                        {intl.get('cashwithdrawal.form.submit')}
                    </Button>
                </Form.Item>
            </Form>

        );
    }
}

export default withRouter(Form.create()(EditAlbum))
