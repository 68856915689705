import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Button, Input, Switch } from 'antd';
import { getPartner, UpdateShopMessage } from "../../action/authAction"
import intl from 'react-intl-universal';
class RoyalPaySet extends React.Component {

    state = {
        data: "",
        is_self_pay: true,
        is_open_stripe: true,
        is_open_paypal: true,
        is_discount_self_pay: true,
    };

    async componentDidMount() {
        let res = await getPartner()
        this.setState({
            data: res.data,
            is_self_pay: res.data.is_self_pay === 1,
            is_open_stripe: res.data.is_open_stripe === 1,
            is_open_paypal: res.data.is_open_paypal === 1,
            is_discount_self_pay: res.data.is_discount_self_pay === 1,
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                values.is_self_pay = this.state.is_self_pay ? "1" : "0"
                values.is_open_stripe = this.state.is_open_stripe ? "1" : "0"
                values.is_open_paypal = this.state.is_open_paypal ? "1" : "0"
                values.is_discount_self_pay = this.state.is_discount_self_pay ? "1" : "0"
                UpdateShopMessage(values).then(res => {
                    // console.log(res)
                })
            }
        });
    };


    render() {
        const { data, is_self_pay, is_open_stripe, is_open_paypal, is_discount_self_pay } = this.state
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 4 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 16 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };


        if (data.package && data.package.is_own_payment == 1) {
            return (
                <Form {...formItemLayout} onSubmit={this.handleSubmit} className="waperBox FormView">
                    <div style={{ height: 30 }}></div>
                    <Form.Item
                        label={intl.get("lang.list.isUse") + " Royal Pay"}
                    >
                        <Switch checked={is_self_pay} disabled onChange={() => {
                            this.setState({
                                is_self_pay: !is_self_pay
                            })
                        }} />
                    </Form.Item>
                    {/* <Form.Item
                label="Is open stripe"
            >
                <Switch checked={is_open_stripe} disabled onChange={() => {
                    this.setState({
                        is_open_stripe: !is_open_stripe
                    })
                }} />
            </Form.Item>
            <Form.Item
                label="Is open paypal"
            >
                <Switch checked={is_open_paypal} disabled onChange={() => {
                    this.setState({
                        is_open_paypal: !is_open_paypal
                    })
                }} />
            </Form.Item> */}
                    {/* <Form.Item
                label="royal pay partner"
            >
                {getFieldDecorator('royal_pay_partner', {
                    rules: [{ required: false }],
                    initialValue: data.royal_pay_partner
                })(<Input disabled />)}
            </Form.Item>

            <Form.Item
                label="royal pay credential"
            >
                {getFieldDecorator('royal_pay_credential', {
                    rules: [{ required: false }],
                    initialValue: data.royal_pay_credential
                })(<Input disabled />)}
            </Form.Item> */}
                    <Form.Item
                        label={intl.get("nav.open_promotion_payment")}
                    >
                        <Switch checked={is_discount_self_pay} disabled onChange={() => {
                            this.setState({
                                is_discount_self_pay: !is_discount_self_pay
                            })
                        }} />
                    </Form.Item>
                    <div style={{ marginTop: "30px" }}>
                        <Form.Item
                            label={intl.get("nav.RefundInstructions")}

                        >
                            <p style={{ margin: "0px", lineHeight: "25px", marginTop: "8px" }}>
                                {intl.get("nav.RefundInstructions_title")}
                            </p>
                            <p style={{ margin: "0px", lineHeight: "25px" }}>
                                {intl.get("nav.RefundInstructions_1")}
                            </p>
                            <p style={{ margin: "0px", lineHeight: "25px" }}>
                                {intl.get("nav.RefundInstructions_2")}
                            </p>
                            <p style={{ margin: "0px", lineHeight: "25px" }}>
                                {intl.get("nav.RefundInstructions_3")}
                            </p>
                            <p style={{ margin: "0px", lineHeight: "25px" }}>
                                {intl.get("nav.RefundInstructions_4")}
                            </p>
                            <p style={{ margin: "0px", lineHeight: "25px" }}>
                                {intl.get("nav.RefundInstructions_5")}
                            </p>
                            <p style={{ margin: "0px", lineHeight: "25px" }}>
                                {intl.get("nav.RefundInstructions_6")}
                            </p>
                        </Form.Item>
                    </div>

                </Form>
            );
        } else {
            return <Form {...formItemLayout} className="waperBox FormView">
                <p>{intl.get("nav.independent_settlement")}</p>
            </Form>
        }

    }
}

export default withRouter(Form.create()(RoyalPaySet))
