import React from "react";
import { withRouter } from 'react-router-dom'
import { Table, Form, Input, Button, Switch, Tooltip,Select, Icon} from 'antd';
import { GetUserList, putUser, getPartner } from "../../action/authAction";
import { getShopList, getShopDetail } from "../../action/shopAction"

import "../category/category.less"
import intl from 'react-intl-universal';
let { Search } = Input
const { Option } = Select;


class UserList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: "",
            meta: "",
            

            shopList: "",
            meta: "",
            url: "",
            partner_id: "",


        };
        this.columns = [
            {
                title: intl.get('common.table.id'),
                dataIndex: 'id',
                width: '5%',
                align: "center",
            },
            {
                title: intl.get('user.table.avatar'),
                width: '5%',
                align: "center",
                render: (res) => {
                    return (
                        <img style={{ width: "50px", height: "50px", objectFit: "cover" }} src={res.head_portrait} alt="" />
                    )
                }
            },
            {
                title: intl.get('user.table.nickname'),
                dataIndex: 'name',
                width: '5%',
                align: "center",
            },
            {
                title: intl.get('user.table.phone'),
                dataIndex: 'phone',
                width: '10%',
                align: "center",
            },
            {
                title: intl.get('setup.form.email'),
                dataIndex: 'email',
                width: '10%',
                align: "center",
            },
            // {
            //     title: intl.get('home.table.address'),
            //     width: '15%',
            //     render: (text, res) => {
            //         let address = res?.userAddress?.data
            //         return (
            //             <div>
            //                 {
            //                     address && address.length !== 0 && address.map((item, index) => {
            //                         return (
            //                             <p key={index}>({index + 1}) {item.fill}</p>
            //                         )
            //                     })
            //                 }
            //             </div>
            //         )
            //     }
            // },

            {
                title: intl.get('user.table.share'),
                width: '5%',
                align: "center",
                render: (record) => {
                    return (
                        <Switch checked={record.is_share === 1} onChange={() => this.HandlePutUser(record)} />
                    )
                }
            },

            {
                title: intl.get('home.table.operation'),
                width: '5%',
                align: "center",
                render: (text, record) => {
                    return (
                        <Tooltip title={intl.get('home.table.operation.btn.view')}>
                            <Button
                                style={{ marginRight: "5px" }}
                                shape="circle"
                                icon="eye"
                                onClick={() => {
                                    this.props.history.push("/userDetail/" + record.id)
                                }} />
                        </Tooltip>
                    )
                }
            },

            //  {
            //     title: intl.get('user.table.growup'),
            //     dataIndex: 'grow_up',
            //     width: '5%',
            //     align: "center"
            // },
            // {
            //     title: intl.get('user.table.money'),
            //     dataIndex: 'money',
            //     width: '5%',
            //     align: "center"
            // },
        ];
    }


    async componentDidMount() {

        let shopData = await getPartner()
        let is_open_share = shopData.data.is_open_share === 0
        if (is_open_share) {
            this.columns.splice(5, 1)
        }

        this.GetUserData(1, "")


        let resp = await getShopList("", "")

        console.log("shoplist===",resp);

        let data = resp.data.data
        console.log(data)

        if(data.length >0 ){
                this.setState({
                    shopList: data,
                    partner_id: data[0].id
                }, () => {
                    this.HandleGetData()
                })
        }else{
              this.setState({
                    shopList: [],
                    partner_id: ''
                }, () => {
                    this.HandleGetData()
                })   
        }
        







    }



    async HandleGetData() {
        let { page, partner_id, url } = this.state


         this.GetUserData(1, "",partner_id);

        /* 点击后查询数据
        let res = await getCouponList(page, partner_id, url || "")
        let shopData = await getShopDetail(partner_id, 1)
        this.setState({
            data: res.data.data,
            is_eatmoon_show: shopData.data.is_eatmoon_show === 1,
            meta: res.data.meta.pagination
        })
        */


    }





    async GetUserData(num, url,partner_id=0) {
        let res = await GetUserList(num, url,partner_id)
        this.setState({
            data: res.data.data,
            meta: res.data.meta.pagination
        })
    }

    HandlePutUser = (item) => {
        let { is_share, id } = item
        putUser({ "is_share": is_share === 1 ? "0" : "1" }, id).then(res => {
            this.GetUserData(1, "")
        })

    }


    render() {
        const {  shopList, url, closed, partner_id   ,data, meta } = this.state
        if (data) {
            return (
                <div>
                    <div>
 

                        {
                            shopList &&
                         <Select showSearch
                            placeholder="请选择店铺"
                            style={{ width: 300, marginLeft: "0px", marginRight: "10px" }}
                            onChange={(e, value) => {
                                this.setState({
                                    partner_id: e,
                                    url: ""
                                }, () => this.HandleGetData())

                            }}
                            value={partner_id}
                        >
                            {
                                shopList.map(item => {
                                    return <Option value={item.id} key={item.id}>{item.title}</Option>
                                })
                            }
                        </Select> 

                        }


                            <Tooltip title={'请选择店铺'}>
                                <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                            </Tooltip>


                        {/*
                        <Search
                            placeholder={intl.get("home.placehorder_p")}
                            allowClear
                            enterButton={intl.get("home.search")}
                            // size="large"
                            style={{ width: 250, marginRight: "10px" }}
                            onSearch={(value) => {
                                this.GetUserData(1, "&phone=" + value)
                            }}
                        />
                        <Search
                            placeholder={intl.get("setup.form.email_enter")}
                            allowClear
                            enterButton={intl.get("home.search")}
                            // size="large"
                            style={{ width: 250 }}
                            onSearch={(value) => {
                                this.GetUserData(1, "&email=" + value)
                            }}
                        />
                        */}

                        
                    </div>
                    <Table
                        rowKey={record => record.id}
                        dataSource={data}
                        columns={this.columns}
                        pagination={{
                            pageSize: meta.per_page,
                            total: meta.total
                        }}
                        onChange={(e) => {
                            this.GetUserData(e.current, "")
                        }}
                        className="waperBox"
                        style={{ marginTop: "10px" }}
                    />
                </div>

            );
        } else {
            return (<div />)
        }
    }
}

export default withRouter(Form.create()(UserList))

