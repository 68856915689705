import { Axios } from "../util/request"
import { API } from "../util/api";
import axios from 'axios'
import { BaseUrl } from '../util/function';

//获取已申请商家列表
export const getShopList = (num, url, language_id) => {
    return Axios.ajax({
        //url: API.shopList.partner + API.shopList.include + API.shopList.page2 + num + url + "&request_language_id=" + language_id,
        url: API.shopList.partner  
    })
}

//获取商家套餐日志
export const getPackageLog = (url, page) => {
    return Axios.ajax({
        url: API.packageLog.packageLog + API.packageLog.include + url + "&page=" + page,
    })
}
//新建商家套餐日志
export const AddPackageLog = (data) => {
    return Axios.ajax({
        url: API.packageLog.packageLog,
        method: "post",
        tips: "新建成功",
        data
    })
}


//获取商家年费记录
export const getPackageFeeLog = (page, shop_id, language_id, url, date) => {
    return Axios.ajax({
        url: API.partnerPackageFeeLog + "?page=" + page + "&type=annual" + "&partner_id=" + shop_id + "&request_language_id=" + language_id + url,
    })
}


//新建商家年费记录
export const NewPackageFeeLog = (data) => {
    return Axios.ajax({
        url: API.partnerPackageFeeLog,
        method: "post",
        tips: "新建成功",
        data
    })
}
// 修改商家年费记录
export const UpdatePackageFeeLog = (id, data) => {
    return Axios.ajax({
        url: API.partnerPackageFeeLog + "/" + id,
        method: "put",
        tips: "修改成功",
        data
    })
}
// 删除商家年费记录
export const deletePackageFeeLog = (id) => {
    return Axios.ajax({
        url: API.partnerPackageFeeLog + "/" + id,
        method: "delete",
        tips: "删除成功",
    })
}
// 获取商家年费记录详情
export const getPackageFeeLogDetail = (id, language_id) => {
    return Axios.ajax({
        url: API.partnerPackageFeeLog + "/" + id + "?request_language_id=" + language_id,
    })
}

//新建商家
export const NewShop = (data) => {
    return Axios.ajax({
        url: API.shopList.partner,
        method: "post",
        tips: "新建成功",
        data
    })
}

//修改商家信息
export const putShop = (id, data) => {
    return Axios.ajax({
        url: "partnerupdate" + "/" + id,
        method: "post",
        tips: "修改成功",
        data
    })
}

//获取商家详情
export const getShopDetail = (id, language_id) => {
    return Axios.ajax({
        url: API.shopList.partner + "/" + id + API.shopList.include + "&request_language_id=" + language_id
    })
}

//获取推荐商家列表
export const getRecommendShopList = (num) => {
    return Axios.ajax({
        url: API.importPartner.importPartner + API.shopList.page + num
    })
}

//修改推荐商家
export const putRecommendShop = (id, data) => {
    return Axios.ajax({
        url: API.importPartner.importPartner + "/" + id,
        method: "put",
        tips: "修改成功",
        data
    })
}

// 删除推荐商家
export const deleteRecommendShop = (id) => {
    return Axios.ajax({
        url: API.importPartner.importPartner + "/" + id,
        method: "delete",
        tips: "删除成功",
    })
}

//商家语言列表
export const getPartnerLangList = () => {
    return Axios.ajax({
        url: API.partnerLang,
        method: "get"
    })
}
//增加语言列表
export const AddPartnerLang = (data) => {
    return Axios.ajax({
        url: API.partnerLang,
        method: "post",
        tips: "新建成功",
        data
    })
}
//修改语言列表
export const editPartnerLang = (id, data) => {
    return Axios.ajax({
        url: API.partnerLang + "/" + id,
        method: "put",
        tips: "修改成功",
        data
    })
}
//删除语言列表
export const deletePartnerLanguage = (id) => {
    return Axios.ajax({
        url: API.partnerLang + "/" + id,
        method: "delete",
        tips: "删除成功",
    })
}


//地区列表
export const QueryRegion = () => {
    return Axios.ajax({
        url: API.region,
        method: "get"
    })
}



//皮肤列表
export const getStyleList = () => {
    return Axios.ajax({
        url: API.style,
        method: "get"
    })
}
//增加皮肤
export const AddStyle = (data) => {
    return Axios.ajax({
        url: API.style,
        method: "post",
        tips: "新建成功",
        data
    })
}
//获取皮肤详情
export const getStyleDetail = (id) => {
    return Axios.ajax({
        url: API.style + "/" + id,
        method: "get",
    })
}
//修改皮肤
export const editStyle = (id, data) => {
    return Axios.ajax({
        url: API.style + "/" + id,
        method: "POST",
        tips: "修改成功",
        data
    })
}
//删除皮肤
export const deleteStyle = (id) => {
    return Axios.ajax({
        url: API.style + "/" + id,
        method: "delete",
        tips: "删除成功",
    })
}

//广告列表
export const getAdvertisementList = () => {
    return Axios.ajax({
        url: API.systemAdvertisement,
        method: "get"
    })
}
//增加广告
export const AddAdvertisement = (data) => {
    return Axios.ajax({
        url: API.systemAdvertisement,
        method: "post",
        tips: "新建成功",
        data
    })
}
//获取广告详情
export const getAdvertisementDetail = (id, language_id) => {
    return Axios.ajax({
        url: API.systemAdvertisement + "/" + id + "?request_language_id=" + language_id,
        method: "get",
    })
}
//修改广告
export const editAdvertisement = (id, data) => {
    return Axios.ajax({
        url: API.systemAdvertisement + "/" + id,
        method: "POST",
        tips: "修改成功",
        data
    })
}
//删除广告
export const deleteAdvertisement = (id) => {
    return Axios.ajax({
        url: API.systemAdvertisement + "/" + id,
        method: "delete",
        tips: "删除成功",
    })
}
//文档列表
export const getDocumentList = () => {
    return Axios.ajax({
        url: API.document,
        method: "get"
    })
}
//增加广告
export const AddDocument = (data) => {
    return Axios.ajax({
        url: API.document,
        method: "post",
        tips: "新建成功",
        data
    })
}
//获取广告详情
export const getDocumentDetail = (id, language_id) => {
    return Axios.ajax({
        url: API.document + "/" + id + "?request_language_id=" + language_id,
        method: "get",
    })
}
//修改广告
export const editDocument = (id, data) => {
    console.log(data)
    return Axios.ajax({
        url: API.document + "/" + id,
        method: "POST",
        tips: "修改成功",
        data
    })
}
//删除广告
export const deleteDocument = (id) => {
    return Axios.ajax({
        url: API.document + "/" + id,
        method: "delete",
        tips: "删除成功",
    })
}

//伙伴列表
export const getPartnerList = () => {
    return Axios.ajax({
        url: API.empartnerList.partner,
        method: "get"
    })
}



//销售大区列表
export const getSalesAreaList = () => {
    return Axios.ajax({
        url: API.salesArea,
        method: "get"
    })
}







//增加销售大区
export const AddSalesArea = (data) => {
    return Axios.ajax({
        url: API.salesArea,
        method: "post",
        tips: "新建成功",
        data
    })
}
//获取销售大区详情
export const getSalesAreaDetail = (id) => {
    return Axios.ajax({
        url: API.salesArea + "/" + id,
        method: "get",
    })
}
//修改销售大区
export const editSalesArea = (id, data) => {
    return Axios.ajax({
        url: API.salesArea + "/" + id,
        method: "PUT",
        tips: "修改成功",
        data
    })
}
//删除销售大区
export const deleteSalesArea = (id) => {
    return Axios.ajax({
        url: API.salesArea + "/" + id,
        method: "delete",
        tips: "删除成功",
    })
}

//销售小区列表
export const getSalesAreaSubList = (url) => {
    return Axios.ajax({
        url: API.salesAreaSub + url,
        method: "get"
    })
}
//增加销售小区
export const AddSalesAreaSub = (data) => {
    return Axios.ajax({
        url: API.salesAreaSub,
        method: "post",
        tips: "新建成功",
        data
    })
}
//获取销售小区详情
export const getSalesAreaSubDetail = (id) => {
    return Axios.ajax({
        url: API.salesAreaSub + "/" + id,
        method: "get",
    })
}
//修改销售小区
export const editSalesAreaSub = (id, data) => {
    return Axios.ajax({
        url: API.salesAreaSub + "/" + id,
        method: "PUT",
        tips: "修改成功",
        data
    })
}
//删除销售小区
export const deleteSalesAreaSub = (id) => {
    return Axios.ajax({
        url: API.salesAreaSub + "/" + id,
        method: "delete",
        tips: "删除成功",
    })
}

//国家列表
export const getRegionList = () => {
    return Axios.ajax({
        url: API.region,
        method: "get"
    })
}
//增加国家
export const AddRegion = (data) => {
    return Axios.ajax({
        url: API.region,
        method: "post",
        tips: "新建成功",
        data
    })
}
//获取国家详情
export const getRegionDetail = (id) => {
    return Axios.ajax({
        url: API.region + "/" + id,
        method: "get",
    })
}
//修改国家
export const EditRegion = (id, data) => {
    return Axios.ajax({
        url: API.region + "/" + id,
        method: "post",
        tips: "修改成功",
        data
    })
}
//删除国家
export const deleteRegion = (id) => {
    return Axios.ajax({
        url: API.region + "/" + id,
        method: "delete",
        tips: "删除成功",
    })
}



//补充sub列表
export const getAddressSubList = () => {
    return Axios.ajax({
        url: API.address,
        method: "get"
    })
}
//增加补充sub
export const AddAddressSub = (data) => {
    return Axios.ajax({
        url: API.address,
        method: "post",
        tips: "新建成功",
        data
    })
}

//修改补充sub
export const editAddressSub = (id, data) => {
    return Axios.ajax({
        url: API.address + "/" + id,
        method: "PUT",
        tips: "修改成功",
        data
    })
}
//删除补充sub
export const deleteAddressSub = (id) => {
    return Axios.ajax({
        url: API.address + "/" + id,
        method: "delete",
        tips: "删除成功",
    })
}


//搜索sub
export const queryAddress = (value) => {
    return axios(BaseUrl() + "/api/addressQuery?query=" + value)
}

//系统充值列表
export const getSystemRechargeList = (page) => {
    return Axios.ajax({
        url: API.userMoneyLog + "&page=" + page,
        method: "get"
    })
}

// 公共假期列表
export const getHolidayList = (url) => {
    return Axios.ajax({
        url: API.holiday + "?state=nsw" + url,
        method: "get",
    })
}


// 公共假期详情
export const getHolidayDetail = (id) => {
    return Axios.ajax({
        url: API.holiday + "/" + id,
        method: "get",
    })
}

// 新建公共假期
export const AddHoliday = (data) => {
    return Axios.ajax({
        url: API.holiday,
        method: "post",
        tips: "提交成功",
        data
    })
}
// 导入公共假期
export const ImportHoliday = (data) => {
    return Axios.ajax({
        url: API.holidayImport,
        method: "post",
        tips: "提交成功",
        data
    })
}

// 编辑公共假期
export const putHoliday = (data, id) => {
    return Axios.ajax({
        url: API.holiday + "/" + id,
        method: "put",
        tips: "修改成功",
        data,
    })
}

// 删除公共假期
export const deleteHoliday = (id) => {
    return Axios.ajax({
        url: API.holiday + "/" + id,
        method: "delete",
        tips: "删除成功",
    })
}
