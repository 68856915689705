export default {
  'nav.home': '首页',
  'nav.user': '客户',
  'nav.orderManagement': '订单管理',
  'nav.orderManagement.list': '订单',
  'nav.orderManagement.orderLine': '排队订单',
  'nav.event': '促销打折',
  'nav.event.openSelected': '开启精选商品',
  'nav.event.selected': '精选商品',
  'nav.tools': '工具',
  'nav.behavior': '行为',
  'nav.business': '商家',
  'nav.account': '我的',
  'nav.behavior.MinimumOrderAmount': '订单最低金额',
  'nav.money': '账单',
  'nav.money.withdrawalRecord': '提现记录',
  'nav.money.withdrawal': '提现',
  'nav.goodsManagement': '商品',
  'nav.goodsManagement.goods': '商品',
  'nav.goodsManagement.goods.newGood': '新建',
  'nav.goodsManagement.goods.goodslist': '商品列表',
  'nav.goodsManagement.goods.prompt': '商品图片允许的格式为:jpg,jpeg,png。上传的图片内存现在在1M之内。',
  'nav.goodsManagement.goods.isdeletegoodslist': '归档列表',
  'nav.goodsManagement.classify': '分类',
  'nav.goodsManagement.classify.addClassify': '添加分类',
  'nav.goodsManagement.classify.classifyList': '分类',

  'nav.goodsManagement.openSpecifications': '开启商品规格',
  'nav.goodsManagement.specifications': '商品规格',
  'nav.goodsManagement.sku_goods': '规格商品',
  'nav.goodsManagement.coupon_goods': '优惠券商品',
  'nav.goodsManagement.specifications.add': '添加商品规格',
  'nav.goodsManagement.itemspecifications': '商品规格值',
  'nav.goodsManagement.itemspecifications.add': '添加商品规格值',

  'nav.goodsManagement.additional': '附加商品',
  'nav.goodsManagement.isitional': '是否需要附加商品',
  'nav.goodsManagement.additional.add': '添加附加商品组',
  'nav.goodsManagement.additional.list': '附加商品组',
  'nav.goodsManagement.itemadditional': '附加商品内容',
  'nav.goodsManagement.itemadditional.add': '添加附加商品值',
  'nav.goodsManagement.itemadditional.list': '附加商品值',
  'nav.goodsManagement.noAdditionalGoods': '暂无附加商品',
  'nav.goodsManagement.rrp_cannot_price': 'RRP 价格必须大于商品价格',
  'nav.goodsManagement.fill_in_price': '请先填入价格',
  'nav.goodsManagement.save_sku_first': '请先保存商品sku',
  'nav.goodsManagement.sku_null': '商品sku不能为空',

  'goods.list.goodsAnnex.name': '附加商品名称',
  'goods.list.goodsAnnex.name_horder': '请输入附加商品名称',
  'goods.list.goodsAnnex.price': '附加商品价格',
  'goods.list.goodsAnnex.price_horder': '请输入附加商品价格',
  'goods.list.goodsAnnexType.chooseNumError': '附加商品数量不能小于最少选择条数',
  'goods.list.goodsAnnexType.minOneError': '最少选择一个附加商品',

  'nav.goodsManagement.promotionalgoods': '促销商品',
  'nav.goodsManagement.promotionalgoods_name': '名称',
  'nav.goodsManagement.promotionalgoods_name_error': '请输入名称',
  'nav.goodsManagement.couponOrder': '活动订单',
  'nav.goodsManagement.promotionalOrder': '促销打折订单',
  'nav.goodsManagement.promotionalCoupon': '优惠券订单',
  'nav.goodsManagement.ordinaryOrder': '商品订单',
  'nav.goodsManagement.promotionalgoods.add': '新建',
  'nav.goodsManagement.promotionalgoods.addTips': '每个月最多新建10个活动，最多有3个活动上架',
  'nav.goodsManagement.promotionalgoods.chooseGoodsTips': '选择的商品为不包含附加商品与商品规格的商品',
  'nav.goodsManagement.promotionalgoods.list': '促销打折',
  'nav.goodsManagement.promotionalgoods.coupon': '优惠券',
  'nav.goodsManagement.promotionalgoods.coupon_new': '新建',
  'nav.goodsManagement.promotionalgoods.coupon_name': '优惠券名称',
  'nav.goodsManagement.promotionalgoods.coupon_count': '优惠券数量',
  'nav.goodsManagement.promotionalgoods.coupon_receive_count': '领取数量',
  'nav.goodsManagement.promotionalgoods.coupon_use_count': '使用数量',
  'nav.goodsManagement.promotionalgoods.coupon_remaining_count': '剩余数量',
  'nav.goodsManagement.promotionalgoods.coupon_total_count': '总数量',
  'nav.goodsManagement.promotionalgoods.discount_type': '促销类型',
  'nav.goodsManagement.promotionalgoods.quota_discount': '定额折扣',
  'nav.goodsManagement.promotionalgoods.percent_discount': '百分比折扣',
  'nav.goodsManagement.promotionalgoods.coupon_type': '优惠券类型',
  'nav.goodsManagement.promotionalgoods.coupon_discount_type': '折扣类型',
  'nav.goodsManagement.promotionalgoods.coupon_type_goods': '商品优惠券',
  'nav.goodsManagement.promotionalgoods.coupon_type_shop': '店铺优惠券',
  'nav.goodsManagement.promotionalgoods.coupon_type_poster': '海报',
  'nav.goodsManagement.promotionalgoods.coupon_style': '优惠券样式',
  'nav.goodsManagement.promotionalgoods.coupon_style_default': '默认',
  'nav.goodsManagement.promotionalgoods.coupon_style_self': '自定义',
  'nav.goodsManagement.promotionalgoods.coupon_style_img': '自定义图片',
  'nav.goodsManagement.promotionalgoods.marketing_type': '营销类型',
  'nav.goodsManagement.promotionalgoods.external_coupon': '全站优惠券',
  'nav.goodsManagement.promotionalgoods.coupon_detail': '优惠券详情',
  'nav.goodsManagement.promotionalgoods.coupon_number': '优惠券编号',

  'nav.goodsManagement.promotionalgoods.isBuy': '系统已购',
  'nav.goodsManagement.promotionalgoods.during_the_event': '活动期间',
  'nav.goodsManagement.promotionalgoods.price_type': '价格类型',
  'nav.goodsManagement.promotionalgoods.discount_on_fixed': '折扣',
  'nav.goodsManagement.promotionalgoods.discount_on_percentage': '打折(%)',
  'nav.goodsManagement.promotionalgoods.time': '促销打折时间',
  'nav.goodsManagement.promotionalgoods.term_of_validity': '有效期',
  'nav.goodsManagement.promotionalgoods.timeError': '请选择促销打折时间',
  'nav.goodsManagement.sales_promotion': '促销打折',
  'nav.goodsManagement.promotionalgoods.goodsImg': '活动图片',
  'nav.goodsManagement.promotionalgoods.goodsImgError': '请上传图片',
  'nav.goodsManagement.promotionalgoods.activity_service_rate': '活动服务费',
  'nav.goodsManagement.promotionalgoods.order_fixed_fee': '固定服务费',
  'nav.goodsManagement.promotionalgoods.activity_service_rate_money': '服务费结算',
  'nav.goodsManagement.promotionalgoods.order_fixed_fee': '固定费用',
  'nav.goodsManagement.promotionalgoods.stock': '总数量',
  'nav.goodsManagement.promotionalgoods.total_inventory': '总库存',
  'nav.goodsManagement.promotionalgoods.Remaining_inventory': '剩余库存',
  'nav.goodsManagement.promotionalgoods.stockEnter': '请输入库存',
  'nav.goodsManagement.promotionalgoods.stock_error': '不能小于原库存',
  'nav.goodsManagement.promotionalgoods.integer': '请输入大于1的整数',
  'nav.goodsManagement.promotionalgoods.sale_quantity': '销量',
  'nav.goodsManagement.promotionalgoods.quota': '限额',
  'nav.goodsManagement.promotionalgoods.full_price': '满减额',
  'nav.goodsManagement.promotionalgoods.full_price_tips': '不填写就是无门槛',
  'nav.goodsManagement.promotionalgoods.full_price_error_tips': '满减金额为整数或者最多两位小数',
  'nav.goodsManagement.promotionalgoods.full_price_error': '满减金额不能少于折扣金额+最小付款金额',
  'nav.goodsManagement.promotionalgoods.total_quota': '每个用户最多可以购买数量',
  'nav.goodsManagement.promotionalgoods.coupon_total_quota': '每个用户最多可以领取数量',
  'nav.goodsManagement.promotionalgoods.select_goods_word_tips': '输入3个字符以上自动搜索',
  'nav.goodsManagement.promotionalgoods.expire_day': '促销打折订单有效天数',
  'nav.goodsManagement.promotionalgoods.discount_price': '折扣后价格',
  'nav.goodsManagement.promotionalgoods.price': '原价',
  'nav.goodsManagement.promotionalgoods.selectError': '请选择一个商品',
  'nav.goodsManagement.promotionalgoods.quotaError': '限额最小为1',
  'nav.goodsManagement.promotionalgoods.discountPrice': '折扣价格不能大于商品价格',
  'nav.goodsManagement.promotionalgoods.minimumOrder': '折扣后价格不能大于付款订单最低金额',
  'nav.goodsManagement.promotionalgoods.orderValidity': '订单有效期',
  'nav.goodsManagement.promotionalgoods.max_quantity': '最大数量',
  'nav.goodsManagement.promotionalgoods.Single_max_quantity': '单次购买最大数量',
  'nav.goodsManagement.promotionalgoods.min_quantity': '最小数量',
  'nav.goodsManagement.promotionalgoods.Single_min_quantity': '单次购买最小数量',
  'nav.goodsManagement.promotionalgoods.inside': '内部',
  'nav.goodsManagement.promotionalgoods.external': '全站',
  'nav.goodsManagement.promotionalgoods.mysterious': '神秘',
  'nav.goodsManagement.promotionalgoods.operationType_tips': '请选择运营类型',
  'nav.goodsManagement.promotionalgoods.operationType': '运营类型',
  'nav.goodsManagement.promotionalgoods.on-line': '线上运营',
  'nav.goodsManagement.promotionalgoods.Offline': '线下运营',
  'nav.goodsManagement.coupon.detail_tips': '*领取优惠券后，或可到店出示优惠券或订单，店员可进行确认。该优惠券的解释权，归商家所有。',


  'nav.goodsManagement.promotionalgoods.displayOnly': '(该价格仅作展示)',
  'nav.goodsManagement.promotionalgoods.OffTheShelf': '上架',
  'nav.goodsManagement.promotionalgoods.OffTheShelf_tips': '该活动下架后，不可以再上架',
  'nav.goodsManagement.promotionalgoods.InputError': '输入错误！',
  'nav.goodsManagement.promotionalgoods.EnterClosed': '请在输入框中输入"confirm"确认',
  'nav.goodsManagement.promotionalgoods.end': '已结束',
  'nav.goodsManagement.promotionalgoods.Terminated': '已终止',
  'nav.goodsManagement.promotionalgoods.NormalSales': '正常',
  'nav.goodsManagement.promotionalgoods.select_products': '请先选择商品',
  'nav.goodsManagement.promotionalgoods.1_person_required': '1个人为必选项',
  'nav.goodsManagement.promotionalgoods.2_person_required': '2个人为必选项',
  'nav.goodsManagement.promotionalgoods.price_less_than_1': '价格不能低于$1',
  'nav.goodsManagement.promotionalgoods.two_people_cannot_equal_one_person': '2个人砍价价格不能大于等于1个人',
  'nav.goodsManagement.promotionalgoods.three_people_cannot_equal_two_person': '3个人砍价价格不能大于等于2个人',
  'nav.goodsManagement.promotionalgoods.enter_integer': '请输入整数',
  'nav.goodsManagement.promotionalgoods.quota_cannot_stock': '限额不能大于库存',
  'nav.goodsManagement.promotionalgoods.maximum_cannot_limit': '最大数量不能大于限额',
  'nav.goodsManagement.promotionalgoods.minimum_cannot_maximum': '最小数量不能大于最大数量',
  'nav.goodsManagement.promotionalgoods.minimum_cannot_limit': '最小数量不能大于限额',
  'nav.goodsManagement.promotionalgoods.detail': '活动详情',
  'nav.goodsManagement.cutgoods.BargainingNumber': '砍价人数',
  'nav.goodsManagement.cutgoods.isOpen': '是否开启',
  'nav.goodsManagement.cutgoods': '砍一刀商品列表',
  'nav.goodsManagement.cutgoodsType': '砍一刀',
  'nav.goodsManagement.openCutGoodsType': '开启砍一刀',
  'nav.goodsManagement.openAward': '开启派奖',
  'nav.goodsManagement.OpenAdditionalItems': '开启附加商品',
  'nav.goodsManagement.OpenInStoreItems': '开启下订单到店时间',
  'nav.goodsManagement.send_receipt': '强制邮箱',
  'nav.goodsManagement.send_receipt_tips': '下订单页用户必须填写邮箱',
  'nav.goodsManagement.OpenUserAddress': '开启客户地址',
  'nav.goodsManagement.Forced_open': '强制开启',
  'nav.goodsManagement.Forced_open_tips': '店内消费和到店自提将不显示（h5端，不显示店内消费和到店自提的tab）',
  'nav.goodsManagement.OpenHoliday': '开启公共假期',
  'nav.goodsManagement.OpenOrderReceiving': '开启接单',
  'nav.goodsManagement.Promotion': '促销',
  'nav.goodsManagement.isPromotion': '是否促销',
  'nav.goodsManagement.coupon_detail': 'Ticket',
  'nav.goodsManagement.coupon_user': '客户',
  'nav.goodsManagement.coupon_date': '领取时间',
  'nav.goodsManagement.coupon_use_date': '使用时间',
  'nav.goodsManagement.coupon_barcode': '条码',
  'nav.goodsManagement.coupon_ticket_unique_code': 'Ticket编码',


  'nav.delivery': '配送方式',
  'nav.delivery.discount': '配送方式打折',
  'nav.delivery.discount.new': '新建配送方式打折',
  'nav.delivery.discount.list': '配送方式打折列表',
  'nav.delivery.area': '送餐区域',
  'nav.delivery.area.add': '添加送餐区域',
  'nav.delivery.area.list': '送餐区域列表',
  'nav.delivery.packingCharge': '打包费',
  'nav.delivery.packingCharge.add': '添加打包费',
  'nav.delivery.packingCharge.list': '打包费列表',
  'nav.delivery.set': '配送方式设置',
  'nav.extension': '推广',
  'nav.extension.tag': 'tag',
  'nav.extension.tag.new': '新建tag',
  'nav.extension.tag.list': 'tag列表',
  'nav.extension.carousel': '轮播图',
  'nav.extension.carousel.add': '新建轮播图',
  'nav.extension.carousel.list': '轮播图列表',
  'nav.extension.dishRecommendation': '菜牌推荐',
  'nav.extension.dishRecommendation.add': '新建菜牌',
  'nav.extension.dishRecommendation.list': '菜牌列表',
  'nav.extension.partnerNav': '导航',
  'nav.extension.partnerNav.list': '导航列表',
  'nav.extension.groupRed': '群红包',
  'nav.extension.groupRed.get': '申请红包',
  'nav.extension.groupRed.list': '红包列表',
  'nav.setting': '设置',
  'nav.setting.base': '基本设置',
  'nav.setting.password': '修改密码',
  'nav.setting.password.password': '密码',
  'nav.setting.password.confirm': '确认密码',
  'nav.setting.password.error': '两次密码不一致',
  'nav.setting.management': '店员',
  'nav.setting.businessHours': '营业时间',
  'nav.setting.is_rest': '是否休息',
  'nav.setting.businessHoursError': '格式错误',
  'nav.setting.holiday_business': '假期营业',
  'nav.setting.public_holidays': '公共假期',
  'nav.setting.holidays': '假期',
  'nav.setting.comment': '说明',
  'nav.setting.self_holidays': '自定义假期',
  'nav.setting.public_holidays_detail': '详情',
  'nav.setting.public_holidays_date': '时间',
  'nav.setting.select_public_holidays': '请选择假期',
  'nav.setting.businessHours.isOpenOnHoliday': '节假日是否开放营业',
  "nav.setting.businessHours.week1": '星期一',
  "nav.setting.businessHours.week2": '星期二',
  "nav.setting.businessHours.week3": '星期三',
  "nav.setting.businessHours.week4": '星期四',
  "nav.setting.businessHours.week5": '星期五',
  "nav.setting.businessHours.week6": '星期六',
  "nav.setting.businessHours.week7": '星期日',
  "nav.setting.businessHours.alreadyExists": '已存在',
  'nav.setting.notice': '公告',
  'nav.setting.notice.add': '新建公告',
  'nav.setting.notice.list': '公告列表',
  'nav.setting.notice.system': '系统公告',
  'nav.setting.seat': '桌号',
  'nav.setting.seat.add': '新建桌号',
  'nav.setting.seat.list': '桌号列表',
  'nav.setting.seat.open_the_sign': '是否开启立牌',
  'nav.setting.freight': '运费',
  'nav.setting.freight.add': '新建运费',
  'nav.setting.SiteLocation': '归属地',
  'nav.setting.SiteSuburb': '地区',
  'nav.setting.lang': '语言',
  'nav.setting.lang.list': '语言列表',
  'nav.setting.printer': '打印机',
  'nav.setting.printerList': '打印机列表',
  'nav.setting.printerBrand': '打印机品牌',
  'nav.setting.printerType': '打印机型号',
  'nav.setting.printerName': '打印机名称',
  'nav.setting.printerDefaultName': '打印机别名',
  'nav.setting.printerAddTime': '添加时间',
  'nav.setting.printerSn': '编号',
  'nav.setting.printerSecretKey': '密钥',
  'nav.setting.automaticPrinting': '自动打印',
  'nav.setting.openPrinter': '开启打印',

  'nav.download.app_download': 'Auepartner APP',
  'nav.download.merchant': '商家管理端',

  'nav.CostFlow.other': '其他',
  'nav.CostFlow.order': '订单',
  'nav.CostFlow.income': '收入',
  'nav.CostFlow.expenditure': '支出',
  'nav.CostFlow.name': '名称',
  'nav.CostFlow.cost': '金额',
  'nav.CostFlow.costType': '金额类型',
  'nav.CostFlow.feeType': '费用类型',
  'nav.PaySet': '支付设置',
  'nav.PayRoute': '支付途径',
  'nav.CostFlow.other.billingDetails': '账单详情',

  'nav.album': '店铺相册',
  'nav.album.img': '相册图片',
  'nav.open_promotion_payment': '开启促销独立支付',
  'nav.RefundInstructions': '退款说明',
  'nav.RefundInstructions_title': '关于C端客户的已付款订单，退款的说明：',
  'nav.RefundInstructions_1': '1、当C端客户将订单付款后，如果是需要接单的订单，在未接单并且超过接单期限，系统将自动退款到C端客户的钱包中。',
  'nav.RefundInstructions_2': '2、当C端客户将活动订单付款后，超过待消费的期限，系统将自动退款到C端客户的钱包中。',
  'nav.RefundInstructions_3': '3、C端客户的订单，被自动退款后，系统识别，如果是由商家独立结算的订单，则将在商家后台-钱包-流水列表中，展示出来。并在次月的 系统 toB 的付款中进行结算。',
  'nav.RefundInstructions_4': '4、C端客户的已付款订单，如果使用了自己的钱包余额付款，则该订单所使用的余额，将在次月的 系统 toB 的付款中进行结算。',
  'nav.RefundInstructions_5': '5、如果C端客户申请第三方支付渠道或线下手动的银行转账退款到个人账号，商家无需为他服务，而将由系统管理员为他服务，无需商家介入。',
  'nav.RefundInstructions_6': '6、系统不承认商家与C端客户进行的第三方支付渠道或线下手动的银行转账退款到个人账号的行为。',
  'nav.independent_settlement': '该商家不支持独立结算',

  'nav.bindPay': '支付',
  'nav.plugin': '插件',
  'nav.plugin.toStore': '到店插件',
  'nav.plugin.currency': '通用',
  'nav.plugin.deliveryPlug': '配送插件',
  'nav.plugin.third_party_video_platform': '第三方视频平台',
  'nav.package': '套餐',
  'nav.package.list': '套餐列表',
  'nav.package.currentPackage': '当前套餐',
  'nav.package.packageLog': '套餐日志',
  'nav.package.packageLog.list': '套餐日志列表',
  'nav.subscription': '我的订阅',
  'nav.myOrders': '订阅订单',
  'nav.datastatics': '数据统计',

  'home.phoneNumber': '电话：',
  'home.search': '查询',
  'home.placehorder': '请输入订单号',
  'home.placehorder_p': '请输入电话',

  'home.table.uuid': 'uuid',
  'home.table.ordernumber': '订单编号',
  'home.table.orderprice': '订单原价',
  'home.table.price': '付款金额',
  'home.table.spu_discount_price': '商品小计',
  'home.table.order_price': '订单合计',
  'home.table.order_package': '套餐信息',
  'home.table.order_coupon': '优惠券信息',
  'home.table.order_package_id': '套餐id',
  'home.table.use_user_money': '钱包抵扣',
  'home.table.use_user_coupon': '优惠券抵扣',
  'home.table.bank': '支付通道手续费',
  'home.table.totalprice': '实际付款金额',
  'home.table.partner_charge_money': '订单服务费',
  'home.table.partner_charge_rate': '订单服务费百分比',
  'home.table.change': '(变更)',
  'home.table.partner_fixed_fee': '订单固定抽成',
  'home.table.payee': '收款方',
  'home.table.payment_method': '支付方式',
  'home.table.time': '付款时间',
  'home.table.dissipate': '消费时间',
  'home.table.source': '来源',
  'home.table.arrivalTime': '到店时间',
  'home.table.ordertime': '下单时间',
  'home.table.scanning_mode': '扫码方式',
  'home.table.orderconfirmationtime': '确认订单时间',
  'home.table.confirm.admin': '确认管理员',
  'home.table.redpack': '红包',
  'home.table.delivery': '配送费',
  'home.table.packingCharge': '包装费',
  'home.table.service': '服务费',
  'home.table.goods': '商品',
  'home.table.status': '支付状态',
  'home.table.way': '取货方式',
  'home.table.address': '客户地址',
  'home.table.useStatus': '使用状态',
  'home.table.operation': '操作',
  'home.table.isShowOrder': '显示用户最近购买记录',
  'home.table.isShowOrder_tips': '店铺前台用户使用',
  'home.table.status.name_y': '已付款',
  'home.table.status.name_n': '未付款',

  'home.table.order_refund.information': '退款信息',
  'home.table.order_refund.name': '提现人名称',
  'home.table.order_refund.bank': '银行名称',
  'home.table.order_refund.account': '银行账号',
  'home.table.order_refund.abn': 'ABN',
  'home.table.order_refund.bsb': 'BSB',
  'home.table.order_refund.price': '提现总额',
  'home.table.order_refund.message': '提现留言',
  'home.table.order_refund.type': '提现类型',
  'home.table.order_refund.img': '票据图片',

  'home.table.way.name_d': '到店',
  'home.table.way.name_s': '配送',
  'home.table.way.name_w': '外卖',
  'home.table.way.name_t': '店内消费',
  'home.table.way.name_z': '到店自提',

  'home.table.useStatus.name_y': '已使用',
  'home.table.useStatus.name_n': '未使用',
  'home.table.useStatus.all': '全部',
  'home.table.useStatus.unpaid': '未付款',
  'home.table.useStatus.paid': '已付款',
  'home.table.useStatus.unfinish': '待消费',
  'home.table.useStatus.completed': '已完成',
  'home.table.useStatus.cancelled': '已取消',
  'home.table.reject_the_order': '拒绝该订单',
  'home.table.confirm_reject': '确认拒绝接单？确认请在输入框输入"DENY"',

  'home.table.useStatus.un_pay': '未付款',
  'home.table.useStatus.wait_order_receiving': '待接单',
  'home.table.useStatus.un_order_receiving': '退款中',
  'home.table.useStatus.for_consumption': '待消费',
  'home.table.useStatus.finished': '已完成',
  'home.table.useStatus.canceled': '已过期',
  'home.table.useStatus.disabled': '已失效',
  'home.table.useStatus.rejected': '已拒绝',
  'home.table.useStatus.refunded': '已退款',
  'home.table.useStatus.refunded_detail': '退款详情',
  'home.table.useStatus.refunded_amount': '退款金额',
  'home.table.useStatus.refunded_to': '退款到',
  'home.table.useStatus.refunded_time': '退款时间',
  'home.table.useStatus.bank_card': '银行卡',
  'home.table.useStatus.wallet': '钱包',
  'home.table.useStatus.orderReply': '订单回复',
  'home.table.useStatus.Reply': '回复',
  'home.table.useStatus.isBuy': '已购买',
  'home.table.useStatus.exportOrder': '导出订单',


  'home.table.operation.btn.file': '归档',
  'home.table.operation.btn.delete': '删除',
  'home.table.operation.btn.recover': '恢复',
  'home.table.operation.btn.view': '查看',
  'home.table.operation.btn.refuse': '拒绝',
  'home.table.operation.btn.agree': '接单',
  'home.table.operation.btn.isAgree': '确认接单？',
  'home.table.operation.btn.isOrder': '确认订单',
  'home.table.operation.btn.print': '打印',
  'home.table.operation.btn.printHorder': '确定打印？',
  'home.table.operation.btn.file_horder': '确定归档？',
  'home.table.operation.btn.horder': '确定删除？',
  'home.table.operation.btn.recoverHorder': '确定恢复？',
  'home.table.placehorder': '暂无订单信息',

  'user.table.name': '用户名',
  'user.table.header': '头像',
  'user.table.nickname': '昵称',
  'user.table.phone': '电话',
  'user.table.user': '用户',
  'user.table.avatar': '头像',
  'user.table.growup': '成长值',
  'user.table.money': '账户余额',
  'user.table.share': '分享',

  'order.table.remark': '备注',
  'order.table.type': '订单类别',
  'order.table.tixian': '提现状态',
  'order.table.address': '地址',
  'order.table.tixian.name_y': '已提现',
  'order.table.tixian.name_n': '未提现',

  'orderline.table.price': '($)订单金额',
  'orderline.table.payStatus': '支付状态',
  'orderline.table.useStatus': '使用状态',
  'orderline.table.operation': '操作',

  'withdrawLog.table.name': '提现人名称',
  'withdrawLog.table.money': '提现金额',
  'withdrawLog.table.order': '涉及订单',
  'withdrawLog.table.result': '处理结果',
  'withdrawLog.table.operation': '操作',

  'withdrawLog.table.operation.btn': '查看',
  'withdrawLog.table.result.ycl': '已处理',
  'withdrawLog.table.result.juj': '拒绝',
  'withdrawLog.table.result.check': '审核中',

  'cashwithdrawal.form.money': '可提现总额',
  'cashwithdrawal.form.bank': '银行名称',
  'cashwithdrawal.form.bank.placehorder': '请输入银行名称',
  'cashwithdrawal.form.account': '账号名',
  'cashwithdrawal.form.account.placehorder': '请输入用户名',
  'cashwithdrawal.form.bsb': 'bsb',
  'cashwithdrawal.form.num': '银行帐号',
  'cashwithdrawal.form.num.placehorder': '请输入银行账户',
  'cashwithdrawal.form.submit': '提交',
  'cashwithdrawal.form.bsb.placehorder': '请输入bsb',
  'cashwithdrawal.form.abn.placehorder': '请输入abn',

  'cashwithdrawal.form.ok': '同意',
  'cashwithdrawal.form.no': '拒绝',

  'cashwithdrawal.form.orderNo': '提现包含的订单编号',
  'cashwithdrawal.form.handle': '处理留言',
  'cashwithdrawal.form.img': '票据图片',

  'seat.table.name': '桌号名称',
  'seat.table.min': '最少人数',
  'seat.table.max': '最多人数',
  'seat.table.tablenumber': '桌子数量',
  'seat.table.describe': '描述',
  'seat.table.number': '桌号',
  'seat.table.open_seat': '开启桌号',
  'seat.table.operation': '操作',

  'seat.table.btn.return': '返回',
  'seat.table.btn.cancel': '取消',
  'seat.table.btn.save': '保存',

  'seat.table.btn.cancel.placeHorder': '确定取消?',
  'seat.table.btn.edit': '编辑',
  'seat.table.please': '请输入',

  'seat.table.name.pleacehorder': '请输入桌号名称',
  'seat.table.num.pleacehorder': '请输入人数',
  'seat.table.table.pleacehorder': '请输入桌子数量',

  'announcementList.table.title': '标题',
  'announcementList.table.time': '时间',
  'announcementList.table.sort': '排序',
  'announcementList.table.error': '请输入标题',

  'admin.table.name': '管理员名称',
  'admin.table.name_tips': '管理员的名称不能与商家用户名一致',
  'admin.table.no': '管理员编号',
  'admin.table.new': '新建',
  'admin.table.newA': '新建管理员',
  'admin.table.aname': '管理员姓名：',
  'admin.table.passward': '密码：',
  'admin.table.passward_error': '请保持密码长度为6-16位',
  'setup.form.user': '用户名',
  'setup.form.licence': '授权码',
  'setup.form.no_licence': '暂未授权',
  'setup.form.Choose': '选择',
  'setup.form.apply': '申请',
  'setup.form.title': '伙伴名称',
  'setup.form.shop': '店铺',
  'setup.form.phone': '电话',
  'setup.form.otherPhone': '备用电话',
  'setup.form.email': '邮箱',
  'setup.form.email_enter': '请输入邮箱',
  'setup.form.code_enter': '请输入验证码',
  'setup.form.orderemail': '订单邮箱',
  'setup.form.orderemail_tips': '多个邮箱使用英文状态下的",”分隔',
  'setup.form.email.placehorder': 'The input is not valid E-mail!',
  'setup.form.abstract': '店铺简介',
  'setup.form.ShowAddress': '显示地址',
  'setup.form.domain': '店铺域名',
  'setup.form.time': '开店时间',
  'setup.form.info': '综合信息',
  'setup.form.license': '营业执照',
  'setup.form.licensefile': '食品许可证',
  'setup.form.wechatfile': '微信二维码',
  'setup.form.logo': '店铺logo',
  'setup.form.ercode': '小程序码',
  'setup.form.img': '店铺海报',
  'setup.form.recruitmentImg': '店招图片',
  'setup.form.recruitmentVideo': '店招视频',
  'setup.form.recruitmentVideo.tips': '视频最大不超过5M,横向尺寸16:9',
  'setup.form.isclose': '是否开启店铺',
  'setup.form.prepareTime': '备货时间',
  'setup.form.isOpenPrepareTime': '是否开启备货时间',
  'setup.form.openDelivery': '开启配送',
  'setup.form.delivery': '配送',
  'setup.form.openFreight': '开启运费',
  'setup.form.full_delivery_free': '开启满包邮',
  'setup.form.is_logistics': '开启商家自行配送',
  'setup.form.stop_order_time': '停止接单时间',
  'setup.form.order_time': '接单时间',
  'setup.form.order_expire_header': '订单过期天数',
  'setup.form.key_header': '文本变量',
  'setup.form.Consumption_variable': '消费变量',
  'setup.form.Self_extracting_variable': '自提变量',
  'setup.form.Table_variable': '桌号变量',
  'setup.form.order_expire': '商品订单有效期',
  'setup.form.activity_order_expire': '活动订单有效期',
  'setup.form.many_help_order_expire': '砍一刀订单有效期',
  'setup.form.order_expire_tip': '过期天数最小15天最大180天',
  'setup.form.activity_order_expire_tip': '过期天数最小1天最大90天',
  'setup.form.isAutoPrint': '是否自动打印小票',
  'setup.form.tostore': '是否到店',
  'setup.form.isDistribution': '是否配送',
  'setup.form.isAlertAnnouncement': '是否弹出公告',
  'setup.form.isAlertMenuImage': '是否开启招牌',
  'setup.form.money': '提现限额',
  'setup.form.way': '付款方式',
  'setup.form.way.all': '不限付款方式',
  'setup.form.way.outline': '线下付款',
  'setup.form.way.inline': '在线付款',
  'setup.form.logo.placehorder': '请上传logo',
  'setup.form.info.placehorder': '请将必填信息填写完成',
  'setup.form.streets': 'Street',
  'setup.form.suburbs': 'Suburb',
  'setup.form.abn': 'ABN',
  'setup.form.company': '公司名称',
  'setup.form.region': '国家',
  'setup.form.salesArea': '销售大区',
  'setup.form.salesArea_small': '销售区域',
  'setup.form.state': '州',
  'setup.form.Merchant_service_fee': '商家活动订单服务费',
  'setup.form.Merchant_general_service_fee': '商家商品订单服务费',


  'newtag.form.name': 'tag名称',
  'newtag.form.name.horder': '请输入属性名称',
  'newtag.form.id': '绑定的商品ID',
  'newtag.form.id.horder': '请输入商品ID',
  'newtag.form.sort': '排序',

  'ad.form.title': '广告标题',
  'ad.form.isShowTitle': '是否显示标题',
  'ad.form.content': '公告内容',
  'ad.form.isShowContent': '是否显示内容',
  'ad.form.title.placehorder': '请输入广告标题',
  'ad.form.content.placehorder': '请输入广告内容',
  'ad.form.seq': '排序',
  'ad.form.img': '广告图片',
  'ad.form.type': '广告类型',
  'ad.form.style': '导航按钮颜色',
  'ad.form.selectgoods': '选择商品',
  'ad.form.selecttype': '选择分类',
  'ad.form.goods': '商品',
  'ad.form.cate': '分类',
  'ad.table.link': '广告ID',

  'menu.form.logo': '店铺logo',
  'menu.form.id': '商品ID',
  'menu.form.seq': '排序',
  'menu.form.img': '菜单图片',

  'red.table.id': '用户id',
  'red.table.no': '票号',
  'red.table.money': '领取金额',
  'red.table.time': '领取时间',

  'red.table.redname': '红包名称',
  'red.table.activeMoney': '活动金额',
  'red.table.activenum': '活动票数',
  'red.table.activeTotal': '总额',
  'red.table.status': '状态',
  'red.table.status.dis': '禁用',
  'red.table.status.en': '不禁用',
  'red.table.log': '领取记录',
  'red.table.activebalance': '活动余额',
  'red.table.currentTotal': '当次活动总额',
  'red.table.currentTotalNum': '当次活动总票数',
  'red.table.balanceNum': '剩余票数',

  'discount.form.discount': '折扣',
  'discount.form.discount.pleasehorder': '请输入折扣数字',
  'discount.form.way': '选择折扣方式',
  'discount.form.way.placehorder': '请先选择折扣方式',
  'discount.form.free': '包邮限额',
  'discount.form.othermoney': '是否收取打包费',

  'pack.form.name': '打包费名称',
  'pack.form.name.pleasehorder': '请输入打包费名称',
  'pack.form.seq': '排序',
  'pack.form.price': '价格',
  'pack.form.opt': '操作',

  'suburbs.table.suburbs': '区域',
  'suburbs.table.price': '价格',

  'goods.list.name': '名称',
  'goods.list.name.horder': '请输入商品名称',
  'goods.list.name.horder.max': '字数超出最大限额',
  'goods.list.price': '商品价格',
  'goods.list.rrp_price': 'RRP 价格',
  'goods.list.rrp_price_tips': '建议零售价',
  'goods.list.price.error': '请保持商品价格与商品sku默认价格一致',
  'goods.list.price.horder': '请输入商品价格',
  'goods.list.type': '商品分类',
  'goods.list.classification': '请选择商品分类',
  'goods.list.sort': '排序',
  'goods.list.unit_number': '自定义编号',
  'goods.list.sale_quantity': '已销售数量',
  'goods.list.attribute': '属性',
  'goods.list.isOk': '上架',
  'goods.list.isShow': '显示',
  'goods.list.disableLanguage': '激活语言',
  'goods.list.is_gst': '含gst',
  'goods.list.img': '主图',
  'goods.list.img.horder': '请选择图片',
  'goods.list.selected': '是否精选',
  'goods.list.selected.maxselect': '* 最多显示前5个精选',
  'goods.list.isspeci': '是否需要规格',
  'goods.list.abstract': '商品简介',
  'goods.list.info': '商品详情',
  'goods.list.speciname': '商品规格名称',
  'goods.list.speciname_tips': '调整规格值，sku价格将会被重置',

  'goods.list.sku': 'sku价格',
  'goods.list.spei': '选择规格',
  'goods.list.good': '商品sku',

  'goods.list.addtype': '添加商品类型',
  'goods.list.addtype.horder': '请输入商品类型名称',

  'goods.list.goodsAnnexType': '附加商品',
  'goods.list.goodsAnnexType.name': '附加商品组名称',
  'goods.list.goodsAnnexType.required': '是否必选',
  'goods.list.goodsAnnexType.check': '是否多选',
  'goods.list.goodsAnnexType.max_select': '最多选择条数',
  'goods.list.goodsAnnexType.min_select': '最少选择条数',
  'goods.list.goodsAnnexType.min_select_error': '最小条数不能大于最大条数',
  'goods.list.goodsAnnexType.max_select_error': '最多条数不能大于所选附加商品数量',
  'goods.list.goodsAnnexType.max_count': '最大可选数量',
  'goods.list.goodsAnnexType.set_default': '设置默认',
  'goods.list.goodsAnnexType.must_default': '强制默认',
  'goods.list.goodsAnnexType.must_default.error': '请打开必选开关',
  'goods.list.goodsAnnexType.set_default.error': '请打开多选开关',
  'goods.list.goodsAnnexType.set_default_num.error': '默认条数不能超过最多选择条数',
  'goods.list.goodsAnnexType.must_default_num.error': '强制默认条数不能超过最少选择条数',
  'goods.list.goodsAnnexType.goodsAnnex_price.error': '请输入最多两位小数的金额',

  'category.list.name': '分类名称',
  'category.list.img': '分类图片',
  'category.list.Icon': '图标',
  'category.list.name.horder': '请输入分类名称',
  'category.list.sort': '排序',
  'category.list.del': '删除',
  'category.list.isShow': '是否显示',
  'category.list.isAlert': '是否弹出',
  'category.list.del.horder': '确定删除?如果该分类下有商品，商品会失效！',

  'gooditem.table.name': '商品规格项名称',
  'gooditem.table.name.horder': '请输入商品规格名称',
  'gooditem.table.id': '商品规格项id',
  'goodsArr.form.name': '商品属性名称',
  'goodsArr.form.name.plachordr': '请输入属性名称',
  'goodsArr.form.type': '请选择商品类型',

  'bindpay.removeBtn': '移除绑定',
  'bindpay.card': '银行卡信息',//'Card details'
  'bindpay.bindBtn': '绑定支付',

  'package.name': '套餐名称',
  'package.reason': '原因',
  'package.record': '备案时间',
  'package.implement': '执行时间',
  'package.created': '创建时间',
  'package.rate': '商品订单服务费',
  'package.fee': '定额订单服务费',
  'package.set': '初始费',
  'package.fixed_fee': '定额服务费',
  'package.ActivityServiceFee': '活动订单服务费',
  'package.service.year': '技术服务年费',
  'package.service.year_service_fee_by_order_num': '技术服务年费减免要求订单数量',
  'package.service.month_service_fee_by_order_num': '月达标单数',
  'package.service.money_standard_activity_order_num': '月活动达标单数',
  'package.service.contract_date': '签约期（月）',
  'package.service.contract_term_standard_order_num': '签约期达标单数',
  'package.service.contract_term_standard_activity_order_num': '签约期活动达标单数',
  'package.service.day_of_fee_reduction': '体验期商品订单服务费',
  'package.service.prestore_money': '预存金额',
  'package.service.goods_count': '商品数量',
  'package.service.coupon_count': '优惠券ticket数量',
  'package.service.fee_reduction_fixed_fee': '体验期订单固定服务费',
  'package.service.fee_reduction_activity_service_rate': '体验期活动订单服务费',
  'package.is_own_payment': '支持独立结算',
  'package.is_langs': '支持商家多语言',
  'package.is_have_h5': '支持H5移动端浏览器',
  'package.is_have_domain': '支持独立域名',
  'package.is_have_seat_qrcode': '支持立牌',
  'package.month.fix': '月固定费',
  'package.month': '月费',
  'package.days': '体验期(月)',
  'package.option': '操作',
  'package.open': '开通套餐',
  'package.subscribe': '订阅套餐',
  'package.hasOpen': '已开通',
  'package.toOpen': '待开通',
  'package.service.status': '服务费状态',
  'package.month.status': '月费状态',
  'package.sub.service': '服务费订阅',
  'package.sub.month': '月费订阅',
  'package.system.recommendation': ' Eatmoon 系统，推荐',
  'package.slogan': '不要犹豫，Eatmoon 就是最好的选择。',
  'package.join.eatmoon': '加入 Eatmoon 彻底改变您的营销方式，免费试用，请随时与系统联系。',

  'plugin.name': '插件名称',
  'plugin.client': '适用客户端',
  'plugin.route': '识别路由',
  'plugin.price': '每月价格',
  'plugin.sort': '排序',
  'plugin.sub': '下单',
  'plugin.hasSub': '已订阅',
  'plugin.toSub': '待订阅',
  'plugin.status': '状态',

  // 'login.sitname':'商家登录',
  // 'login.success':'登录成功',
  // 'login.manaNo':'管理员编号',
  // 'login.btn.login':'登录',
  // 'login.userlogin':'店铺管理员登录',
  // 'login.passlogin':'账户密码登录',

  'summit.success': '提交成功',
  'delete.success': '删除成功',
  'file.success': '归档成功',
  'update.success': '修改成功',
  'recover.success': '恢复成功',
  'common.table.id': 'id',

  'file.type.placehorder': 'You can only upload JPG/PNG file!',
  'file.size.placehorder': 'Image must smaller than 1MB!',
  'file.btn.name': 'Upload',

  'system.title': '伙伴管理后台',
  'system.loginout': '退出登录',
  'system.is_audit': '未审核',

  'announcement.placeholder': '排序值请不要设置过大',
  'lang.list.name': '语言名称',
  'lang.list.symbol': '语言缩写',
  'lang.list.isUse': '是否启用',
  'lang.list.isDefault': '是否默认',
  'lang.list.isH5Default': '是否h5默认',
  'lang.list.isManageDefault': '是否后台默认',
  'lang.list.H5Default': 'h5默认',
  'lang.list.ManageDefault': '后台默认',
  'lang.list.option': '操作',
  'lang.list.change': '默认语言',
  'lang.list.yes': '是',
  'lang.list.no': '否',
  'lang.list.use': '启用语言',
  'lang.list.notUse': '暂不启用',

  'subscription.price': '价格($)',
  'subscription.status': '状态',
  'subscription.type': '类型',
  'subscription.serviceType': '服务类型',
  'subscription.startTime': '服务开始时间',
  'subscription.endTime': '服务结束时间',
  'subscription.expire': '订单是否过期',
  'subscription.option': '操作',
  'subscription.status.zero': '已过期或终止',
  'subscription.status.one': '服务中',
  'subscription.serviceType.s': '开通服务',
  'subscription.serviceType.y': '年服务费',
  'subscription.serviceType.m': '月达标费',
  'subscription.see': '查看',
  'subscription.expire.one': '过期',
  'subscription.expire.zero': '未过期',
  'subscription.info.package': '产品套餐',
  'subscription.info.addon': '产品插件',
  'subscription.info.base': '基本信息',
  'subscription.crateTime': '创建时间',
  'subscription.payBtn': '续费',

  'orders.price': '价格',
  'orders.count': '数量',
  'orders.real_price': '实际支付',
  'orders.use_money': '使用余额',
  'orders.status': '订单状态',
  'orders.pay_type': '支付方式',
  'orders.is_trial': '是否试用订单',
  'orders.is_expire': '订单是否过期',
  'orders.status.zero': '未付款',
  'orders.status.one': '已付款',
  'orders.status.two': '退款',
  'orders.status.three': '终止',
  'orders.status.four': '完成',
  'orders.is_trial.zero': '正式',
  'orders.is_trial.one': '试用',
  'orders.payTime': '支付时间',
  'orders.subsrc': '订阅信息',
  'modal.title': '选择使用方式',
  'modal.title.selectNo': '试用',
  'modal.title.selectYes': '正式',

  'data.order': '订单统计',
  'data.order.total': '订单总数',
  'data.order.year': '年订单数',
  'data.order.quarter': '季度订单数',
  'data.order.month': '月订单数',
  'data.order.today': '今日订单数',

  'data.coupon': '优惠券统计',
  'data.coupon.total': '优惠券总数',
  'data.coupon.year': '年优惠券数',
  'data.coupon.quarter': '季度优惠券数',
  'data.coupon.month': '月优惠券数',
  'data.coupon.today': '今日优惠券数',

  'data.deal': '促销统计',
  'data.deal.total': '促销总数',
  'data.deal.year': '年促销数',
  'data.deal.quarter': '季度促销数',
  'data.deal.month': '月促销数',
  'data.deal.today': '今日促销数',

  'data.spu': '商品统计',
  'data.spu.total': '商品总数',
  'data.spu.year': '年商品数',
  'data.spu.quarter': '季度商品数',
  'data.spu.month': '月商品数',
  'data.spu.today': '今日商品数',
  'behavior.Dining.mode': "消费方式",
  'behavior.Online.payment.order': "线上付款订单，最低金额",
  'behavior.Order.amount.setting': "订单金额设置",
};

