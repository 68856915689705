import { Axios } from "../util/request"
import { API } from "../util/api";
import { getLangType } from '../util/function';
import intl from 'react-intl-universal';
// 商家登录
export const login = (data) => {
    return Axios.ajax({
        url: API.auth.LOGIN,
        method: "post",
        data
    })
}
// 管理员登录
export const AdminLogin = (data) => {
    return Axios.ajax({
        url: API.auth.partnerAdminLogin,
        method: "post",
        data
    })
}

// 商家注册
export const register = (data) => {
    return Axios.ajax({
        url: API.auth.PARTNER,
        method: "post",
        data
    })
}

// 更新token
export const PutToken = (data) => {
    return Axios.ajax({
        url: API.auth.LOGIN,
        method: "put"
    })
}

//获取商家信息
export const getPartner = () => {
    return Axios.ajax({
        //url: `${API.auth.partnerAddress}&request_language_id=${getLangType()}&include=partnerAddress,region,style,package,partnerFoodLicense,salesArea&appends=site_region`,
        //url: `${API.auth.partnerAddress}`,

        url: `${API.auth.partnerAddress}?request_language_id=${getLangType()}&include=empartnerAddress`,
        

    })
}
//获取用户性质
export const getIsSuper = () => {
    return Axios.ajax({
        url: "isSuper",
    })
}

// 修改商家信息
export const UpdateShopMessage = (data) => {
    return Axios.ajax({
        url: API.auth.PARTNER + API.auth.UPDATE,
        method: "post",
        tips: intl.get("update.success"),
        data
    })
}

// 获取可提现金额
export const GetWalletMoney = () => {
    return Axios.ajax({
        url: `${API.wallet.wallet}${API.wallet.money}?request_language_id=${getLangType()}`,
        method: "get",
    })
}

// 提现
export const WalletWithdraw = (data) => {
    return Axios.ajax({
        url: API.wallet.wallet + API.wallet.withdraw,
        method: "post",
        tips: "提现成功",
        data
    })
}

// 获取提现记录
export const GetWithdrawLog = () => {
    return Axios.ajax({
        url: `${API.withdrawLog.withdrawLog}?request_language_id=${getLangType()}`,
        method: "get",
    })
}

// 获取提现详情
export const GetWithdrawDetail = (id) => {
    return Axios.ajax({
        url: `${API.withdrawLog.withdrawLog}/${id}?request_language_id=${getLangType()}`,
        method: "get",
    })
}

// 新建座位
export const AddSeat = (data) => {
    return Axios.ajax({
        url: API.partnerSeat,
        method: "post",
        tips: intl.get("summit.success"),
        data
    })
}

// 获取座位列表
export const GetSeatList = () => {
    return Axios.ajax({
        url: `${API.partnerSeat}?request_language_id=${getLangType()}`,
        method: "get"
    })
}


// 获取图片
export const GetSeatImage = (url) => {
    return Axios.ajax({
        url: url,
        method: "get"
    })
}
// 修改座位
export const putSeat = (data, id) => {
    return Axios.ajax({
        url: API.partnerSeat + "/" + id,
        method: "put",
        tips: intl.get("update.success"),
        data,
    })
}

// 删除座位
export const deleteSeat = (id) => {
    return Axios.ajax({
        url: API.partnerSeat + "/" + id,
        method: "delete",
        tips: intl.get("delete.success"),
    })
}

// 新建运费
export const AddFreight = (data) => {
    return Axios.ajax({
        url: API.partnerDeliveryFree,
        method: "post",
        tips: intl.get("summit.success"),
        data
    })
}

// 获取运费列表
export const GetFreightList = () => {
    return Axios.ajax({
        url: `${API.partnerDeliveryFree}?request_language_id=${getLangType()}`,
        method: "get"
    })
}

// 修改运费
export const putFreight = (data, id) => {
    return Axios.ajax({
        url: API.partnerDeliveryFree + "/" + id,
        method: "put",
        tips: intl.get("update.success"),
        data,
    })
}

// 删除运费
export const deleteFreight = (id) => {
    return Axios.ajax({
        url: API.partnerDeliveryFree + "/" + id,
        method: "delete",
        tips: intl.get("delete.success"),
    })
}


// 获取用户列表
export const GetUserList = (num, url,partner_id) => {
    return Axios.ajax({
        url: `${API.user}${API.page}${num}${url}&request_language_id=${getLangType()}&partner_id=${partner_id}`,
        method: "get"
    })
}
// 修改用户
export const putUser = (data, id) => {
    return Axios.ajax({
        url: API.user + "/" + id,
        method: "put",
        tips: intl.get("update.success"),
        data,
    })
}

// 查询地址
export const QueryAddress = (key) => {
    return Axios.ajax({
        url: `${API.queryAddress}${key}&request_language_id=${getLangType()}`,
        method: "get"
    })
}
// 皮肤列表
export const StyleList = (key) => {
    return Axios.ajax({
        url: API.style,
        method: "get"
    })
}

// 新建送餐区域
export const AddDeliveryAddress = (data) => {
    return Axios.ajax({
        url: API.partnerDeliveryAddress,
        method: "post",
        tips: intl.get("summit.success"),
        data
    })
}

// 获取送餐区域
export const GetDeliveryAddressList = () => {
    return Axios.ajax({
        url: `${API.partnerDeliveryAddress}?request_language_id=${getLangType()}`,
        method: "get"
    })
}
// 修改送餐区域
export const putDeliveryAddress = (data, id) => {
    return Axios.ajax({
        url: API.partnerDeliveryAddress + "/" + id,
        method: "put",
        tips: intl.get("update.success"),
        data,
    })
}

// 删除送餐区域
export const deleteDeliveryAddress = (id) => {
    return Axios.ajax({
        url: API.partnerDeliveryAddress + "/" + id,
        method: "delete",
        tips: intl.get("delete.success"),
    })
}



// 获取红包列表
export const GetRedPackageList = () => {
    return Axios.ajax({
        url: `${API.redPackageIndex}?request_language_id=${getLangType()}`,
        method: "get"
    })
}

// 获取活动详情
export const GetPrizeActivityList = (id) => {
    return Axios.ajax({
        url: `${API.prizeActivity}${id}&request_language_id=${getLangType()}`,
        method: "get"
    })
}



// 新建管理员
export const AddAdmin = (data) => {
    return Axios.ajax({
        url: API.partnerAdmin,
        method: "post",
        tips: intl.get("summit.success"),
        data
    })
}

// 获取管理员列表
export const GetAdminList = () => {
    return Axios.ajax({
        url: `${API.partnerAdmin}?request_language_id=${getLangType()}`,
        method: "get"
    })
}
// 通过编号获取管理员详情
export const GetAdminUniqueDetail = (id) => {
    return Axios.ajax({
        url: `${API.partnerAdminUniqueShow}${id}&request_language_id=${getLangType()}`,
        method: "get"
    })
}
// 获取管理员详情
export const GetAdminDetail = (id) => {
    return Axios.ajax({
        url: `${API.partnerAdmin}/${id}&request_language_id=${getLangType()}`,
        method: "get"
    })
}
// 修改管理员
export const putAdmin = (data, id) => {
    return Axios.ajax({
        url: API.partnerAdmin + "/" + id,
        method: "post",
        tips: intl.get("update.success"),
        data,
    })
}

// 删除管理员
export const deleteAdmin = (id) => {
    return Axios.ajax({
        url: API.partnerAdmin + "/" + id,
        method: "delete",
        tips: intl.get("delete.success"),
    })
}

//语言列表
export const getLangList = () => {
    return Axios.ajax({
        url: API.partnerLangue,
        method: "get"
    })
}

export const editLang = (data) => {
    return Axios.ajax({
        url: "defaultLanguage",
        method: "post",
        tips: intl.get("update.success"),
        data
    })
}


export const getDefaultLang = () => {
    return Axios.ajax({
        url: "defaultLanguage",
        method: "get"
    })
}


export const editUse = (id, data) => {
    return Axios.ajax({
        url: "empartnerLangue/" + id,
        method: "put",
        tips: intl.get("update.success"),
        data,
    })
}

export const QuerySubscrption = () => {
    return Axios.ajax({
        url: "partnerSubscription?include=partner,partnerOrder,prod,package,addon",
        method: "get"
    })
}

// export const submitPayAgain=(id)=>{
//     return Axios.ajax({
//         url:"partnerOrder?include=partner,partnerOrder,prod,package,addon&partner_subscription_id="+id,
//         method:"get"
//     })
// }
export const submitPayAgain = (id, is_trial) => {
    return Axios.ajax({
        url: "partnerOrder",
        method: "post",
        tips: "操作成功",
        data: {
            include: 'partner,partnerOrder,prod,package,addon',
            partner_subscription_id: id,
            is_trial
        },
    })
}

export const mySubscrDetail = (id) => {
    return Axios.ajax({
        url: `partnerSubscription/${id}`,
        method: "get"
    })
}

export const QueryOrders = () => {
    return Axios.ajax({
        url: "partnerOrder?include=partner,partnerSubscription.prod",
        method: "get"
    })
}

export const myOrderDetail = (id) => {
    return Axios.ajax({
        url: `partnerOrder/${id}`,
        method: "get"
    })
}

export const submitPay = (id) => {
    return Axios.ajax({
        url: `/partnerOrder/${id}/pay`,
        method: "post",
        tips: "操作成功",
        data: {
            include: 'partner,partnerOrder,prod,package,addon',
        },
    })
}


export const QueryStatistics = () => {
    return Axios.ajax({
        url: "data",
        method: "get"
    })
}


export const QueryCount = () => {
    return Axios.ajax({
        url: "empartnercount",
        method: "get"
    })
}





// 设置营业时间
export const setBusinesshours = (data) => {
    return Axios.ajax({
        url: API.businessHours,
        method: "post",
        tips: intl.get("summit.success"),
        data
    })
}
// 公共假期列表
export const getHolidayList = (url) => {
    return Axios.ajax({
        url: API.holiday + url,
        method: "get",
    })
}


// 公共假期详情
export const getHolidayDetail = (id) => {
    return Axios.ajax({
        url: API.holiday + "/" + id,
        method: "get",
    })
}

// 新建公共假期
export const AddHoliday = (data) => {
    return Axios.ajax({
        url: API.holiday,
        method: "post",
        tips: intl.get("summit.success"),
        data
    })
}

// 编辑公共假期
export const putHoliday = (data, id) => {
    return Axios.ajax({
        url: API.holiday + "/" + id,
        method: "put",
        tips: intl.get("update.success"),
        data,
    })
}

// 删除公共假期
export const deleteHoliday = (id) => {
    return Axios.ajax({
        url: API.holiday + "/" + id,
        method: "delete",
        tips: intl.get("delete.success"),
    })
}
// 假期营业列表
export const getHolidayBusinessList = () => {
    return Axios.ajax({
        url: API.holidayBusiness,
        method: "get",
    })
}


// 假期营业详情
export const getHolidayBusinessDetail = (id) => {
    return Axios.ajax({
        url: API.holidayBusiness + "/" + id,
        method: "get",
    })
}

// 新建假期营业
export const AddHolidayBusiness = (data) => {
    return Axios.ajax({
        url: API.holidayBusiness,
        method: "post",
        tips: intl.get("summit.success"),
        data
    })
}

// 编辑假期营业
export const putHolidayBusiness = (data, id) => {
    return Axios.ajax({
        url: API.holidayBusiness + "/" + id,
        method: "put",
        tips: intl.get("update.success"),
        data,
    })
}

// 删除假期营业
export const deleteHolidayBusiness = (id) => {
    return Axios.ajax({
        url: API.holidayBusiness + "/" + id,
        method: "delete",
        tips: intl.get("delete.success"),
    })
}

//获取用户详情
export const getUserDetail = (id) => {
    return Axios.ajax({
        url: `/user/${id}?include=partner`,
        method: "get",
    })
}


export const GetOrderListE = (page, id) => {
    return Axios.ajax({
        url: `/order?include=user,orderSpu&user_id=${id}&page=${page}`,
        method: "get"
    })
}


// 获取系统设置
export const GetConfigSet = () => {
    return Axios.ajax({
        url: API.config,
        method: "get",
    })
}
// 获取当前站点国家
export const GetSiteCountry = () => {
    return Axios.ajax({
        url: API.getConfig,
        method: "get",
    })
}


// 获取费用流水
export const GetPartnerMonthlyFeet = (page, url, date) => {
    return Axios.ajax({
        url: API.partnerPackageFeeLog + "?page=" + page + url + "&created_at=" + date,
        method: "get",
    })
}


//获取语言列表
export const getLanguageList = () => {
    return Axios.ajax({
        url: API.language,
        method: "get"
    })
}



// 获取申请套餐记录列表
export const getApplyPackageList = (partner_id) => {
    return Axios.ajax({
        url: API.partnerApplyPackage + "?partner_id=" + partner_id,
        method: "get"
    })
}
//修改申请套餐
export const UpdateApplyPackage = (id, data) => {
    return Axios.ajax({
        url: API.partnerApplyPackage + "/" + id,
        method: "put",
        tips: "修改成功",
        data
    })
}



