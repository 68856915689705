import React from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Form, Button, Modal, Radio, Icon } from 'antd';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.less"
import { getPackageList, ApplyPackage } from "../../action/goodAction";
import { getPartner } from "../../action/authAction";
import intl, { init } from 'react-intl-universal';

class PackageList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: "",
            package_id: "",
            is_coupon: "",
            initialSlide: ""
        };

    }

    async componentDidMount() {
        let res = await getPackageList()
        let partnerData = await getPartner()
        let data = res.data.data
        let package_id = partnerData.data.package_id
        let is_coupon = partnerData.data.is_coupon
        let initialSlide = 0
        for (let i in data) {
            if (data[i].id === package_id) {
                initialSlide = i - 1
            }
        }
        this.setState({ data, package_id, is_coupon, initialSlide })
    }
    HandleApplyPackage = (id) => {
        let data = { "package_id": id }
        ApplyPackage(data).then(res => {
            console.log(res)
        })
    }
    render() {
        let { data, package_id, is_coupon, initialSlide } = this.state
        if (data) {
            let settings = {
                infinite: true,
                speed: 500,
                slidesToShow: 4,
                slidesToScroll: 1,
                arrows: true,
                initialSlide: initialSlide
            };
            return (
                <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                    <div style={{ maxWidth: "1200px", height: "800px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <span style={{ fontSize: 14, fontWeight: 700, color: "#656e77", marginBottom: "12px" }}>{intl.get("package.system.recommendation")}</span>
                            <span style={{ fontSize: 40, fontWeight: 700, color: "#000" }}>{intl.get("package.slogan")}</span>
                            <span style={{ fontSize: 16, color: "#656e77", marginTop: "16px", marginBottom: "32px" }}>{intl.get("package.join.eatmoon")}</span>
                            <Button
                                type="primary"
                                onClick={() => this.props.history.push("/packageLog")}
                            >
                                {intl.get('nav.package.packageLog.list')}
                            </Button>
                        </div>
                        <div
                            style={{
                                maxWidth: "1200px",
                                minWidth: "1000px",
                                marginTop: "40px"
                            }}
                        >
                            {
                                data && data.length > 4 ?
                                    <Slider {...settings}>
                                        {
                                            data && data.map((item, index) => {
                                                return <div key={index}>
                                                    <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: 'center', }}>
                                                        <div style={{ width: "95%", height: "680px", borderRadius: "10px", padding: "20px 20px 0", border: "1px solid", borderColor: package_id === item.id ? "#5867dd" : "#e5e5e5", backgroundColor: "#fff" }}>
                                                            <p style={{ color: "#192435", fontSize: "24px", fontWeight: 700, marginBottom: "20px" }}>{item.name}</p>
                                                            <p style={{ color: "#656e77", fontSize: "14px", marginBottom: "36px", height: "42px", overflow: "hidden" }}>{item.remark}</p>
                                                            <div style={{ marginBottom: "34px", paddingBottom: "24px", borderBottom: "1px solid #e5e5e5" }}>
                                                                <p style={{ fontSize: "24px", color: "#192435", fontWeight: 700, margin: "0px" }}>{intl.get('package.set')}: ${Number(item.setup_fee)}</p>
                                                            </div>
                                                            <div>
                                                                <div style={{ marginBottom: "22px", height: "110px" }}>
                                                                    {
                                                                        item.is_langs === 1 &&
                                                                        <div className='slick-list-item-content-item'>
                                                                            <Icon type='check-circle' style={{ fontSize: "20px", color: "#192435" }} />
                                                                            <p>{intl.get('package.is_langs')}</p>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        String(item.order_fixed_fee).length > 0 &&
                                                                        <div className='slick-list-item-content-item'>
                                                                            <Icon type='check-circle' style={{ fontSize: "20px", color: "#192435" }} />
                                                                            <p>{intl.get('package.fee')}: ${item.order_fixed_fee}</p>
                                                                        </div>
                                                                    }

                                                                    {
                                                                        String(item.rate).length > 0 &&
                                                                        <div className='slick-list-item-content-item'>
                                                                            <Icon type='check-circle' style={{ fontSize: "20px", color: "#192435" }} />
                                                                            <p>{intl.get('package.rate')}: {item.rate}%</p>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        String(item.activity_service_rate).length > 0 &&
                                                                        <div className='slick-list-item-content-item'>
                                                                            <Icon type='check-circle' style={{ fontSize: "20px", color: "#192435" }} />
                                                                            <p>{intl.get('package.ActivityServiceFee')}: {item.activity_service_rate}%</p>
                                                                        </div>
                                                                    }
                                                                </div>

                                                                <div style={{ display: "flex", justifyContent: "center" }}>
                                                                    {
                                                                        package_id === item.id ?
                                                                            <Button
                                                                                disabled
                                                                                className='slick-list-item-content_button'
                                                                            >
                                                                                {intl.get("nav.package.currentPackage")}
                                                                            </Button> :
                                                                            <Button
                                                                                className='slick-list-item-content_button'
                                                                                onClick={() => this.HandleApplyPackage(item.id)}
                                                                            >
                                                                                {intl.get('setup.form.Choose')}
                                                                            </Button>
                                                                    }
                                                                </div>
                                                                <hr style={{ backgroundColor: "#e5e5e5", height: "1px", borderWidth: 0, marginBottom: "24px" }} />

                                                                {
                                                                    String(item.year_service_fee).length > 0 &&
                                                                    <div className='slick-list-item-content-item'>
                                                                        <Icon type='check-circle' style={{ fontSize: "20px", color: "#192435" }} />
                                                                        <p>{intl.get("package.service.year")}: ${item.year_service_fee}</p>
                                                                    </div>
                                                                }

                                                                {
                                                                    String(item.month_service_fee).length > 0 &&
                                                                    <div className='slick-list-item-content-item'>
                                                                        <Icon type='check-circle' style={{ fontSize: "20px", color: "#192435" }} />
                                                                        <p>{intl.get("subscription.serviceType.m")}: ${item.month_service_fee}</p>
                                                                    </div>
                                                                }

                                                                {
                                                                    String(item.month_service_fee_by_order_num).length > 0 &&
                                                                    <div className='slick-list-item-content-item'>
                                                                        <Icon type='check-circle' style={{ fontSize: "20px", color: "#192435" }} />
                                                                        <p>{intl.get("package.service.month_service_fee_by_order_num")}: {item.month_service_fee_by_order_num}</p>
                                                                    </div>
                                                                }
                                                                {
                                                                    String(item.money_standard_activity_order_num).length > 0 &&
                                                                    <div className='slick-list-item-content-item'>
                                                                        <Icon type='check-circle' style={{ fontSize: "20px", color: "#192435" }} />
                                                                        <p>{intl.get("package.service.money_standard_activity_order_num")}: {item.money_standard_activity_order_num}</p>
                                                                    </div>
                                                                }
                                                                {
                                                                    String(item.contract_term_standard_order_num).length > 0 &&
                                                                    <div className='slick-list-item-content-item'>
                                                                        <Icon type='check-circle' style={{ fontSize: "20px", color: "#192435" }} />
                                                                        <p>{intl.get("package.service.contract_term_standard_order_num")}: {item.contract_term_standard_order_num}</p>
                                                                    </div>
                                                                }
                                                                {
                                                                    String(item.contract_term_standard_activity_order_num).length > 0 &&
                                                                    <div className='slick-list-item-content-item'>
                                                                        <Icon type='check-circle' style={{ fontSize: "20px", color: "#192435" }} />
                                                                        <p>{intl.get("package.service.contract_term_standard_activity_order_num")}: {item.contract_term_standard_activity_order_num}</p>
                                                                    </div>
                                                                }
                                                                {
                                                                    String(item.contract_date).length > 0 &&
                                                                    <div className='slick-list-item-content-item'>
                                                                        <Icon type='check-circle' style={{ fontSize: "20px", color: "#192435" }} />
                                                                        <p>{intl.get("package.service.contract_date")}: {item.contract_date}</p>
                                                                    </div>
                                                                }


                                                                {
                                                                    String(item.trial_period_days).length > 0 &&
                                                                    <div className='slick-list-item-content-item'>
                                                                        <Icon type='check-circle' style={{ fontSize: "20px", color: "#192435" }} />
                                                                        <p>{intl.get("package.days")}: {item.trial_period_days}</p>
                                                                    </div>
                                                                }
                                                                {
                                                                    String(item.fee_reduction_fixed_fee).length > 0 &&
                                                                    <div className='slick-list-item-content-item'>
                                                                        <Icon type='check-circle' style={{ fontSize: "20px", color: "#192435" }} />
                                                                        <p>{intl.get("package.service.fee_reduction_fixed_fee")}: ${item.fee_reduction_fixed_fee}</p>
                                                                    </div>
                                                                }
                                                                {
                                                                    String(item.fee_reduction_activity_service_rate).length > 0 &&
                                                                    <div className='slick-list-item-content-item'>
                                                                        <Icon type='check-circle' style={{ fontSize: "20px", color: "#192435" }} />
                                                                        <p>{intl.get("package.service.fee_reduction_activity_service_rate")}: {item.fee_reduction_activity_service_rate}%</p>
                                                                    </div>
                                                                }

                                                                {
                                                                    String(item.fee_reduction_rate).length > 0 &&
                                                                    <div className='slick-list-item-content-item'>
                                                                        <Icon type='check-circle' style={{ fontSize: "20px", color: "#192435" }} />
                                                                        <p>{intl.get("package.service.day_of_fee_reduction")}: {item.fee_reduction_rate}%</p>
                                                                    </div>
                                                                }
                                                                {
                                                                    String(item.prestore_money).length > 0 &&
                                                                    <div className='slick-list-item-content-item'>
                                                                        <Icon type='check-circle' style={{ fontSize: "20px", color: "#192435" }} />
                                                                        <p>{intl.get("package.service.prestore_money")}: ${item.prestore_money}</p>
                                                                    </div>
                                                                }
                                                                {
                                                                    String(item.spu_quantity).length > 0 &&
                                                                    <div className='slick-list-item-content-item'>
                                                                        <Icon type='check-circle' style={{ fontSize: "20px", color: "#192435" }} />
                                                                        <p>{intl.get("package.service.goods_count")}: {item.spu_quantity}</p>
                                                                    </div>
                                                                }
                                                                {
                                                                    item.is_own_payment === 1 &&
                                                                    <div className='slick-list-item-content-item'>
                                                                        <Icon type='check-circle' style={{ fontSize: "20px", color: "#192435" }} />
                                                                        <p>{intl.get("package.is_own_payment")}</p>
                                                                    </div>
                                                                }
                                                                {
                                                                    is_coupon === 1 && String(item.coupon_quantity).length > 0 &&
                                                                    <div className='slick-list-item-content-item'>
                                                                        <Icon type='check-circle' style={{ fontSize: "20px", color: "#192435" }} />
                                                                        <p>{intl.get("package.service.coupon_count")}:{item.coupon_quantity}</p>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }

                                    </Slider> :
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        {
                                            data && data.map((item, index) => {
                                                return <div key={index}>
                                                    <div style={{ width: "300px", height: "100%", display: "flex", justifyContent: 'center', }}>
                                                        <div style={{ width: "95%", height: "680px", borderRadius: "10px", padding: "20px 20px 0", border: "1px solid", borderColor: package_id === item.id ? "#5867dd" : "#e5e5e5", backgroundColor: "#fff" }}>
                                                            <p style={{ color: "#192435", fontSize: "24px", fontWeight: 700, marginBottom: "20px" }}>{item.name}</p>
                                                            <p style={{ color: "#656e77", fontSize: "14px", marginBottom: "36px", height: "42px", overflow: "hidden" }}>{item.remark}</p>
                                                            <div style={{ marginBottom: "34px", paddingBottom: "24px", borderBottom: "1px solid #e5e5e5" }}>
                                                                <p style={{ fontSize: "24px", color: "#192435", fontWeight: 700, margin: "0px" }}>{intl.get('package.set')}: ${Number(item.setup_fee)}</p>
                                                            </div>
                                                            <div>
                                                                <div style={{ marginBottom: "22px", height: "110px" }}>
                                                                    {
                                                                        item.is_langs === 1 &&
                                                                        <div className='slick-list-item-content-item'>
                                                                            <Icon type='check-circle' style={{ fontSize: "20px", color: "#192435" }} />
                                                                            <p>{intl.get('package.is_langs')}</p>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        String(item.order_fixed_fee).length > 0 &&
                                                                        <div className='slick-list-item-content-item'>
                                                                            <Icon type='check-circle' style={{ fontSize: "20px", color: "#192435" }} />
                                                                            <p>{intl.get('package.fee')}: ${item.order_fixed_fee}</p>
                                                                        </div>
                                                                    }

                                                                    {
                                                                        String(item.rate).length > 0 &&
                                                                        <div className='slick-list-item-content-item'>
                                                                            <Icon type='check-circle' style={{ fontSize: "20px", color: "#192435" }} />
                                                                            <p>{intl.get('package.rate')}: {item.rate}%</p>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        String(item.activity_service_rate).length > 0 &&
                                                                        <div className='slick-list-item-content-item'>
                                                                            <Icon type='check-circle' style={{ fontSize: "20px", color: "#192435" }} />
                                                                            <p>{intl.get('package.ActivityServiceFee')}: {item.activity_service_rate}%</p>
                                                                        </div>
                                                                    }
                                                                </div>

                                                                <div style={{ display: "flex", justifyContent: "center" }}>
                                                                    {
                                                                        package_id === item.id ?
                                                                            <Button
                                                                                disabled
                                                                                className='slick-list-item-content_button'
                                                                            >
                                                                                {intl.get("nav.package.currentPackage")}
                                                                            </Button> :
                                                                            <Button
                                                                                className='slick-list-item-content_button'
                                                                                onClick={() => this.HandleApplyPackage(item.id)}
                                                                            >
                                                                                {intl.get('setup.form.Choose')}
                                                                            </Button>
                                                                    }
                                                                </div>
                                                                <hr style={{ backgroundColor: "#e5e5e5", height: "1px", borderWidth: 0, marginBottom: "24px" }} />

                                                                {
                                                                    String(item.year_service_fee).length > 0 &&
                                                                    <div className='slick-list-item-content-item'>
                                                                        <Icon type='check-circle' style={{ fontSize: "20px", color: "#192435" }} />
                                                                        <p>{intl.get("package.service.year")}: ${item.year_service_fee}</p>
                                                                    </div>
                                                                }

                                                                {
                                                                    String(item.month_service_fee).length > 0 &&
                                                                    <div className='slick-list-item-content-item'>
                                                                        <Icon type='check-circle' style={{ fontSize: "20px", color: "#192435" }} />
                                                                        <p>{intl.get("subscription.serviceType.m")}: ${item.month_service_fee}</p>
                                                                    </div>
                                                                }

                                                                {
                                                                    String(item.month_service_fee_by_order_num).length > 0 &&
                                                                    <div className='slick-list-item-content-item'>
                                                                        <Icon type='check-circle' style={{ fontSize: "20px", color: "#192435" }} />
                                                                        <p>{intl.get("package.service.month_service_fee_by_order_num")}: {item.month_service_fee_by_order_num}</p>
                                                                    </div>
                                                                }
                                                                {
                                                                    String(item.money_standard_activity_order_num).length > 0 &&
                                                                    <div className='slick-list-item-content-item'>
                                                                        <Icon type='check-circle' style={{ fontSize: "20px", color: "#192435" }} />
                                                                        <p>{intl.get("package.service.money_standard_activity_order_num")}: {item.money_standard_activity_order_num}</p>
                                                                    </div>
                                                                }
                                                                {
                                                                    String(item.contract_term_standard_order_num).length > 0 &&
                                                                    <div className='slick-list-item-content-item'>
                                                                        <Icon type='check-circle' style={{ fontSize: "20px", color: "#192435" }} />
                                                                        <p>{intl.get("package.service.contract_term_standard_order_num")}: {item.contract_term_standard_order_num}</p>
                                                                    </div>
                                                                }
                                                                {
                                                                    String(item.contract_term_standard_activity_order_num).length > 0 &&
                                                                    <div className='slick-list-item-content-item'>
                                                                        <Icon type='check-circle' style={{ fontSize: "20px", color: "#192435" }} />
                                                                        <p>{intl.get("package.service.contract_term_standard_activity_order_num")}: {item.contract_term_standard_activity_order_num}</p>
                                                                    </div>
                                                                }
                                                                {
                                                                    String(item.contract_date).length > 0 &&
                                                                    <div className='slick-list-item-content-item'>
                                                                        <Icon type='check-circle' style={{ fontSize: "20px", color: "#192435" }} />
                                                                        <p>{intl.get("package.service.contract_date")}: {item.contract_date}</p>
                                                                    </div>
                                                                }


                                                                {
                                                                    String(item.trial_period_days).length > 0 &&
                                                                    <div className='slick-list-item-content-item'>
                                                                        <Icon type='check-circle' style={{ fontSize: "20px", color: "#192435" }} />
                                                                        <p>{intl.get("package.days")}: {item.trial_period_days}</p>
                                                                    </div>
                                                                }
                                                                {
                                                                    String(item.fee_reduction_fixed_fee).length > 0 &&
                                                                    <div className='slick-list-item-content-item'>
                                                                        <Icon type='check-circle' style={{ fontSize: "20px", color: "#192435" }} />
                                                                        <p>{intl.get("package.service.fee_reduction_fixed_fee")}: ${item.fee_reduction_fixed_fee}</p>
                                                                    </div>
                                                                }
                                                                {
                                                                    String(item.fee_reduction_activity_service_rate).length > 0 &&
                                                                    <div className='slick-list-item-content-item'>
                                                                        <Icon type='check-circle' style={{ fontSize: "20px", color: "#192435" }} />
                                                                        <p>{intl.get("package.service.fee_reduction_activity_service_rate")}: {item.fee_reduction_activity_service_rate}%</p>
                                                                    </div>
                                                                }

                                                                {
                                                                    String(item.fee_reduction_rate).length > 0 &&
                                                                    <div className='slick-list-item-content-item'>
                                                                        <Icon type='check-circle' style={{ fontSize: "20px", color: "#192435" }} />
                                                                        <p>{intl.get("package.service.day_of_fee_reduction")}: {item.fee_reduction_rate}%</p>
                                                                    </div>
                                                                }
                                                                {
                                                                    String(item.prestore_money).length > 0 &&
                                                                    <div className='slick-list-item-content-item'>
                                                                        <Icon type='check-circle' style={{ fontSize: "20px", color: "#192435" }} />
                                                                        <p>{intl.get("package.service.prestore_money")}: ${item.prestore_money}</p>
                                                                    </div>
                                                                }
                                                                {
                                                                    String(item.spu_quantity).length > 0 &&
                                                                    <div className='slick-list-item-content-item'>
                                                                        <Icon type='check-circle' style={{ fontSize: "20px", color: "#192435" }} />
                                                                        <p>{intl.get("package.service.goods_count")}: {item.spu_quantity}</p>
                                                                    </div>
                                                                }

                                                                {
                                                                    is_coupon === 1 && String(item.coupon_quantity).length > 0 &&
                                                                    <div className='slick-list-item-content-item'>
                                                                        <Icon type='check-circle' style={{ fontSize: "20px", color: "#192435" }} />
                                                                        <p>{intl.get("package.service.coupon_count")}:{item.coupon_quantity}</p>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                            }

                        </div>
                    </div>
                </div>
            );
        } else {
            return null
        }

    }
}

export default withRouter(Form.create()(PackageList))

