import React from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Switch } from 'antd';
import { getLangList, editLang, editUse } from "../../action/authAction";
import { setLangType } from "../../util/function";
import intl from 'react-intl-universal';

class LangList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [] };
        this.columns = [
            {
                title: intl.get("common.table.id"),
                dataIndex: 'id',
                align: "center",
            },
            {
                title: intl.get("lang.list.name"),
                dataIndex: 'language_id',
                align: "center",
                render: (text, record) => {
                    return record.language.name
                }
            }, {
                title: intl.get("lang.list.symbol"),
                align: "center",
                render: (record) => {
                    return record.language.show_symbol
                }
            }, 


            /*{
                title: intl.get("lang.list.isUse"),
                dataIndex: 'is_use',
                align: "center",
                render: (text, record) => {
                    return <Switch checked={text === 1} onChange={() => {
                        this.isUseHandle(record.id, { is_use: text === 1 ? "0" : "1" })
                    }} />
                }


            },
            {
                title: intl.get("lang.list.isH5Default"),
                dataIndex: 'front_is_default',
                align: "center",
                render: (text, record) => {
                    return <Switch checked={text === 1} onChange={() => {
                        this.isUseHandle(record.id, { front_is_default: text === 1 ? "0" : "1" })
                    }} />
                }
            },*/
            
            {
                title: intl.get("lang.list.isManageDefault"),
                dataIndex: 'is_default',
                align: "center",
                render: (text, record) => {
                    return <Switch checked={text === 1} onChange={() => {
                        this.isUseHandle(record.id, { is_default: text === 1 ? "0" : "1" })
                    }} />
                }
            }

        ];
    }

    async componentDidMount() {
        let res = await getLangList()
        this.setState({ data: res.data.data })
    }

    exchanage = async (record) => { //默认语言
        editLang(record).then(data => {
            if (data && data.data) {
                getLangList().then(res => {
                    this.setState({ data: res.data.data })
                })

            }
        })
    }

    isUseHandle = async (id, data) => {
        await editUse(id, data)
        let res = await getLangList()
        this.setState({ data: res.data.data }, () => {
            if (data.is_use === "1") {
                setLangType(id)
            } else {
                let data = res.data.data
                for (let i in data) {
                    if (data[i].is_use === 1) {
                        setLangType(data[i].language_id)
                    }
                }
            }

        })

    }

    render() {
        return (
            <Table
                rowKey={record => record.id}
                dataSource={this.state.data}
                columns={this.columns}
                pagination={{
                    hideOnSinglePage: true
                }
                }
                className="waperBox"
            />

        );
    }
}

export default withRouter(LangList)

