import React from "react";
import { withRouter } from 'react-router-dom'
import { Table, Form, Input, Button, Card, Pagination } from 'antd';
import { getDocumentList, getContent } from "../../action/AdAction";
import intl from 'react-intl-universal';
import "./index.css"
import Header from "../header/index"
let { Search } = Input

class documentList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: "",
            meta: "",
            Height: document.body.clientHeight - 140 + "px"
        };
    }


    async componentDidMount() {
        window.addEventListener("resize", () => {
            this.setState({
                Height: document.body.clientHeight - 140 + "px"
            })
        })
        this.GetDocumentData(1)
    }

    componentWillUnmount() {
        window.removeEventListener("resize", () => {
            this.setState({
                Height: document.body.clientHeight - 140 + "px"
            })
        })
    }

    async GetDocumentData(page) {
        let res = await getDocumentList(page)
        let data = res.data.data
        if (data) {
            for (let i in data) {
                let a = await getContent(data[i].content)
                data[i].current_content = a.data
            }
            if (data[data.length - 1].current_content) {
                this.setState({
                    data: data,
                    meta: res.data.meta.pagination
                })
            }

        }


    }


    render() {
        const { data, meta, Height } = this.state
        if (data) {
            return (
                <div style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column" }}>
                    <Header />
                    <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <div className="container" style={{ maxWidth: "1260px", height: Height, overflowY: "scroll", flexFlow: "wrap", justifyContent: "center", alignContent: "flex-start" }}>

                            {
                                data.map((item, index) => {
                                    return <Card
                                        key={item.id}
                                        title={item.title}
                                        extra={<Button onClick={() => this.props.history.push("/documentDetail/" + item.id)} type="link">More</Button>}
                                        style={{ width: "400px", height: "210px", margin: "0px 10px", marginBottom: "20px" }}>
                                        <p
                                            style={{ width: "350px", height: "102px", wordBreak: "break-word", wordBreak: "break-all", overflow: "hidden" }}
                                            dangerouslySetInnerHTML={{
                                                __html: item.current_content
                                            }}
                                        />
                                    </Card>
                                })
                            }
                            {
                                data.length % 3 === 2 &&
                                <div style={{ width: "400px", margin: "10px", }} />
                            }

                        </div>
                        <div style={{ width: "100%", maxWidth: "1260px", marginTop: "10px", marginRight: "10px", display: "flex", justifyContent: "flex-end" }}>
                            <Pagination
                                current={meta.current_page}
                                pageSize={15}
                                total={meta.total}
                                onChange={(a) => {
                                    this.setState({
                                        page: a.current
                                    }, () => this.GetDocumentData(a.current))
                                }}
                            />
                        </div>
                    </div>
                </div >

            );
        } else {
            return (<div />)
        }
    }
}

export default withRouter(Form.create()(documentList))

