import React from "react";
import { withRouter } from 'react-router-dom'
import { PlusOutlined } from '@ant-design/icons';
import { Input, InputNumber, Table, Form, Switch, Button, Select, Modal, Card, Popover, message } from 'antd';
import { getShopList, putShop } from "../../action/shopAction";
import {  GetConfigSet } from "../../action/authAction"
import "./index.css"
const { Search, TextArea } = Input;

class ShopList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: "",
            meta: "",
            page: 1,
            LanguageData: "",
            language_id: "",
            visible: false,
            passwordVisible: false,
            newPassword: "",
            confirmPassword: "",
            isError: false,
            ItemId: "",
            is_open_spu_many_help: false,
            is_disable_goods_annex: false,
            is_open_partner_seat: false,
            is_eatmoon_show: false,
            is_open_on_delivery: false,
            is_open_logistics: false,
            is_authorization_code: false,
            is_open_printer: false,
            is_discount: false,
            is_update_activity_service_rate: false,
            is_coupon: false,
            is_open_up_live: false,
            is_open_sku: false,
            is_system_delivery_free: false,
            is_open_full_delivery_free: false,
            is_open_spu_many_help_system: false,
            is_self_pay: false,
            up_live_udid: "",
            up_live_udid_null: false,
            up_live_token: "",
            up_live_token_null: false,
            up_live_visible: false,
            confirmPasswordError: false,
            chooseAll: true,
            auditVisible: false,
            auditValue: "",
            packageVisible: false,
            ItemPackageData: "",
            ItemData: "",
            activity_order_fixed_fee: "",
            order_rate: "",
            activity_service_rate: ""
        };
        this.columns = [
            {
                title: 'id',
                dataIndex: 'id',
                width: '5%',
                align: "center"
            },
            
             {
                 title: 'bdid',
                 dataIndex: 'bdid',
                 width: '5%',
                 align: "center"
             },

            {
                title: '商家logo',
                width: '5%',
                align: "center",
                render: (res) => {
                    return (
                        <img style={{ width: "50px", height: "50px", objectFit: 'cover', backgroundColor: "#fff", borderRadius: "10px" }} src={res.logo} alt="" />
                    )
                }
            },
            {
                title: '用户名',
                dataIndex: 'name',
                width: '8%',
                align: "center"
            }, {
                title: '店铺名称',
                dataIndex: 'title',
                width: '8%',
                align: "center"
            },
        


        


            {
                title: '店铺状态',
                width: '8%',
                align: "center",
                render: (record) => {
                    return (
                        <Switch disabled checked={record.status === 1} onChange={() => this.onChange(record)} />
                    )
                }
            },


            

            {
                title: '操作',
                width: '5%',
                align: "center",
                render: (record) => {
                    return <Popover
                        style={{ width: 500 }}
                        content={
                            <div style={{ display: "flex" }}>
                                <Button
                                    style={{ marginRight: "5px" }}
                                    onClick={() => this.showPasswordModal(record)}>
                                    修改密码
                                </Button>


                                {/*<Button
                                    style={{ marginRight: "5px" }}
                                    onClick={() => {
                                        this.props.history.push("/editshop/" + record.id)
                                    }}>编辑</Button>

                                    */}

                                    

                                {
                                    record.is_audit === 0 &&
                                    <Button onClick={() => {
                                        this.setState({
                                            auditVisible: true,
                                            ItemId: record.id
                                        })

                                    }}>审核</Button>
                                }
                            </div>
                        }
                        trigger="hover"
                    >
                        <Button
                            style={{ marginRight: "5px" }}
                            shape="circle"
                            icon="more"
                        />
                    </Popover>
                },
            },

        ];

    }


    isEditing = record => record.id === this.state.editingKey;

    cancel = () => {
        this.setState({ editingKey: '' });
    };


    async componentDidMount() {
         
        this.setState({   language_id:11 }, () => {
            this.GetShopData(1, "", 1)
        })

    }

    async GetShopData(num, url, language_id) {
        let data = await getShopList(num, url, language_id)

        console.log("getshoplist=============",data)

        let res = await GetConfigSet()
        let allData = res.data.data
        this.setState({
            data: data.data.data,
            meta: data.data.meta.pagination,
            language_id,
            is_open_spu_many_help_system: allData.find(el => el.key === "is_open_spu_many_help").value == 1
        })
    }



    async onChange(record) {
        let { data } = this.state
        let NewData = data
        let status = "0"
        if (record.status === 1) {
            status = "0"
        } else {
            status = 1
        }
        for (let i in NewData) {
            if (NewData[i].id === record.id) {
                NewData[i].status = status
                this.setState({
                    data: NewData
                })
                break
            }
        }
        await putShop(record.id, { status })
    }


    showPasswordModal = (item) => {
        this.setState({
            passwordVisible: true,
            itemId: item.id,
            newPassword: "",
            confirmPassword: "",
            newPasswordError: false,
            confirmPasswordError: false,
        });
    };

    handlePasswordOk = e => {
        const { newPassword, confirmPassword, itemId, newPasswordError, confirmPasswordError } = this.state
        if (newPassword !== confirmPassword) {
            this.setState({
                isError: true
            })
        } else {
            this.setState({
                isError: false
            })
            if (newPassword && confirmPassword && !newPasswordError && !confirmPasswordError) {
                putShop(itemId, { "password": newPassword }).then(res => {
                    this.setState({
                        passwordVisible: false,
                    });
                })
            }
        }
    };

    handlePackageFreeOk = async () => {
        let { activity_order_fixed_fee, order_rate, activity_service_rate, itemId } = this.state
        let values = {}

        values["activity_order_fixed_fee"] = activity_order_fixed_fee
        values["rate"] = order_rate ? parseInt(order_rate) / 100 : ""
        values["activity_service_rate"] = activity_service_rate ? parseInt(activity_service_rate) / 100 : ""
        await putShop(itemId, values)

    }

    handleCancel = e => {
        this.setState({
            visible: false,
            passwordVisible: false,
        });
    };


    render() {
        const { data, meta, ItemData, LanguageData, is_open_spu_many_help, is_disable_goods_annex, is_open_partner_seat, is_eatmoon_show, is_open_on_delivery, is_open_logistics, is_authorization_code, is_open_printer, is_discount, is_update_activity_service_rate, is_coupon, is_open_up_live, is_open_sku, is_system_delivery_free, is_open_full_delivery_free, language_id, ItemId, is_open_spu_many_help_system, newPassword, confirmPassword, is_self_pay, up_live_token, up_live_token_null, up_live_udid, auditVisible, auditValue, chooseAll, ItemPackageData } = this.state
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 10 },
            },
        };
        if (data) {
            return (
                <div style={{   backgroundColor: '#fff' ,padding:"20px"}}>


                   {/* <div style={{ width: "100%", height: "80px", display: "flex", justifyContent: "space-between", alignItems: "center", backgroundColor: '#fff' }}>
                        

                        
                        <div>


                            



                            {
                                LanguageData ? <Select
                                    style={{ width: "100px", margin: "0px 10px" }}
                                    defaultValue={LanguageData[1].name}
                                    onChange={(e) => {
                                        this.GetShopData(1, "", e)
                                    }}
                                >
                                    {LanguageData && LanguageData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                                </Select> : <div />

                            }
                            <Search
                                style={{ width: 400, marginLeft: "5px" }}
                                placeholder={'请输入店铺名称'}
                                enterButton={'查询'}
                                onSearch={(val) => {
                                    this.GetShopData(1, "&search=" + val, this.state.language_id)
                                }}
                            />
                            <Button
                                onClick={() => {
                                    this.setState({
                                        chooseAll: true
                                    }, () => this.GetShopData(1, "", this.state.language_id))
                                }}
                                style={{ marginRight: "10px", marginLeft: '10px', backgroundColor: chooseAll ? "#5867dd" : "#fff", color: chooseAll ? "#fff" : "#000000a6" }}
                            >
                                全部
                            </Button>
                             
                        </div>

                      



                        
                    </div>  */}



                    <Table
                        rowKey='id'
                        dataSource={data}
                        columns={this.columns}
                        pagination={{
                            hideOnSinglePage: true,
                            pageSize: meta.per_page,
                            total: meta.total
                        }}
                        onChange={(e) => {
                            this.setState({
                                page: e.current
                            }, () => this.GetShopData(e.current, ""))

                        }}
                    />
                    <Modal
                        title="修改密码"
                        visible={this.state.passwordVisible}
                        onOk={this.handlePasswordOk}
                        onCancel={this.handleCancel}
                    >
                        <div style={{ height: "60px" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <span style={{ width: "120px", textAlign: "right", marginRight: 5 }}>密码:</span>
                                <Input
                                    value={newPassword}
                                    onChange={(e) => {
                                        e.persist()
                                        let value = e.target.value.replace(/\W/g, '')
                                        if (value.length > 16 || value.length < 6) {
                                            this.setState({
                                                newPasswordError: true
                                            })
                                        } else {
                                            this.setState({
                                                newPasswordError: false
                                            })
                                        }
                                        this.setState({
                                            newPassword: value
                                        })
                                    }} />
                            </div>
                            {
                                this.state.newPasswordError &&
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <span style={{ width: "120px", textAlign: "right", marginRight: 5 }} />
                                    <span style={{ width: "100%", fontSize: "12px", marginTop: "4px", color: "#ff0000" }}>请保持密码长度为6-16位</span>
                                </div>
                            }
                        </div>
                        <div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <span style={{ width: "120px", textAlign: "right", marginRight: 5 }}>确认密码:</span>
                                <Input
                                    value={confirmPassword}
                                    onChange={(e) => {
                                        e.persist()
                                        let value = e.target.value.replace(/\W/g, '')
                                        if (value.length > 16 || value.length < 6) {
                                            this.setState({
                                                confirmPasswordError: true
                                            })
                                        } else {
                                            this.setState({
                                                confirmPasswordError: false
                                            })
                                        }
                                        this.setState({
                                            confirmPassword: value
                                        })
                                    }} />
                            </div>
                            {
                                this.state.isError &&
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <span style={{ width: "120px", textAlign: "right", marginRight: 5 }} />
                                    <span style={{ width: "100%", fontSize: "12px", marginTop: "4px", color: "#ff0000" }}>两次密码不一致</span>
                                </div>
                            }
                            {
                                this.state.confirmPasswordError &&
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <span style={{ width: "120px", textAlign: "right", marginRight: 5 }} />
                                    <span style={{ width: "100%", fontSize: "12px", marginTop: "4px", color: "#ff0000" }}>请保持密码长度为6-16位</span>
                                </div>
                            }

                        </div>
                    </Modal>
                    <Modal
                        title="套餐详情"
                        visible={this.state.packageVisible}
                        onOk={this.handlePackageFreeOk}
                        onCancel={() => {
                            this.setState({
                                packageVisible: false
                            })
                        }}
                    >
                        
                    </Modal>

                    <Modal
                        title="行为"
                        visible={this.state.visible}
                        onCancel={() => {
                            this.setState({
                                visible: false,
                                ItemId: "",
                                is_open_spu_many_help: false,
                                is_disable_goods_annex: false,
                                is_open_partner_seat: false,
                                is_eatmoon_show: false,
                                is_open_on_delivery: false,
                                is_open_logistics: false,
                                is_authorization_code: false,
                                is_open_printer: false,
                                is_discount: false,
                                is_update_activity_service_rate: false,
                                is_coupon: false,
                                is_open_up_live: false,
                                is_open_sku: false,
                                is_system_delivery_free: false,
                                is_open_full_delivery_free: false,
                            })
                        }}
                        footer={null}
                    >

                        <Card style={{ marginBottom: "10px" }}>
                            <div style={{ display: "flex" }}>
                                <div style={{ width: "80px" }}>
                                    V1基础版
                                </div>
                                <div>
                                     
                                     
                                </div>
                            </div>
                        </Card>
                        <Card style={{ marginBottom: "10px" }}>
                            <div style={{ display: "flex" }}>
                                <div style={{ width: "80px" }}>
                                    V2网店版
                                </div>
                                <div>
                                     
                                </div>
                            </div>
                        </Card>
                        <Card style={{ marginBottom: "10px" }}>
                            <div style={{ display: "flex" }}>
                                <div style={{ width: "80px" }}>
                                    V3促销版
                                </div>
                                <div>
 
                                </div>
                            </div>
                        </Card>
                        <Card style={{ marginBottom: "10px" }}>
                            <div style={{ display: "flex" }}>
                                <div style={{ width: "80px" }}>
                                    V4优惠券版
                                </div>
                                <div>
 
                                </div>
                            </div>
                        </Card>

                        <Card style={{ marginBottom: "10px" }}>
                            <div style={{ display: "flex" }}>
                                <div style={{ width: "130px" }}>
                                    第三方视频平台
                                </div>
                                <div>

                                     
                                    <Modal
                                        title="第三方视频平台"
                                        visible={this.state.up_live_visible}
                                        centered
                                        width={435}
                                        onOk={() => {
                                            this.setState({
                                                up_live_token_null: !up_live_token ? true : false,
                                                up_live_udid_null: !up_live_udid ? true : false
                                            })
                                            if (up_live_token && up_live_udid) {
                                                putShop(ItemId, { is_open_up_live: "1", up_live_token, up_live_udid }).then(res => {
                                                    this.setState({
                                                        is_open_up_live: true,
                                                        up_live_visible: false
                                                    }, () => {
                                                        this.GetShopData(1, "", language_id)
                                                    })
                                                })
                                            }

                                        }}
                                        onCancel={() => this.setState({ up_live_visible: false })}
                                    >
                                        
                                       
                                    </Modal>


                                </div>
                            </div>
                        </Card>
                    </Modal>
                    <Modal
                        title="确认通过审核"
                        visible={auditVisible}
                        onOk={() => {
                            if (auditValue === "confirm") {
                                putShop(ItemId, { "is_audit": 1 }).then(res => {
                                    this.setState({
                                        chooseAll: true,
                                        auditVisible: false
                                    }, () => {
                                        this.GetShopData(1, "", this.state.language_id)
                                    })

                                })

                            } else {
                                message.error("输入错误！")
                            }
                        }}
                        onCancel={() => this.setState({ auditVisible: false, auditValue: "" })}
                        style={{ marginTop: "100px" }}
                    >
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <span style={{ marginBottom: "15px" }}>请在输入框中输入"confirm"确认</span>
                            <Input
                                style={{ width: "200px", textAlign: 'center' }}
                                value={auditValue}
                                onChange={(e) => {
                                    e.persist()
                                    this.setState({
                                        auditValue: e.target.value.toLowerCase()
                                    })
                                }} />
                        </div>

                    </Modal>
                </div>
            );
        } else {
            return (<div />)
        }

    }
}

export default withRouter(Form.create()(ShopList))

