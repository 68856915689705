import React from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Popconfirm, Form, Button } from 'antd';
import { getAdList, deleteAd } from "../../action/AdAction";
import intl from 'react-intl-universal';

class AdList extends React.Component {

    constructor(props) {
        super(props);
        this.state = { data: [] };
        this.columns = [
            {
                title: intl.get('common.table.id'),
                dataIndex: 'id',
                width: '5%',
                align: "center",
            },
            {
                title: intl.get('ad.form.title'),
                dataIndex: 'title',
                width: '20%',
                align: "center",
            }, {
                title: intl.get('ad.form.img'),
                dataIndex: 'image',
                width: '15%',
                align: "center",
                render: (image) => {
                    return (<img src={image} width="100px" alt="" style={{ objectFit: "contain" }} />)
                }
            },
            {
                title: intl.get('ad.form.type'),
                dataIndex: 'type',
                width: '15%',
                align: "center",
                render: (type) => {
                    return <span>{type === 1 ? intl.get('ad.form.goods') : intl.get('ad.form.cate')}</span>
                }
            },
            {
                title: intl.get('ad.table.link'),
                dataIndex: 'url_link',
                width: '15%',
                align: "center",
            },
            {
                title: intl.get('ad.form.seq'),
                dataIndex: 'sort',
                width: '10%',
                align: "center",
            },
            {
                title: intl.get('home.table.operation'),
                dataIndex: 'operation',
                width: '5%',
                align: "center",
                render: (text, record) => {
                    return (
                        <div className='deleteView'>
                            <Button
                                style={{ marginRight: "10px" }}
                                onClick={() => {
                                    this.props.history.push("/EditAd/" + record.id)
                                }}>
                                {intl.get('seat.table.btn.edit')}
                            </Button>
                            <Popconfirm title={intl.get('home.table.operation.btn.horder')} onConfirm={() => this.handleDelete(record)}>
                                <Button>
                                    {intl.get('home.table.operation.btn.delete')}
                                </Button>
                            </Popconfirm>
                        </div>

                    );
                },
            },

        ];
    }

    async componentDidMount() {
        let res = await getAdList()
        this.setState({ data: res.data.data })
    }


    async handleDelete(data) {
        await deleteAd(data.id)
        const dataSource = [...this.state.data];
        this.setState({ data: dataSource.filter(item => item.id !== data.id) });
    }

    render() {
        return (
            <div>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                    <div />
                    <Button type="primary"
                        onClick={() => this.props.history.push("/advertisement")}
                    >
                        {intl.get('nav.extension.carousel.add')}
                        {/* 新建 */}
                    </Button>
                </div>
                <Table
                    rowKey={record => record.id}
                    // bordered
                    dataSource={this.state.data}
                    columns={this.columns}
                    pagination={{
                        hideOnSinglePage: true
                    }}
                    className="waperBox"
                />
            </div>


        );
    }
}

export default withRouter(Form.create()(AdList))

