import React from "react";
import { withRouter } from 'react-router-dom'
import { Table, Form, Input, Button, Card, Pagination } from 'antd';
import { getDocumentDetail, getContent } from "../../action/AdAction";
import intl from 'react-intl-universal';
import "./index.css"
import Header from "../header/index"

class documentDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: "",
            Height: document.body.clientHeight - 130 + "px"
        };
    }


    async componentDidMount() {
        window.addEventListener("resize", () => {
            this.setState({
                Height: document.body.clientHeight - 130 + "px"
            })
        })
        this.GetDocumentData()
    }

    componentWillUnmount() {
        window.removeEventListener("resize", () => {
            this.setState({
                Height: document.body.clientHeight - 130 + "px"
            })
        })
    }

    async GetDocumentData() {
        const id = this.props.location.pathname.substring(16)

        let res = await getDocumentDetail(id)
        let data = res.data
        if (data) {
            getContent(data.content).then(a => {
                console.log(a.data)
                data.current_content = a.data
                this.setState({
                    data
                })
            })
        }


    }


    render() {
        const { data, Height } = this.state
        if (data) {
            return (
                <div style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column" }}>
                    <Header />
                    <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <p style={{ fontSize: "24px", fontWeight: "700", marginBottom: "10px", marginTop: "-10px" }}>{data.title}</p>
                        <div className="container" style={{ maxWidth: "1000px", height: Height, overflowY: "scroll", justifyContent: "center", backgroundColor: "#fff", margin: "0px 20px" }}>

                            <p
                                style={{ width: "100%", wordBreak: "break-word", wordBreak: "break-all", padding: "20px" }}
                                dangerouslySetInnerHTML={{
                                    __html: data.current_content
                                }}
                            />


                        </div>
                    </div>
                </div >

            );
        } else {
            return (<div />)
        }
    }
}

export default withRouter(Form.create()(documentDetail))

