import React, { Component } from 'react'
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom'
import { getTokenStorage } from "./util/function";


import Login from "../src/page/login/index"
import RegisterForm from "../src/page/login/register"
import Admin from "./admin";
// import Home from "./page/home";
import User from "./page/user/user";
import UserDetail from "./page/user/userDetail";
import SetUp from "./page/shopSetup/setup"
import Licence from "./page/shopSetup/licence"
import UpLiveToken from "./page/shopSetup/upLiveToken"
import EditPassword from "./page/shopSetup/EditPassword"
import NewDiscount from "./page/discount/discount"
import discountList from "./page/discount/discountList"
import EditDiscount from "./page/discount/editDiscount"
import DiscountSet from "./page/discount/discountset"
import NewAddress from "./page/address/NewAddress"
import AddressList from "./page/address/addressList"
import NewCategory from "./page/category/NewCategory"
import EditCategory from "./page/category/EditCategory"
import CategoryList from "./page/category/categoryList"
import GoodsType from "./page/goods/goodsType"
import NewGoodsSpecification from "./page/goodsSpecification/NewGoodsSpecification"
import GoodsSpecificationList from "./page/goodsSpecification/GoodsSpecificationList"
import NewGoodsSpecificationItem from "./page/goodsSpecification/NewGoodsSpecificationItem"
import GoodsSpecificationListItem from "./page/goodsSpecification/GoodsSpecificationItemList"
import NewGoodsAnnexType from "./page/goodsAnnexType/NewGoodsAnnexType"
import GoodsAnnexTypeList from "./page/goodsAnnexType/goodsAnnexTypeList"
import NewGoodsAnnexTypeItem from "./page/goodsAnnexType/NewGoodsAnnexTypeItem"
import EditGoodsAnnexType from "./page/goodsAnnexType/EditGoodsAnnexType"
// import GoodsAnnexTypeListItem from "./page/goodsAnnexType/goodsAnnexTypeItemList"
import NewPromotionalGoods from "./page/promotionalgoods/Newpromotionalgoods"
import EditPromotionalGoods from "./page/promotionalgoods/Editpromotionalgoods"
import ViewPromotionalGoods from "./page/promotionalgoods/Viewpromotionalgoods"
import PromotionalGoodsList from "./page/promotionalgoods/promotionalgoodsList"
import IsDeletePromotionalGoodsList from "./page/promotionalgoods/isdeletepromotionalgoodsList"
import NewCoupon from "./page/coupon/NewCoupon"
import EditCoupon from "./page/coupon/EditCoupon"
import CouponList from "./page/coupon/CouponList"
import ViewCoupon from "./page/coupon/ViewCoupon"
import IsDeleteCoupon from "./page/coupon/isdeleteCoupon"
import UserCoupon from "./page/coupon/userCoupon"
import NewGoodArr from "./page/goodsAttr/NewgoodsAttr"
import GoodsArrList from "./page/goodsAttr/GoodsArrList"
import NewGoods from "./page/goods/NewGood"
import GoodList from "./page/goods/goodList"
import IsDeleteGoodsList from "./page/goods/isdeletegoodList"
import SelectedGoodList from "./page/goods/selectgoodList"
import EditGood from "./page/goods/EditGood"
import NewAd from "./page/ad/NewAd";
import AdList from "./page/ad/AdList";
import EditAd from "./page/ad/EditAd";
import NewMenu from "./page/menu/newMenu";
import MenuList from "./page/menu/menulist";
import EditMenu from "./page/menu/editMenu";
import NewTag from "./page/tag/newtag";
import TagList from "./page/tag/taglist";
import NewAnnouncement from "./page/Announcement/newAnnouncement";
import AnnouncementList from "./page/Announcement/AnnouncementList";
import EditAnnouncement from "./page/Announcement/EditAnnouncement";
import SystemAnnouncement from "./page/Announcement/SystemAnnouncement";
import EditSystemAnnouncement from "./page/Announcement/EditSystemAnnouncement";
import OrderList from "./page/order/orderList"
import OrderDetail from "./page/order/orderDetail"
import OrderLine from "./page/order/orderLine"
import CashWithdrawal from "./page/wallet/cashwithdrawal"
import withdrawLog from "./page/wallet/withdrawLog"
import WithdrawLogDetail from "./page/wallet/withdrawLogDetail"
import NewSeat from "./page/seat/newSeat"
import SeatList from "./page/seat/seatList"
import NewFreight from "./page/freight/newFreight"
import FreightList from "./page/freight/freightList"
import NewPackCkarge from "./page/packCkarge/NewPackCkarge"
import PackCkargeList from "./page/packCkarge/packCkargeList"
import RedPackageList from "./page/redPackage/redPackageList"
import ActivityList from "./page/redPackage/ActivityList"
import AdminList from "./page/admin/adminList"
import NewAdmin from "./page/admin/NewAdmin"
import EditAdmin from "./page/admin/EditAdmin"
import PackageList from './page/package/packageList'
import PackageDetail from './page/package/packageDetail'
import PackageLogList from './page/package/packageLoglist'
import BindPay from './page/bindPay/list'
import LanguageList from './page/lang/list'
import MySubscription from './page/subscription/index';
import MySubscriptionDetail from './page/subscription/detail';
import MyOrders from './page/myOrders/index';
import MyOrdersDetail from './page/myOrders/detail';
import DataStatistics from './page/statistics/index';
import BusinessHours from './page/businessHours';
import businessHoliday from './page/businessHours/holiday/holidayList';
import selfBusinessHoliday from './page/businessHours/holiday/selfHolidayList';
import NewHoliday from './page/businessHours/holiday/NewHoliday';
import EditHoliday from './page/businessHours/holiday/EditHoliday';
import HolidayBusinessList from "./page/businessHours/holidayBusiness/holidayBusinessList"
import NewHolidayBusiness from "./page/businessHours/holidayBusiness/NewHolidayBusiness"
import EditHolidayBusiness from "./page/businessHours/holidayBusiness/EditHolidayBusiness"
import goodsAnnexTypeItemList from './page/goodsAnnexType/goodsAnnexTypeItemList';
import navList from './page/navList/list'
import Behavior from './page/behavior/index'
import toStore from './page/behavior/toStore'
import delivery from './page/behavior/delivery'


import AlbumList from './page/album/AlbumList'; //商家相册
import EditAlbum from './page/album/EditAlbum'; //商家相册编辑
import NewAlbum from './page/album/NewAlbum'; //商家相册新建
import MenuConfig from './page/menu/menuConfig';//招牌商品设置
import PaymentConfig from './page/album/PaymentConfig';//第三方支付
import AnnualFeeList from './page/wallet/AnnualFee/AnnualFeeList';//年费流水
import OrderFeeList from './page/wallet/AnnualFee/orderFeeList';//年费流水
import OrderChargeFeeList from './page/wallet/AnnualFee/orderChargeFeeList';//年费流水
import SiteCountry from './page/shopSetup/SiteCountry';
import SalesArea from './page/shopSetup/salesArea';
import PrinterList from './page/shopSetup/printer/PrinterList';//打印机列表 
import EditPrinter from './page/shopSetup/printer/EditPrinter';//打印机列表  
import Download from "./page/download/index"
import GoodsPlug from "./page/plugIn/goodsPlug"
import ToStorePlug from "./page/plugIn/toStorePlug"
import CurrencyPlug from "./page/plugIn/currencyPlug"
import DeliveryPlug from "./page/plugIn/deliveryPlug"
import documentList from './page/document/documentList';
import documentDetail from './page/document/documentDetail';



//补充商家路由
import ShopList from "./page/shopList/shoplist"
import applyPackageList from "./page/shopList/applyPackageList"



class Router extends Component {
    render() {
        return (
            <HashRouter>
                <Switch>
                    <Route path='/' exact>
                        {
                            getTokenStorage() ?
                                <Redirect to='/dataStatistics' /> :
                                <Redirect to='/login' />
                        }
                    </Route>
                    <Route path='/login' component={Login} />
                    <Route path='/documentList' component={documentList} />
                    <Route path='/documentDetail' component={documentDetail} />

                    {/* <Route path='/register' component={RegisterForm} /> */}
                    <Route
                        render={() => {
                            return (
                                <Admin>
                                    <Switch>
                                        {/* <Route path='/home' component={Home} /> */}
                                        <Route path='/setup' component={SetUp} />
                                        <Route path='/licence' component={Licence} />
                                        <Route path='/upLiveToken' component={UpLiveToken} />
                                        <Route path='/EditPassword' component={EditPassword} />
                                        <Route path='/discount' component={NewDiscount} />
                                        <Route path='/discountset' component={DiscountSet} />
                                        <Route path='/discountList' component={discountList} />
                                        <Route path='/editdiscount' component={EditDiscount} />
                                        <Route path='/user' component={User} />
                                        <Route path='/userDetail' component={UserDetail} />
                                        <Route path='/admin' component={AdminList} />
                                        <Route path='/NewAdmin' component={NewAdmin} />
                                        <Route path='/EditAdmin' component={EditAdmin} />
                                        <Route path='/addCate' component={NewCategory} />
                                        <Route path='/EditCategory' component={EditCategory} />
                                        <Route path='/addDeliveryAddress' component={NewAddress} />
                                        <Route path='/DeliveryAddressList' component={AddressList} />
                                        <Route path='/cateList' component={CategoryList} />
                                        <Route path='/goodType' component={GoodsType} />
                                        <Route path='/addGoodsSpecification' component={NewGoodsSpecification} />
                                        <Route path='/goodsSpecificationList' component={GoodsSpecificationList} />
                                        <Route path='/addGoodsSpecificationItem' component={NewGoodsSpecificationItem} />
                                        <Route path='/goodsSpecificationItemList' component={GoodsSpecificationListItem} />

                                        <Route path='/addGoodsAnnexType' component={NewGoodsAnnexType} />
                                        <Route path='/goodsAnnexTypeList' component={GoodsAnnexTypeList} />
                                        <Route path='/addGoodsAnnexTypeItem' component={NewGoodsAnnexTypeItem} />
                                        <Route path='/goodsAnnexTypeItemList' component={goodsAnnexTypeItemList} />
                                        <Route path='/EditGoodsAnnexType' component={EditGoodsAnnexType} />

                                        <Route path='/addPromotionalGoods' component={NewPromotionalGoods} />
                                        <Route path='/EditPromotionalGoods' component={EditPromotionalGoods} />
                                        <Route path='/ViewPromotionalGoods' component={ViewPromotionalGoods} />
                                        <Route path='/promotionalgoodsList' component={PromotionalGoodsList} />
                                        <Route path='/isdeletepromotionalgoodsList' component={IsDeletePromotionalGoodsList} />
                                        <Route path='/newCoupon' component={NewCoupon} />
                                        <Route path='/editCoupon' component={EditCoupon} />
                                        <Route path='/ViewCoupon' component={ViewCoupon} />
                                        <Route path='/couponList' component={CouponList} />
                                        <Route path='/isDeleteCoupon' component={IsDeleteCoupon} />
                                        <Route path='/userCoupon' component={UserCoupon} />

                                        <Route path='/addGoodArr' component={NewGoodArr} />
                                        <Route path='/goodsArrList' component={GoodsArrList} />
                                        <Route path='/newGoods' component={NewGoods} />
                                        <Route path='/goodsList' component={GoodList} />
                                        <Route path='/isdeletegoodsList' component={IsDeleteGoodsList} />
                                        <Route path='/selectedgoods' component={SelectedGoodList} />
                                        <Route path='/EditGood' component={EditGood} />
                                        <Route path='/advertisement' component={NewAd} />
                                        <Route path='/advertisementList' component={AdList} />
                                        <Route path='/EditAd' component={EditAd} />
                                        {/* <Route path='/newMenu' component={NewMenu} /> */}
                                        <Route path='/MenuList' component={MenuList} />
                                        <Route path='/EditMenu' component={EditMenu} />
                                        <Route path='/NewTag' component={NewTag} />
                                        <Route path='/TagList' component={TagList} />
                                        <Route path='/NewAnnouncement' component={NewAnnouncement} />
                                        <Route path='/AnnouncementList' component={AnnouncementList} />
                                        <Route path='/EditAnnouncement' component={EditAnnouncement} />
                                        <Route path='/SystemAnnouncement' component={SystemAnnouncement} />
                                        <Route path='/EditSystemAnnouncement' component={EditSystemAnnouncement} />
                                        <Route path='/Cashwithdrawal' component={CashWithdrawal} />
                                        <Route path='/OrderList' component={OrderList} />
                                        <Route path='/OrderDetail' component={OrderDetail} />
                                        <Route path='/OrderLine' component={OrderLine} />
                                        <Route path='/withdrawLog' component={withdrawLog} />
                                        <Route path='/withdrawLogDetail' component={WithdrawLogDetail} />
                                        <Route path='/newSeat' component={NewSeat} />
                                        <Route path='/seatList' component={SeatList} />
                                        <Route path='/newFreight' component={NewFreight} />
                                        <Route path='/freightList' component={FreightList} />
                                        <Route path='/PackCharge' component={NewPackCkarge} />
                                        <Route path='/PackChargeList' component={PackCkargeList} />
                                        <Route path='/redPackageList' component={RedPackageList} />
                                        <Route path='/ActivityList' component={ActivityList} />
                                        <Route path='/package' component={PackageList} />
                                        <Route path='/packageDetail' component={PackageDetail} />
                                        <Route path='/packageLog' component={PackageLogList} />
                                        <Route path='/bindPay' component={BindPay} />
                                        <Route path='/languageList' component={LanguageList} />
                                        <Route path='/mySubscription' component={MySubscription} />
                                        <Route path='/mySubscriptionDetail' component={MySubscriptionDetail} />
                                        <Route path='/myOrders' component={MyOrders} />
                                        <Route path='/myOrderDetail' component={MyOrdersDetail} />
                                        <Route path='/dataStatistics' component={DataStatistics} />
                                        <Route path='/businessHours' component={BusinessHours} />
                                        <Route path='/businessHoliday' component={businessHoliday} />
                                        <Route path='/selfBusinessHoliday' component={selfBusinessHoliday} />
                                        <Route path='/NewHoliday' component={NewHoliday} />
                                        <Route path='/EditHoliday' component={EditHoliday} />
                                        <Route path='/holidayBusinessList' component={HolidayBusinessList} />
                                        <Route path='/NewHolidayBusiness' component={NewHolidayBusiness} />
                                        <Route path='/EditHolidayBusiness' component={EditHolidayBusiness} />
                                        <Route path='/navList' component={navList} />
                                        <Route path='/behavior' component={Behavior} />
                                        <Route path='/toStore' component={toStore} />
                                        <Route path='/delivery' component={delivery} />

                                        <Route path='/AlbumList' component={AlbumList} />
                                        <Route path='/NewAlbum' component={NewAlbum} />
                                        <Route path='/EditAlbum' component={EditAlbum} />
                                        <Route path='/MenuConfig' component={MenuConfig} />
                                        <Route path='/PaymentConfig' component={PaymentConfig} />
                                        <Route path='/AnnualFeeList' component={AnnualFeeList} />
                                        <Route path='/OrderFeeList' component={OrderFeeList} />
                                        <Route path='/OrderChargeFeeList' component={OrderChargeFeeList} />
                                        <Route path='/SiteCountry' component={SiteCountry} />
                                        <Route path='/salesArea' component={SalesArea} />
                                        <Route path='/PrinterList' component={PrinterList} />
                                        <Route path='/EditPrinter' component={EditPrinter} />
                                        <Route path='/download' component={Download} />
                                        <Route path='/toStorePlug' component={ToStorePlug} />
                                        <Route path='/goodsPlug' component={GoodsPlug} />
                                        <Route path='/currencyPlug' component={CurrencyPlug} />
                                        <Route path='/deliveryPlug' component={DeliveryPlug} />


                                        <Route path='/shop' component={ShopList} />  
                                        <Route path='/applyPackageList' component={applyPackageList} />

                                    </Switch>
                                </Admin>
                            )
                        }}
                    />
                </Switch>
            </HashRouter>
        )
    }
}

export default Router
