
import { Axios } from "../util/request";
import { API } from "../util/api";
import { getLangType } from '../util/function';
import intl from 'react-intl-universal';
// 新建分类
export const AddCategory = (data) => {
    return Axios.ajax({
        url: API.category.NEWCATEGORY,
        method: "post",
        tips: intl.get("summit.success"),
        data
    })
}

// 获取分类列表
export const getCategory = () => {
    return Axios.ajax({
        url: `${API.category.NEWCATEGORY}?request_language_id=${getLangType()}`,
        method: "get"
    })
}
// 获取分类详情
export const getCategoryDetail = (id) => {
    return Axios.ajax({
        url: `${API.category.NEWCATEGORY}${id}?request_language_id=${getLangType()}`,
        method: "get"
    })
}


// 修改分类
export const putCategory = (data, id) => {
    return Axios.ajax({
        url: API.category.EDITCATEGORY + id,
        method: "post",
        tips: intl.get("update.success"),
        data,
    })
}


// 删除分类
export const deleteCategory = (id) => {
    return Axios.ajax({
        url: API.category.NEWCATEGORY + id,
        method: "delete",
        tips: intl.get("delete.success"),
    })
}


// 新建商品类型
export const AddGoodType = (data) => {
    return Axios.ajax({
        url: API.goodsType,
        method: "post",
        tips: intl.get("summit.success"),
        data
    })
}

// 获取商品类型
export const getGoodType = () => {
    return Axios.ajax({
        url: `${API.goodsType}?request_language_id=${getLangType()}`,
        method: "get"
    })
}

// 修改商品类型
export const putGoodType = (data, id) => {
    return Axios.ajax({
        url: API.goodsType + id,
        method: "put",
        tips: intl.get("update.success"),
        data,
    })
}

// 删除分类
export const deleteGoodType = (id) => {
    return Axios.ajax({
        url: API.goodsType + id,
        method: "delete",
        tips: intl.get("delete.success"),
    })
}


// 新建商品规格
export const AddGoodsSpecification = (data) => {
    return Axios.ajax({
        url: API.goodsSpecification.goodsSpecification,
        method: "post",
        tips: intl.get("summit.success"),
        data
    })
}

// 获取商品规格列表
export const getGoodsSpecification = () => {
    return Axios.ajax({
        url: `${API.goodsSpecification.goodsSpecification}${API.goodsSpecification.include}&request_language_id=${getLangType()}`,
        method: "get"
    })
}

// 修改商品规格
export const putGoodsSpecification = (data, id) => {
    return Axios.ajax({
        url: API.goodsSpecification.goodsSpecification + "/" + id,
        method: "put",
        tips: intl.get("update.success"),
        data,
    })
}

// 删除商品规格
export const deleteGoodsSpecification = (id) => {
    return Axios.ajax({
        url: API.goodsSpecification.goodsSpecification + "/" + id,
        method: "delete",
        tips: intl.get("delete.success"),
    })
}



// 新建商品规格项
export const AddGoodsSpecificationItem = (data) => {
    return Axios.ajax({
        url: API.goodsSpecification.goodsSpecificationItem,
        method: "post",
        tips: intl.get("summit.success"),
        data
    })
}

// 获取商品规格列表
export const getGoodsSpecificationItem = () => {
    return Axios.ajax({
        url: `${API.goodsSpecification.goodsSpecificationItem}?request_language_id=${getLangType()}`,
        method: "get"
    })
}


// 修改商品规格
export const putGoodsSpecificationItem = (data, id) => {
    return Axios.ajax({
        url: API.goodsSpecification.goodsSpecificationItem + "/" + id,
        method: "put",
        tips: intl.get("update.success"),
        data,
    })
}

// 删除商品规格
export const deleteGoodsSpecificationItem = (id) => {
    return Axios.ajax({
        url: API.goodsSpecification.goodsSpecificationItem + "/" + id,
        method: "delete",
        tips: intl.get("delete.success"),
    })
}


// 新建附加商品类型
export const AddGoodsAnnexType = (data) => {
    return Axios.ajax({
        url: API.goodsAnnexType.goodsAnnexType,
        method: "post",
        tips: intl.get("summit.success"),
        data
    })
}

// 获取附加商品类型列表
export const getGoodsAnnexTypeList = () => {
    return Axios.ajax({
        url: `${API.goodsAnnexType.goodsAnnexType}${API.goodsAnnexType.includeType}&request_language_id=${getLangType()}`,
        method: "get"
    })
}

// 修改附加商品类型
export const putGoodsAnnexType = (data, id) => {
    return Axios.ajax({
        url: API.goodsAnnexType.goodsAnnexType + "/" + id,
        method: "put",
        tips: intl.get("update.success"),
        data,
    })
}

// 删除附加商品类型
export const deleteGoodsAnnexType = (id) => {
    return Axios.ajax({
        url: API.goodsAnnexType.goodsAnnexType + "/" + id,
        method: "delete",
        tips: intl.get("delete.success"),
    })
}
// 获取附加商品类型详情
export const getGoodsAnnexTypeDetail = (id) => {
    return Axios.ajax({
        url: `${API.goodsAnnexType.goodsAnnexType}/${id}?request_language_id=${getLangType()}`,
        method: "get"
    })
}


// 新建附加商品内容
export const AddGoodsAnnexTypeItem = (data) => {
    return Axios.ajax({
        url: API.goodsAnnexType.goodsAnnexTypeItem,
        method: "post",
        tips: intl.get("summit.success"),
        data
    })
}

// 获取附加商品类内容列表
export const getGoodsAnnexTypeItemList = () => {
    return Axios.ajax({
        url: `${API.goodsAnnexType.goodsAnnexTypeItem}${API.goodsAnnexType.includeItem}&request_language_id=${getLangType()}`,
        method: "get"
    })
}


// 修改附加商品内容
export const putGoodsAnnexTypeItem = (data, id) => {
    return Axios.ajax({
        url: API.goodsAnnexType.goodsAnnexTypeItem + "/" + id,
        method: "put",
        tips: intl.get("update.success"),
        data,
    })
}

// 删除附加商品内容
export const deleteGoodsAnnexTypeItem = (id) => {
    return Axios.ajax({
        url: API.goodsAnnexType.goodsAnnexTypeItem + "/" + id,
        method: "delete",
        tips: intl.get("delete.success"),
    })
}

// 新建商品属性
export const AddGoodsAttr = (data) => {
    return Axios.ajax({
        url: API.goodsAttr,
        method: "post",
        tips: intl.get("summit.success"),
        data
    })
}

// 获取商品属性列表
export const getGoodsAttr = () => {
    return Axios.ajax({
        url: `${API.goodsAttr}?request_language_id=${getLangType()}`,
        method: "get"
    })
}


// 修改商品属性
export const putGoodsAttr = (data, id) => {
    return Axios.ajax({
        url: API.goodsAttr + "/" + id,
        method: "put",
        tips: intl.get("update.success"),
        data,
    })
}

// 删除商品属性
export const deleteGoodsAttr = (id) => {
    return Axios.ajax({
        url: API.goodsAttr + "/" + id,
        method: "delete",
        tips: intl.get("delete.success"),
    })
}


// 新建促销商品
export const AddSpuDiscount = (data) => {
    return Axios.ajax({
        url: API.spuDiscount,
        method: "post",
        tips: intl.get("summit.success"),
        data
    })
}

// 获取促销商品列表
export const getSpuDiscountList = (page, url,partner_id,) => {
    return Axios.ajax({
        url: `${API.spuDiscount}?include=spu&is_discount=1&page=${page}${url}&partner_id=${partner_id}&request_language_id=${getLangType()}`,
        method: "get"
    })
}

// 获取回收站促销商品列表
export const getIsDeleteSpuGoodsList = (page) => {
    return Axios.ajax({
        url: API.spuDiscount + "?is_discount=1&is_deleted=1&page=" + page,
        method: "get"
    })
}

// 删除回收站促销商品
export const deleteRealDestroySpuGoods = (data) => {
    return Axios.ajax({
        url: "spuDiscountRealDestroy",
        method: "post",
        tips: intl.get("delete.success"),
        data
    })
}

// 恢复回收站促销商品
export const RecoverSpuGoods = (spu_id) => {
    return Axios.ajax({
        url: "spuDiscountRecover?spu_discount_id=" + spu_id,
        method: "get",
        tips: intl.get("recover.success"),
    })
}


// 获取与促销商品详情
export const getSpuDiscountDetail = (id) => {
    return Axios.ajax({
        url: API.spuDiscount + "/" + id,
        method: "get"
    })
}
// 修改促销商品
export const putSpuDiscount = (data, id) => {
    return Axios.ajax({
        url: API.spuDiscount + "/" + id,
        method: "post",
        tips: intl.get("update.success"),
        data,
    })
}

// 删除促销商品
export const deleteSpuDiscount = (id) => {
    return Axios.ajax({
        url: API.spuDiscount + "/" + id,
        method: "delete",
        tips: intl.get("file.success"),
    })
}

// 新建优惠券
export const AddCoupon = (data) => {
    return Axios.ajax({
        url: API.coupon,
        method: "post",
        tips: intl.get("summit.success"),
        data
    })
}

// 获取优惠券列表
export const getCouponList = (page, id, url) => {
    return Axios.ajax({
        url: `${API.coupon}?partner_id=${id}&page=${page}${url}&request_language_id=${getLangType()}`,
        method: "get"
    })
}

// 获取领取优惠券列表
export const getUserCouponList = (page, url) => {
    return Axios.ajax({
        url: `${API.userCoupon}${url}&page=${page}&request_language_id=${getLangType()}`,
        method: "get"
    })
}

// 获取回收站优惠券列表
export const getIsDeleteCouponList = (page) => {
    return Axios.ajax({
        url: API.coupon + "?is_discount=1&is_deleted=1&page=" + page,
        method: "get"
    })
}

// 删除回收站优惠券
export const deleteRealDestroyCoupon = (data) => {
    return Axios.ajax({
        url: "couponRealDestroy",
        method: "post",
        tips: intl.get("delete.success"),
        data
    })
}

// 恢复回收站优惠券
export const RecoverCoupon = (id) => {
    return Axios.ajax({
        url: "couponRecover?coupon_id=" + id,
        method: "get",
        tips: intl.get("recover.success"),
    })
}


// 获取优惠券详情
export const getCouponDetail = (id) => {
    return Axios.ajax({
        url: API.coupon + "/" + id,
        method: "get"
    })
}
// 修改优惠券
export const putCoupon = (data, id) => {
    return Axios.ajax({
        url: API.coupon + "/" + id,
        method: "post",
        tips: intl.get("update.success"),
        data,
    })
}
// check优惠券
export const checkCoupon = (data) => {
    return Axios.ajax({
        url: API.userCouponCheck,
        method: "post",
        tips: intl.get("update.success"),
        data,
    })
}

// 删除优惠券
export const deleteCoupon = (id) => {
    return Axios.ajax({
        url: API.coupon + "/" + id,
        method: "delete",
        tips: intl.get("file.success"),
    })
}


// 新建商品
export const AddGoods = (data) => {
    return Axios.ajax({
        url: API.NewGood.spu,
        method: "post",
        tips: intl.get("summit.success"),
        data
    })
}

// 获取商品列表
export const getGoodsList = (num, url, search,partner_id) => {
    return Axios.ajax({
        url: API.NewGood.spu + API.page + num + API.NewGood.include2 + url + API.search + search + "&partner_id="+partner_id,
        method: "get"
    })
}
// 获取回收站商品列表
export const getIsDeleteGoodsList = (num, search) => {
    return Axios.ajax({
        url: API.NewGood.spu + API.page + num + API.NewGood.include2 + "&is_deleted=1" + + API.search + search,
        method: "get"
    })
}

// 获取无sku商品列表
export const getNoSkuGoodsList = (num, search) => {
    return Axios.ajax({
        url: API.NewGood.spu + API.page + num + API.NewGood.include2 + "&no_sku=1&no_annex_type=1&state=1" + API.search + search,
        method: "get"
    })
}
// 获取精选商品列表
export const getSelectedGoodsList = (num) => {
    return Axios.ajax({
        url: API.NewGood.spu + API.page + num + API.NewGood.include2 + API.NewGood.recommend,
        method: "get"
    })
}

// 获取商品详情
export const getGoodsDetail = (id) => {
    return Axios.ajax({
        // url: API.NewGood.spu + "/" + id + API.NewGood.include + '&' + `request_language_id=${getLangType()}`,
        url: API.NewGood.spu + "/" + id + API.NewGood.include,
        method: "get"
    })
}


// 修改商品
export const putGoods = (data, id) => {
    return Axios.ajax({
        url: API.NewGood.spu + API.NewGood.UPDATE + "/" + id,
        method: "post",
        tips: intl.get("update.success"),
        data,
    })
}

// 删除商品
export const deleteGoods = (id) => {
    return Axios.ajax({
        url: API.NewGood.spu + "/" + id,
        method: "delete",
        tips: intl.get("file.success"),
    })
}

// 删除回收站商品
export const deleteSpuRealDestroyGoods = (data) => {
    return Axios.ajax({
        url: "spuRealDestroy",
        method: "post",
        tips: intl.get("delete.success"),
        data
    })
}
// 恢复回收站商品
export const SpuRecoverGoods = (spu_id) => {
    return Axios.ajax({
        url: "spuRecover?spu_id=" + spu_id,
        method: "get",
        tips: intl.get("recover.success"),
    })
}

// 新建tag
export const AddTag = (data) => {
    return Axios.ajax({
        url: API.Tag,
        method: "post",
        tips: intl.get("summit.success"),
        data
    })
}
// 获取tag列表
export const getTagList = () => {
    return Axios.ajax({
        url: `${API.Tag}?request_language_id=${getLangType()}`,
        method: "get"
    })
}

// 修改tag
export const putTag = (data, id) => {
    return Axios.ajax({
        url: API.Tag + "/" + id,
        method: "put",
        tips: intl.get("update.success"),
        data,
    })
}

// 删除tag
export const deleteTag = (id) => {
    return Axios.ajax({
        url: API.Tag + "/" + id,
        method: "delete",
        tips: intl.get("delete.success"),
    })
}

// 获取订单列表
export const getOrderList = (spu_discount_id, data, page) => {
    return Axios.ajax({
        url: `${API.BASEURL}${API.order.order}${API.order.includeUser}&is_pay=1&spu_discount_id=${spu_discount_id}${data}&page=${page}`,
        method: "get",
    })
}
// 获取订单列表
export const getRefundOrderList = (spu_discount_id, data, page) => {
    return Axios.ajax({
        url: `${API.BASEURL}${API.order.order}${API.order.includeUser}&spu_discount_id=${spu_discount_id}${data}&page=${page}`,
        method: "get",
    })
}
// 获取订单详情
export const getOrderDetail = (id) => {
    return Axios.ajax({
        url: `${API.BASEURL}${API.order.order}/${id}${API.order.include}&request_language_id=${getLangType()}`,
        method: "get"
    })
}

// 修改订单
export const putOrder = (id, data) => {
    return Axios.ajax({
        url: API.BASEURL + API.order.order + "/" + id,
        method: "put",
        tips: intl.get("update.success"),
        data,
    })
}
// 确认订单
export const confirmOrderOrder = (data) => {
    return Axios.ajax({
        url: API.BASEURL + API.order.confirmOrder,
        method: "put",
        tips: intl.get("update.success"),
        data,
    })
}

// 修改订单状态
export const putOrderStatus = (id, data) => {
    return Axios.ajax({
        url: API.BASEURL + API.order.orderReceiving + id,
        method: "put",
        tips: intl.get("update.success"),
        data,
    })
}


// 获取密钥
export const GetOrderExportSecret = () => {
    return Axios.ajax({
        url: `${API.order.orderExportSecret}`,
        method: "get",
    })
}

// 导出订单
export const ExportOrderList = (url) => {
    return Axios.ajax({
        url: `${API.order.exportOrder}${url}`,
        method: "get",
        responseType: "arraybuffer"
    })
}

// 获取排队订单列表
export const getOrderLine = () => {
    return Axios.ajax({
        url: `${API.order.orderLine}?request_language_id=${getLangType()}`,
        method: "get"
    })
}

// 删除订单
export const deleteOrder = (id) => {
    return Axios.ajax({
        url: API.order.order + "/" + id,
        method: "delete"
    })
}


// 新建打包费
export const AddPackCharge = (data) => {
    return Axios.ajax({
        url: API.partnerPackCharge,
        method: "post",
        tips: intl.get("summit.success"),
        data
    })
}
// 获取打包费列表
export const getPackChargeList = () => {
    return Axios.ajax({
        url: `${API.partnerPackCharge}?request_language_id=${getLangType()}`,
        method: "get"
    })
}

// 修改打包费
export const putPackCharge = (data, id) => {
    return Axios.ajax({
        url: API.partnerPackCharge + "/" + id,
        method: "put",
        tips: intl.get("update.success"),
        data,
    })
}

// 删除打包费
export const deletePackCharge = (id) => {
    return Axios.ajax({
        url: API.partnerPackCharge + "/" + id,
        method: "delete",
        tips: intl.get("delete.success"),
    })
}


export const subscribePlugIn = (id, is_trial) => {
    return Axios.ajax({
        url: `/settlement`,
        method: "post",
        tips: intl.get("summit.success"),
        data: {
            include: 'partner,partnerSubscription.prod',
            prod_type: 'addon',
            prod_id: id,
            service_type: 'month',
            is_trial
        }
    })
}


export const subscribePackageNew = (row, key, is_trial) => {
    return Axios.ajax({
        url: `/settlement`,
        method: "post",
        tips: intl.get("summit.success"),
        data: {
            include: 'partner,partnerSubscription.prod',
            prod_type: 'package',
            prod_id: row.id,
            service_type: key,
            is_trial
        }
    })
}
// 套餐列表
export const getPackageList = () => {
    return Axios.ajax({
        url: `${API.package}?request_language_id=${getLangType()}`,
        method: "get"
    })
}
// 套餐详情
export const getPackageDetail = (id) => {
    return Axios.ajax({
        url: `${API.package}/${id}?request_language_id=${getLangType()}`,
        method: "get"
    })
}

// 套餐日志列表
export const getPackageLogList = () => {
    return Axios.ajax({
        url: `${API.packageLog}?request_language_id=${getLangType()}`,
        method: "get"
    })
}

//申请套餐
export const ApplyPackage = (data) => {
    return Axios.ajax({
        url: API.partnerApplyPackage,
        method: "post",
        tips: intl.get("summit.success"),
        data
    })
}
//订阅套餐
export const subscribePackage = (id, type) => {
    return Axios.ajax({
        url: "package/subscription/" + id,
        method: "post",
        tips: intl.get("summit.success"),
        data: { type }
    })
}

//开通套餐
export const openScribe = (id) => {
    return Axios.ajax({
        url: "package/setup/" + id,
        method: "post",
        tips: intl.get("summit.success"),
        data: {}
    })
}

//
export const getBindInfo = () => {
    return Axios.ajax({
        url: `${API.card}?request_language_id=${getLangType()}`,
        method: "get"
    })
}


export const getStripeKey = () => {
    return Axios.ajax({
        url: `cardKey?request_language_id=${getLangType()}`,
        method: "get"
    })
}

export const bindToHandle = (data) => {
    return Axios.ajax({
        url: API.card,
        method: "post",
        tips: intl.get("summit.success"),
        data
    })
}


export const unBindPay = () => {
    return Axios.ajax({
        url: API.card,
        method: "delete",
        tips: intl.get("delete.success"),
    })
}

export const manualPrintOrder = (id, data) => {
    return Axios.ajax({
        url: API.manualPrintOrder + "/" + id,
        method: "post",
        tips: intl.get("summit.success"),
        data
    })
}


// 获取打印机列表
export const getPrinterList = () => {
    return Axios.ajax({
        url: API.printer,
        method: "get"
    })
}
// 获取打印机详情
export const getPrinterDetail = (id) => {
    return Axios.ajax({
        url: API.printer + "/" + id,
        method: "get"
    })
}

// 修改打印机
export const UpdatePrinter = (id, data) => {
    return Axios.ajax({
        url: API.printer + "/" + id,
        method: "put",
        tips: intl.get("update.success"),
        data
    })
}

// 获取打印机状态
export const getPrinterStatus = (sn) => {
    return Axios.ajax({
        url: API.queryPrinterStatus + sn,
        method: "get"
    })
}