import React from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Form, Switch, Button } from 'antd';
import { getPrinterList, getPrinterStatus } from "../../../action/goodAction";
import { getPartner, UpdateShopMessage } from "../../../action/authAction";
import { UpdatePrinter } from "../../../action/goodAction";
import intl from 'react-intl-universal';
let currency_symbol = sessionStorage.getItem("currency_symbol")

class PrinterList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [], is_auto_print: true };
        this.columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                width: '5%',
                align: "center",
            },


            {
                title: intl.get('nav.setting.printerBrand'),
                dataIndex: ["printerCategory", "brand"],
                width: '10%',
                align: "center",
            },
            {
                title: intl.get('nav.setting.printerType'),
                dataIndex: ["printerCategory", "model"],
                width: '10%',
                align: "center",
            },
            // {
            //     title: intl.get('nav.setting.printerName'),
            //     dataIndex: ["printerCategory", "name"],
            //     width: '10%',
            //     align: "center",
            // },
            {
                title: intl.get('nav.setting.printerDefaultName'),
                dataIndex: 'name',
                width: '10%',
                align: "center",
            },
            {
                title: intl.get('pack.form.price'),
                dataIndex: ["printerCategory", "price"],
                width: '5%',
                align: "center",
                render: (record) => {
                    return <span>{currency_symbol}{record}</span>
                }
            },
            // {
            //     title: intl.get('nav.setting.printerSn'),
            //     dataIndex: "sn",
            //     width: '10%',
            //     align: "center",
            // },
            {
                title: intl.get('nav.setting.automaticPrinting'),
                width: '10%',
                align: "center",
                render: (record) => {
                    return (
                        <Switch
                            checked={record.is_auto_print === 1}
                            onChange={() => {
                                UpdatePrinter(record.id, { "is_auto_print": record.is_auto_print === 1 ? "0" : "1" }).then(res => {
                                    this.HandleGetData()
                                })
                            }} />
                    )
                }
            },
            {
                title: intl.get('subscription.status'),
                dataIndex: "status",
                width: '10%',
                align: "center",
            },
            // {
            //     title: intl.get('nav.setting.printerAddTime'),
            //     dataIndex: "created_at",
            //     width: '10%',
            //     align: "center",
            //     render: (record) => {
            //         return record.date.substring(0, record.date.length - 7)
            //     }
            // },
            {
                title: intl.get('seat.table.operation'),
                dataIndex: 'operation',
                width: '10%',
                align: "center",
                render: (text, record) => {
                    return <Button style={{ marginRight: "10px" }} onClick={() => {
                        this.props.history.push("/EditPrinter/" + record.id)
                    }}>
                        {intl.get('seat.table.btn.edit')}
                    </Button>
                },
            },

        ];
    }
    componentDidMount() {
        this.HandleGetData()
    }

    async HandleGetData() {
        let res = await getPrinterList()
        let shopData = await getPartner()
        let data = res.data.data
        for (let i in data) {
            let statusData = await getPrinterStatus(data[i].sn)
            let status = statusData.data.data
            data[i].status = status
        }
        this.setState({
            data: data,
            is_auto_print: shopData.data.is_auto_print === 1 ? true : false,
        })
    }

    render() {
        let { is_auto_print } = this.state
        return (
            <div>
                {/* <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                    <div />
                    <div>
                        <span>{intl.get('setup.form.isAutoPrint')}：</span>
                        <Switch checked={is_auto_print} onChange={() => {
                            this.setState({
                                is_auto_print: !is_auto_print
                            }, () => {
                                UpdateShopMessage({ "is_auto_print": !is_auto_print ? "1" : "0" }).then(res => {
                                    this.HandleGetData()
                                })
                            })
                        }} />
                    </div>
                </div> */}

                <Table
                    rowKey={record => record.id}
                    // bordered
                    dataSource={this.state.data}
                    columns={this.columns}
                    pagination={{
                        hideOnSinglePage: true
                    }}
                    className="waperBox"
                />
            </div>


        );
    }
}

export default withRouter(Form.create()(PrinterList))

