import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, InputNumber, Button, Select, Radio, DatePicker, message, Switch, Upload, Icon, Tooltip, Checkbox, Popconfirm } from 'antd';
import moment from 'moment';
import { getSpuDiscountDetail, putSpuDiscount, getNoSkuGoodsList, getGoodsDetail } from "../../action/goodAction";
import { GetConfigSet, getPartner, getLangList } from "../../action/authAction";
import { getEditorContent } from "../../action/AdAction";
import intl from 'react-intl-universal';
import BraftEditor from 'braft-editor'
import { getLangType } from "../../util/function";
const { Option } = Select;
const { RangePicker } = DatePicker;

let currency_symbol = sessionStorage.getItem("currency_symbol")

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result, img));
    reader.readAsDataURL(img);
}
function beforeUpload(file) {
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
        message.destroy()
        message.error(intl.get('file.size.placehorder'));
    }
    return isLt1M;
}

class EditPromotionalGoods extends React.Component {
    state = {
        name: "",
        SearchData: [],
        spu_id: "",
        discount_type: "fixed",
        price_type: "rrp",
        date: "",
        data: "",
        imageUrl: "",
        logo_file: "",
        min_pay_fee_on_online: "",
        is_cut_show: false,
        one_fixed: "1",
        default_one_fixed: "1",
        one_percentage: "1",
        default_one_percentage: "1",
        one_people_money_price_type: "1",
        one_discount_price: "0",
        two_fixed: "1",
        default_two_fixed: "1",
        two_percentage: "1",
        default_two_percentage: "1",
        two_people_money_price_type: "",
        two_discount_price: "0",
        three_fixed: "1",
        default_three_fixed: "1",
        three_percentage: "1",
        default_three_percentage: "1",
        three_people_money_price_type: "",
        three_discount_price: "0",
        choose_people: [],
        is_show_spu_many_help: false,
        is_show: false,
        editorState: "",
        goods: "",
        disable_language: true,
        is_disable_language: false,
        is_update_activity_service_rate: false,
        typeData: [
            // {
            //     id: 0,
            //     name: intl.get("nav.goodsManagement.promotionalgoods.inside")
            // },
            {
                id: 1,
                name: intl.get("nav.goodsManagement.promotionalgoods.external")
            },
            // {
            //     id: 2,
            //     name: intl.get("nav.goodsManagement.promotionalgoods.mysterious")
            // }
        ],
    };

    async componentDidMount() {
        const id = this.props.location.pathname.substring(22)

        let configData = await GetConfigSet()
        let PartnerData = await getPartner()
        let language = await getLangList()
        let languageData = language.data.data
        let languageList = []
        for (let i in languageData) {
            if (languageData[i].is_use === 1) {
                languageList.push(languageData[i])
            }
        }
        getSpuDiscountDetail(id).then(res => {
            this.HandleGetSpuProduct(res.data.spu_id)
            this.ChangeEditorData(res)
            let goods = res.data.spu
            let discount_price_save = ""
            if (res.data.discount_type === "fixed") {
                discount_price_save = goods && this.accSub(goods.price, res.data.discount_on_fixed || 0)
            } else {
                discount_price_save = goods && parseFloat(this.accMul(parseFloat(goods.price), parseFloat(res.data.discount_on_percentage) || 1)).toFixed(2)
            }
            let people_money = res.data.spuManyHelp && res.data.spuManyHelp.people_money
            let choose_people = []
            for (let i in people_money) {
                choose_people.push(people_money[i].people)
                if (people_money[i].people === "1") {
                    this.setState({
                        one_fixed: people_money[i].price_type === "1" ? people_money[i].price : this.accSub(discount_price_save, this.accMul(parseFloat(discount_price_save), parseFloat(people_money[i].price)).toFixed(2)),
                        default_one_fixed: people_money[i].price_type === "1" ? people_money[i].price : this.accSub(discount_price_save, this.accMul(parseFloat(discount_price_save), parseFloat(people_money[i].price)).toFixed(2)),
                        one_percentage: people_money[i].price_type === "1" ? Math.ceil(this.accDiv(this.accSub(parseFloat(discount_price_save), parseFloat(people_money[i].price)), discount_price_save) * 100) : parseFloat(people_money[i].price) * 100,
                        default_one_percentage: people_money[i].price_type === "1" ? Math.ceil(this.accDiv(this.accSub(parseFloat(discount_price_save), parseFloat(people_money[i].price)), discount_price_save) * 100) : parseFloat(people_money[i].price) * 100,
                        one_people_money_price_type: people_money[i].price_type,
                        one_discount_price: people_money[i].price_type === "1" ? this.accSub(parseFloat(discount_price_save), parseFloat(people_money[i].price)) : this.accMul(parseFloat(discount_price_save), parseFloat(people_money[i].price)).toFixed(2)
                    })
                }
                if (people_money[i].people === "2") {
                    this.setState({
                        two_fixed: people_money[i].price_type === "1" ? people_money[i].price : this.accSub(discount_price_save, this.accMul(parseFloat(discount_price_save), parseFloat(people_money[i].price)).toFixed(2)),
                        default_two_fixed: people_money[i].price_type === "1" ? people_money[i].price : this.accSub(discount_price_save, this.accMul(parseFloat(discount_price_save), parseFloat(people_money[i].price)).toFixed(2)),
                        two_percentage: people_money[i].price_type === "1" ? Math.ceil(this.accDiv(this.accSub(parseFloat(discount_price_save), parseFloat(people_money[i].price)), discount_price_save) * 100) : parseFloat(people_money[i].price) * 100,
                        default_two_percentage: people_money[i].price_type === "1" ? Math.ceil(this.accDiv(this.accSub(parseFloat(discount_price_save), parseFloat(people_money[i].price)), discount_price_save) * 100) : parseFloat(people_money[i].price) * 100,
                        two_people_money_price_type: people_money[i].price_type,
                        two_discount_price: people_money[i].price_type === "1" ? this.accSub(parseFloat(discount_price_save), parseFloat(people_money[i].price)) : this.accMul(parseFloat(discount_price_save), parseFloat(people_money[i].price)).toFixed(2)
                    })
                }
                if (people_money[i].people === "3") {
                    this.setState({
                        three_fixed: people_money[i].price_type === "1" ? people_money[i].price : this.accSub(discount_price_save, this.accMul(parseFloat(discount_price_save), parseFloat(people_money[i].price)).toFixed(2)),
                        default_three_fixed: people_money[i].price_type === "1" ? people_money[i].price : this.accSub(discount_price_save, this.accMul(parseFloat(discount_price_save), parseFloat(people_money[i].price)).toFixed(2)),
                        three_percentage: people_money[i].price_type === "1" ? Math.ceil(this.accDiv(this.accSub(parseFloat(discount_price_save), parseFloat(people_money[i].price)), discount_price_save) * 100) : parseFloat(people_money[i].price) * 100,
                        default_three_percentage: people_money[i].price_type === "1" ? Math.ceil(this.accDiv(this.accSub(parseFloat(discount_price_save), parseFloat(people_money[i].price)), discount_price_save) * 100) : parseFloat(people_money[i].price) * 100,
                        three_people_money_price_type: people_money[i].price_type,
                        three_discount_price: people_money[i].price_type === "1" ? this.accSub(parseFloat(discount_price_save), parseFloat(people_money[i].price)) : this.accMul(parseFloat(discount_price_save), parseFloat(people_money[i].price)).toFixed(2)
                    })
                }
            }
            this.setState({
                data: res.data,
                type: res.data.type,
                goods: res.data.spu,
                imageUrl: res.data.image,
                discount_type: res.data.discount_type,
                price_type: res.data.price_type,
                spu_id: res.data.spu_id,
                is_show: res.data.is_show,
                is_cut_show: res.data.spuManyHelp && res.data.spuManyHelp.state === 1 ? true : false,
                date: [moment(res.data.start_at, "DD-MM-YYYY HH:mm:ss"), moment(res.data.end_at, "DD-MM-YYYY HH:mm:ss")],
                min_pay_fee_on_online: configData.data.data.find(el => el.key === "min_pay_fee_on_online").value,
                is_show_spu_many_help: PartnerData.data.is_show_spu_many_help === 1,
                is_update_activity_service_rate: PartnerData.data.is_update_activity_service_rate == 1 ? true : false,
                disable_language: res.data.disable_language === 0,
                is_disable_language: languageList.length === 1 ? true : false,
                choose_people,
                editorState: BraftEditor.createEditorState(res.data.detail),

            })
        })
    }

    HandleGetSpuProduct = (id) => {
        getGoodsDetail(id).then(res => {
            this.setState({
                name: res.data.name
            })
        })
    }
    handleSubmit = (e) => {
        const id = this.props.location.pathname.substring(22)
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            let formData = new FormData()
            let { logo_file, is_show, is_cut_show, is_show_spu_many_help, one_fixed, one_percentage, two_fixed, two_percentage, three_fixed, three_percentage, one_people_money_price_type, two_people_money_price_type, three_people_money_price_type, choose_people, editorState } = this.state
            // let date = this.props.form.getFieldValue('date');
            // values.spu_id = spu_id
            // values.discount_type = discount_type
            // values.price_type = price_type
            values.detail = editorState.toHTML()
            values.is_show = is_show ? "1" : "0"
            values.disable_language = this.state.disable_language ? "0" : "1"
            // values.start_at = values.date ? moment(date[0]).format("YYYY-MM-DD") + " 00:00:00" : ""
            // values.end_at = values.date ? moment(date[1]).format("YYYY-MM-DD") + " 23:59:59" : ""
            if (values.discount_on_percentage) {
                values.discount_on_percentage = parseInt(values.discount_on_percentage) / 100
            }

            let people_money_item = [
                {
                    "people": "1",
                    "price": one_people_money_price_type === "1" ? one_fixed : one_percentage,
                    "price_type": one_people_money_price_type
                },
                {
                    "people": "2",
                    "price": two_people_money_price_type === "1" ? two_fixed : two_percentage,
                    "price_type": two_people_money_price_type
                },
                {
                    "people": "3",
                    "price": three_people_money_price_type === "1" ? three_fixed : three_percentage,
                    "price_type": three_people_money_price_type
                }
            ]

            let submit_people = []

            if (choose_people) {
                for (let i in choose_people) {
                    for (let k in people_money_item) {
                        if (parseInt(choose_people[i]) === parseInt(people_money_item[k].people)) {
                            submit_people.push(people_money_item[k])
                        }
                    }
                }
            }
            if (is_cut_show) {
                for (let i in submit_people) {
                    let sku = "spu_many_help[people_money][" + i + "]"
                    values[sku + "[people]"] = submit_people[i].people
                    values[sku + "[price]"] = submit_people[i].price_type === "0" ? parseInt(submit_people[i].price) / 100 : submit_people[i].price
                    values[sku + "[price_type]"] = submit_people[i].price_type
                }
            } else {
                values["spu_many_help[people_money]"] = ''
            }
            if (is_show_spu_many_help) {
                values["spu_many_help[state]"] = is_cut_show ? "1" : "0"
            }


            if (values.quota) {
                if (parseInt(values.quota) > parseInt(values.stock)) {
                    message.error(intl.get("nav.goodsManagement.promotionalgoods.quota_cannot_stock"))
                    return false
                }
            }
            if (values.quota && values.max_quantity) {
                if (parseInt(values.quota) < parseInt(values.max_quantity)) {
                    message.error(intl.get("nav.goodsManagement.promotionalgoods.maximum_cannot_limit"))
                    return false
                }
            }
            if (values.min_quantity && values.max_quantity) {
                if (parseInt(values.min_quantity) > parseInt(values.max_quantity)) {
                    message.error(intl.get("nav.goodsManagement.promotionalgoods.minimum_cannot_maximum"))
                    return false
                }
            }
            if (values.quota && values.min_quantity) {
                if (parseInt(values.quota) < parseInt(values.min_quantity)) {
                    message.error(intl.get("nav.goodsManagement.promotionalgoods.minimum_cannot_limit"))
                    return false
                }
            }

            if (!err) {
                // if (values.spu_id) {
                delete values.discount_on_fixed
                delete values.discount_on_percentage
                delete values.date
                delete values.stock
                delete values.good_image
                delete values.type


                // if (Number(values.quota) >= 1) {
                for (let i in values) {
                    // if (values[i]) {
                    formData.append(i, values[i])
                    // }
                }
                logo_file && formData.append("image_file", logo_file)
                putSpuDiscount(formData, id).then(res => {
                    this.props.history.push("/promotionalgoodsList")
                })

            } else {
                message.error(intl.get('nav.goodsManagement.promotionalgoods.selectError'))
            }
            // }


        });
    };
    onChange = e => {
        this.setState({
            value: e
        });
    };
    onSelectSearch = (val) => {
        if (val.length > 1) {
            getNoSkuGoodsList(1, val).then(res => {
                this.setState({
                    SearchData: res.data.data
                })
            })
        }

    }
    handleChange = info => {
        let isOK = beforeUpload(info.file)
        if (isOK) {
            if (info.file) {
                getBase64(info.file, (imageUrl, img) =>
                    this.setState({
                        imageUrl,
                        logo_file: img
                    }),
                );
            }
        }
    };

    checkPsd(rule, value, callback) {
        let price = this.state.data.spu.price;
        let splitData = value.toString().split(".")
        let num = splitData.length === 1 ? 1 : splitData[1].length
        if (price && parseInt(price) < parseInt(value)) {
            callback(new Error(intl.get("nav.goodsManagement.promotionalgoods.discountPrice")));
        } else if (this.accSub(price, value) < parseInt(this.state.min_pay_fee_on_online)) {
            callback(new Error(intl.get("nav.goodsManagement.promotionalgoods.minimumOrder")));
        } else if (num > 2) {
            callback(new Error(intl.get("goods.list.goodsAnnexType.goodsAnnex_price.error")));
        } else {
            callback();
        }
    }
    checkPsd2(rule, value, callback) {
        let price = this.state.data.spu.price;
        if (this.accMul(price, value) / 100 < parseInt(this.state.min_pay_fee_on_online)) {
            callback(new Error(intl.get("nav.goodsManagement.promotionalgoods.minimumOrder")));
        } else {
            callback();
        }
    }
    checkStock(rule, value, callback) {
        let stock = this.state.data.stock;
        if (stock && parseInt(stock) > parseInt(value)) {
            callback(new Error(intl.get("nav.goodsManagement.promotionalgoods.stock_error")));
        } else {
            callback();
        }
    }
    // 除以
    accDiv(arg1, arg2) {
        let t1 = 0,
            t2 = 0,
            r1, r2;
        try {
            t1 = arg1.toString().split(".")[1].length
        } catch (e) { }
        try {
            t2 = arg2.toString().split(".")[1].length
        } catch (e) { };
        r1 = Number(arg1.toString().replace(".", ""))
        r2 = Number(arg2.toString().replace(".", ""))
        return (r1 / r2) * Math.pow(10, t2 - t1);
    }
    // 乘以
    accMul(arg1, arg2) {

        let m = 0,
            s1 = arg1.toString(),
            s2 = arg2.toString();
        try {
            m += s1.split(".")[1].length
        } catch (e) { }
        try {
            m += s2.split(".")[1].length
        } catch (e) { }
        return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)
    }
    // 减
    accSub(arg1, arg2) {
        let r1, r2, m, n;
        try {
            r1 = arg1.toString().split(".")[1].length
        } catch (e) {
            r1 = 0
        }
        try {
            r2 = arg2.toString().split(".")[1].length
        } catch (e) {
            r2 = 0
        }
        m = Math.pow(10, Math.max(r1, r2));
        //动态控制精度长度 
        n = (r1 >= r2) ? r1 : r2;
        return ((arg1 * m - arg2 * m) / m).toFixed(n);
    }

    ChangeEditorData = (data) => {
        getEditorContent(data.data.detail).then(res => {
            this.setState({
                editorState: BraftEditor.createEditorState(res.data)
            })
        }).catch(error => {
        })
    }

    render() {
        const { SearchData, discount_type, data, type, goods, date, price_type, imageUrl, is_cut_show, is_show, one_fixed, one_percentage, two_fixed, two_percentage, three_fixed, three_percentage, one_people_money_price_type, two_people_money_price_type, three_people_money_price_type, one_discount_price, two_discount_price, three_discount_price, choose_people, is_show_spu_many_help, editorState, typeData, disable_language, is_disable_language, is_update_activity_service_rate } = this.state;
        const { getFieldDecorator } = this.props.form;
        let discount_price_save = ""
        if (discount_type === "fixed") {
            discount_price_save = goods && this.accSub(goods.price, this.props.form.getFieldValue('discount_on_fixed') || 0)
        } else {
            discount_price_save = goods && parseFloat(this.accMul(parseFloat(goods.price), parseFloat(this.props.form.getFieldValue('discount_on_percentage')) / 100 || 1)).toFixed(2)
        }
        const formItemLayout = {
            labelCol: {
                xs: { span: 8 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 16 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };
        const uploadButton = (
            <div>
                <Icon type='plus' />
                <div className="ant-upload-text">{intl.get('file.btn.name')}</div>
            </div>
        );
        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView" style={{ minWidth: "1000px" }}>
                <Form.Item
                    label="ADID"
                >
                    {getFieldDecorator('adid', {
                        initialValue: data.adid
                    })(<Input disabled />)}
                </Form.Item>
                <Form.Item
                    label={intl.get('nav.goodsManagement.promotionalgoods_name')}
                >
                    {getFieldDecorator('name', {
                        rules: [{ required: true, message: intl.get("nav.goodsManagement.promotionalgoods_name_error") }],
                        initialValue: data.name
                    })(<Input />)}
                </Form.Item>

                <Form.Item
                    label={<span>
                        {intl.get('nav.goodsManagement.promotionalgoods.goodsImg')}
                        <Tooltip title={intl.get("nav.goodsManagement.goods.prompt")}>
                            <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                        </Tooltip>
                    </span>}
                >
                    {getFieldDecorator('good_image', {
                        rules: [{ required: true }],
                        initialValue: "1"
                    })(
                        <Upload
                            name="avatar"
                            accept=".jpg,.png"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            customRequest={this.handleChange}
                        >
                            {!!imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                        </Upload>
                    )}
                </Form.Item>
                {
                    this.state.name &&
                    <Form.Item
                        label={<span>{intl.get('ad.form.selectgoods')}</span>}
                    >
                        <Select
                            disabled
                            showSearch
                            defaultValue={this.state.name}
                            placeholder={intl.get('ad.form.selectgoods')}
                            defaultActiveFirstOption={false}
                            showArrow={false}
                            filterOption={false}
                            onSearch={this.onSelectSearch}
                            onChange={(e, value) => {
                                let data = this.state.data
                                data.discount_on_fixed = 0
                                data.discount_on_percentage = 100
                                this.setState({
                                    spu_id: value.props.value,
                                    goods: value.props.item,
                                    data,
                                    is_cut_show: false
                                })
                            }}
                            notFoundContent={null}
                        >
                            {
                                SearchData.length > 0 && SearchData.map((item, index) => {
                                    return <Option key={index} value={item.id} item={item}>{item.name}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                }



                <Form.Item
                    label={intl.get('nav.goodsManagement.promotionalgoods.discount_type')}
                >
                    <Radio.Group
                        disabled
                        onChange={(e) => {
                            this.setState({
                                discount_type: e.target.value
                            })
                        }}
                        value={discount_type}>
                        <Radio value={"fixed"}>{intl.get("nav.goodsManagement.promotionalgoods.quota_discount")}</Radio>
                        <Radio value={"percentage"}>{intl.get("nav.goodsManagement.promotionalgoods.percent_discount")}</Radio>
                    </Radio.Group>
                </Form.Item>
                {
                    discount_type === "fixed" ?
                        <Form.Item
                            label={intl.get('nav.goodsManagement.promotionalgoods.discount_on_fixed')}
                        >
                            {getFieldDecorator('discount_on_fixed', {
                                rules: [{ required: true }, { validator: (rule, value, callback) => { this.checkPsd(rule, value, callback) } }],
                                initialValue: data.discount_on_fixed
                            })(<InputNumber
                                disabled
                                formatter={value => `${currency_symbol}${value}`}
                                parser={value => value.replace(currency_symbol, '')}
                                style={{ width: "200px" }} onChange={() => this.setState({ is_cut_show: false })} />)}
                        </Form.Item> :
                        <Form.Item
                            label={intl.get('nav.goodsManagement.promotionalgoods.discount_on_percentage')}
                        >
                            {getFieldDecorator('discount_on_percentage', {
                                rules: [{ required: true, pattern: new RegExp(/^[1-99]\d*$/, "g"), message: intl.get("nav.goodsManagement.promotionalgoods.enter_integer") }, { validator: (rule, value, callback) => { this.checkPsd2(rule, value, callback) } }],
                                initialValue: data.discount_on_percentage * 100
                            })(<InputNumber
                                disabled
                                formatter={value => `${value}%`}
                                parser={value => value.replace('%', '')} min={1} max={100} style={{ width: "200px" }} onChange={() => this.setState({ is_cut_show: false })} />)}
                        </Form.Item>
                }

                <Form.Item
                    label={intl.get('nav.goodsManagement.promotionalgoods.price_type')}
                >
                    <Radio.Group
                        disabled
                        onChange={(e) => {
                            this.setState({
                                price_type: e.target.value
                            })
                        }}
                        value={price_type}
                        style={{ marginTop: "10px" }}
                    >
                        {
                            goods && goods.rrp && parseInt(goods.rrp) > 0 &&
                            <Radio style={{ display: "block", marginBottom: "10px" }} value={"rrp"}>
                                RRP：${goods && goods.rrp}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {
                                    discount_type === "fixed" ? "-" + currency_symbol + `${goods ? (parseFloat(goods.rrp) - this.accSub(goods.price, parseFloat(this.props.form.getFieldValue('discount_on_fixed')) || 0)).toFixed(2) : 0}` : parseFloat(goods.rrp) === 0 ? "0%" :
                                        `${goods && goods.price && (this.accDiv(this.accMul(goods.price, this.props.form.getFieldValue('discount_on_percentage') || 0), parseFloat(goods.rrp))).toFixed(2)}` + "%"
                                }
                                &nbsp; {intl.get("nav.goodsManagement.promotionalgoods.displayOnly")}
                            </Radio>
                        }
                        {
                            goods && goods.rrp && parseInt(goods.rrp) > 0 ?
                                <Radio value={"price"} >
                                    {intl.get("goods.list.price")}：{currency_symbol}{goods && goods.price}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {
                                        discount_type === "fixed" ? "-" + currency_symbol + `${this.props.form.getFieldValue('discount_on_fixed') || 0}` :
                                            `${parseFloat(this.props.form.getFieldValue('discount_on_percentage')) || 100}` + "%"
                                    }
                                </Radio> :
                                <span>
                                    {intl.get("goods.list.price")}：{currency_symbol}{goods && goods.price}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {
                                        discount_type === "fixed" ? "-" + currency_symbol + `${this.props.form.getFieldValue('discount_on_fixed') || 0}` :
                                            `${parseFloat(this.props.form.getFieldValue('discount_on_percentage')) || 100}` + "%"
                                    }
                                </span>
                        }
                    </Radio.Group>
                </Form.Item >
                <Form.Item
                    label={intl.get("nav.goodsManagement.promotionalgoods.discount_price")}
                >
                    <span>
                        {currency_symbol}{
                            (goods && goods.rrp) || (goods ? goods.price : 0) ?
                                discount_type === "fixed" ?
                                    goods && this.accSub(goods.price, this.props.form.getFieldValue('discount_on_fixed')) || 0 :
                                    goods && (parseFloat(this.accMul(parseFloat(goods.price), parseFloat(this.props.form.getFieldValue('discount_on_percentage')) || 1)) / 100).toFixed(2) : 0
                        }
                    </span>
                </Form.Item>
                {
                    is_show_spu_many_help &&
                    <Form.Item
                        label={intl.get('nav.goodsManagement.openCutGoodsType')}
                    >
                        <Switch checked={is_cut_show} onChange={() => {
                            this.setState({
                                is_cut_show: !is_cut_show,
                                choose_people: ["1"],
                                one_fixed: parseFloat(discount_price_save) - 1,
                                one_percentage: Math.ceil((1 / parseFloat(discount_price_save) * 100)),
                                one_discount_price: 1
                            })
                        }} />
                    </Form.Item>
                }

                {
                    is_cut_show &&
                    <Form.Item
                        label={intl.get('nav.goodsManagement.cutgoods.BargainingNumber')}
                    >
                        <Checkbox.Group style={{ width: '100%' }}
                            value={choose_people && choose_people}
                            onChange={(e) => {
                                if (e[0] !== "1") {
                                    message.error(intl.get("nav.goodsManagement.promotionalgoods.1_person_required"))
                                    return false
                                }
                                if (e.length === 2 && e[1] !== "2") {
                                    message.error(intl.get("nav.goodsManagement.promotionalgoods.2_person_required"))
                                    return false
                                }
                                this.setState({
                                    choose_people: e,
                                })
                            }}>
                            <div style={{ display: "flex", alignItems: "center", borderBottom: "1px solid #d9d9d9", marginLeft: "8px" }}>
                                <Checkbox value="1" />
                                <div style={{ width: "120%", height: "100px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <span style={{ marginLeft: "10px" }}>1</span>
                                    <Radio.Group
                                        onChange={(e) => {
                                            this.setState({
                                                one_people_money_price_type: e.target.value,
                                                one_discount_price: e.target.value === "0" ?
                                                    this.accMul(parseFloat(discount_price_save), parseFloat(one_percentage) / 100).toFixed(2) : this.accSub(parseFloat(discount_price_save), parseFloat(one_fixed))
                                            })
                                        }}
                                        value={one_people_money_price_type}
                                    >
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <Radio value={"1"} style={{ marginBottom: "10px" }}>
                                                <span style={{ width: "200px", marginRight: "45px" }}>fixed: </span>
                                                <Input
                                                    prefix={currency_symbol}
                                                    disabled={one_people_money_price_type === "0"}
                                                    style={{ width: "100px" }}
                                                    value={one_fixed}
                                                    onBlur={(e) => {
                                                        let price = this.accSub(parseFloat(discount_price_save), parseFloat(e.target.value))
                                                        if (price < 1) {
                                                            this.setState({
                                                                one_fixed: this.state.default_one_fixed
                                                            })
                                                            message.error(intl.get("nav.goodsManagement.promotionalgoods.price_less_than_1"))
                                                            return false
                                                        }
                                                        this.setState({
                                                            one_discount_price: price,
                                                            default_one_fixed: one_fixed
                                                        })
                                                    }}
                                                    onChange={(e) => {
                                                        e.persist()
                                                        let value = e.target.value.toString().match(/^\d+(?:\.\d{0,2})?/)
                                                        if (value) {
                                                            this.setState({
                                                                one_fixed: value[0]
                                                            })
                                                        } else {
                                                            this.setState({
                                                                one_fixed: ""
                                                            })
                                                        }

                                                    }}
                                                />
                                            </Radio>
                                            <Radio value={"0"}>
                                                <span style={{ width: "200px", marginRight: "10px" }}>percentage:</span>
                                                <InputNumber
                                                    formatter={value => `${value}%`}
                                                    style={{ width: "100px" }}
                                                    disabled={one_people_money_price_type === "1"}
                                                    min={0}
                                                    max={100}
                                                    value={one_percentage}
                                                    onBlur={(e) => {
                                                        let price = this.accDiv(this.accMul(parseFloat(discount_price_save), parseFloat(e.target.value) || 0), 100).toFixed(2)
                                                        if (parseFloat(price) < 1) {
                                                            this.setState({
                                                                one_percentage: this.state.default_one_percentage
                                                            })
                                                            message.error(intl.get("nav.goodsManagement.promotionalgoods.price_less_than_1"))
                                                            return false
                                                        }
                                                        this.setState({
                                                            one_discount_price: price,
                                                            default_one_percentage: one_percentage
                                                        })
                                                    }}
                                                    onChange={(e) => {
                                                        if ((/(^[1-99]\d*$)/.test(e))) {
                                                            this.setState({
                                                                one_percentage: e
                                                            })
                                                        }

                                                    }}
                                                />
                                            </Radio>
                                        </div>
                                    </Radio.Group>
                                    <div style={{ width: "150px", textAlign: "right" }}>
                                        <span>{intl.get("nav.goodsManagement.promotionalgoods.discount_price")}</span>
                                        <span>{currency_symbol}{one_discount_price}</span>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", borderBottom: "1px solid #d9d9d9" }}>
                                <Checkbox
                                    value="2"
                                    onChange={(e) => {
                                        if (choose_people.length === 1 && e.target.checked) {
                                            this.setState({
                                                two_fixed: parseFloat(discount_price_save) - 1,
                                                two_percentage: Math.ceil((1 / parseFloat(discount_price_save) * 100)),
                                                two_discount_price: 1,
                                                two_people_money_price_type: "1"
                                            })
                                        } else if (choose_people.length === 2) {
                                            this.setState({
                                                two_people_money_price_type: ""
                                            })
                                        } else {

                                        }
                                    }}
                                />

                                <div style={{ width: "120%", height: "100px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <span style={{ marginLeft: "10px" }}>2</span>
                                    <Radio.Group
                                        onChange={(e) => {
                                            this.setState({
                                                two_people_money_price_type: e.target.value,
                                                two_discount_price: e.target.value === "0" ?
                                                    this.accMul(parseFloat(discount_price_save), parseFloat(two_percentage) / 100).toFixed(2) : this.accSub(parseFloat(discount_price_save), parseFloat(two_fixed))
                                            })
                                        }}
                                        value={two_people_money_price_type}
                                    >
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <Radio value={"1"} style={{ marginBottom: "10px" }}>
                                                <span style={{ width: "200px", marginRight: "45px" }}>fixed: </span>
                                                <Input
                                                    prefix={currency_symbol}
                                                    disabled={!(two_people_money_price_type === "1") || !choose_people[1] || !(choose_people[1] === "2")}
                                                    value={two_fixed}
                                                    style={{ width: "100px" }}
                                                    onBlur={(e) => {
                                                        let price = this.accSub(parseFloat(discount_price_save), parseFloat(e.target.value))
                                                        if (price < 1) {
                                                            this.setState({
                                                                two_fixed: this.state.default_two_fixed
                                                            })
                                                            message.error(intl.get("nav.goodsManagement.promotionalgoods.price_less_than_1"))
                                                            return false
                                                        }
                                                        if (parseFloat(price) >= parseFloat(one_discount_price)) {
                                                            this.setState({
                                                                two_fixed: this.state.default_two_fixed
                                                            })
                                                            message.error(intl.get("nav.goodsManagement.promotionalgoods.two_people_cannot_equal_one_person"))
                                                            return false
                                                        }
                                                        this.setState({
                                                            two_discount_price: price,
                                                            default_two_fixed: two_fixed
                                                        })
                                                    }}
                                                    onChange={(e) => {
                                                        e.persist()
                                                        let value = e.target.value.toString().match(/^\d+(?:\.\d{0,2})?/)
                                                        if (value) {
                                                            this.setState({
                                                                two_fixed: value[0]
                                                            })
                                                        } else {
                                                            this.setState({
                                                                two_fixed: ""
                                                            })
                                                        }
                                                    }}
                                                />
                                            </Radio>
                                            <Radio value={"0"}>
                                                <span style={{ width: "200px", marginRight: "10px" }}>percentage:</span>
                                                <InputNumber
                                                    formatter={value => `${value}%`}
                                                    style={{ width: "100px" }}
                                                    disabled={!(two_people_money_price_type === "0") || !choose_people[1] || !(choose_people[1] === "2")}
                                                    min={1}
                                                    max={100}
                                                    value={two_percentage}
                                                    onBlur={(e) => {
                                                        let price = this.accDiv(this.accMul(parseFloat(discount_price_save), parseFloat(e.target.value) || 0), 100).toFixed(2)
                                                        if (parseFloat(price) < 1) {
                                                            this.setState({
                                                                two_percentage: this.state.default_two_percentage
                                                            })
                                                            message.error(intl.get("nav.goodsManagement.promotionalgoods.price_less_than_1"))
                                                            return false
                                                        }
                                                        if (parseFloat(price) >= parseFloat(one_discount_price)) {
                                                            this.setState({
                                                                two_percentage: this.state.default_two_percentage
                                                            })
                                                            message.error(intl.get("nav.goodsManagement.promotionalgoods.two_people_cannot_equal_one_person"))
                                                            return false
                                                        }
                                                        this.setState({
                                                            two_discount_price: price,
                                                            default_two_percentage: two_percentage
                                                        })
                                                    }}
                                                    onChange={(e) => {
                                                        if ((/(^[1-99]\d*$)/.test(e))) {
                                                            this.setState({
                                                                two_percentage: e
                                                            })
                                                        }
                                                    }}
                                                />
                                            </Radio>
                                        </div>

                                    </Radio.Group>
                                    <div style={{ width: "150px", textAlign: "right" }}>
                                        <span>{intl.get("nav.goodsManagement.promotionalgoods.discount_price")}：</span>
                                        <span>{currency_symbol}{two_discount_price}</span>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", borderBottom: "1px solid #d9d9d9" }}>
                                <Checkbox
                                    value="3"
                                    onChange={(e) => {
                                        if (choose_people.length === 2 && e.target.checked) {
                                            this.setState({
                                                three_fixed: parseFloat(discount_price_save) - 1,
                                                three_percentage: Math.ceil((1 / parseFloat(discount_price_save) * 100)),
                                                three_discount_price: 1,
                                                three_people_money_price_type: "1"
                                            })
                                        } else {
                                            this.setState({
                                                three_people_money_price_type: ""
                                            })
                                        }
                                    }}
                                />

                                <div style={{ width: "120%", height: "100px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <span style={{ marginLeft: "10px" }}>3</span>
                                    <Radio.Group
                                        onChange={(e) => {
                                            this.setState({
                                                three_people_money_price_type: e.target.value,
                                                three_discount_price: e.target.value === "0" ?
                                                    this.accMul(parseFloat(discount_price_save), parseFloat(three_percentage) / 100).toFixed(2) : this.accSub(parseFloat(discount_price_save), parseFloat(three_fixed))
                                            })
                                        }}
                                        value={three_people_money_price_type}
                                    >
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <Radio value={"1"} style={{ marginBottom: "10px" }}>
                                                <span style={{ width: "200px", marginRight: "45px" }}>fixed: </span>
                                                <Input
                                                    prefix={currency_symbol}
                                                    disabled={!(three_people_money_price_type === "1") || !choose_people[2] || !(choose_people[2] === "3")}
                                                    value={three_fixed}
                                                    style={{ width: "100px" }}
                                                    onBlur={(e) => {
                                                        let price = this.accSub(parseFloat(discount_price_save), parseFloat(e.target.value))

                                                        if (price < 1) {
                                                            this.setState({
                                                                three_fixed: this.state.default_three_fixed
                                                            })
                                                            message.error(intl.get("nav.goodsManagement.promotionalgoods.price_less_than_1"))
                                                            return false
                                                        }
                                                        if (parseFloat(price) >= parseFloat(two_discount_price)) {
                                                            this.setState({
                                                                three_fixed: this.state.default_three_fixed
                                                            })
                                                            message.error(intl.get("nav.goodsManagement.promotionalgoods.three_people_cannot_equal_two_person"))
                                                            return false
                                                        }
                                                        this.setState({
                                                            three_discount_price: price,
                                                            default_three_fixed: three_fixed
                                                        })
                                                    }}
                                                    onChange={(e) => {
                                                        e.persist()
                                                        let value = e.target.value.toString().match(/^\d+(?:\.\d{0,2})?/)
                                                        if (value) {
                                                            this.setState({
                                                                three_fixed: value[0]
                                                            })
                                                        } else {
                                                            this.setState({
                                                                three_fixed: ""
                                                            })
                                                        }
                                                    }}
                                                />
                                            </Radio>
                                            <Radio value={"0"}>
                                                <span style={{ width: "200px", marginRight: "10px" }}>percentage:</span>
                                                <InputNumber
                                                    formatter={value => `${value}%`}
                                                    style={{ width: "100px" }}
                                                    disabled={!(three_people_money_price_type === "0") || !choose_people[2] || !(choose_people[2] === "3")}
                                                    value={three_percentage}
                                                    min={0}
                                                    max={100}
                                                    onBlur={(e) => {
                                                        let price = this.accDiv(this.accMul(parseFloat(discount_price_save), parseFloat(e.target.value) || 0), 100).toFixed(2)
                                                        if (parseFloat(price) < 1) {
                                                            this.setState({
                                                                three_percentage: this.state.default_three_percentage
                                                            })
                                                            message.error(intl.get("nav.goodsManagement.promotionalgoods.price_less_than_1"))
                                                            return false
                                                        }
                                                        if (parseFloat(price) >= parseFloat(two_discount_price)) {
                                                            this.setState({
                                                                three_percentage: this.state.default_three_percentage
                                                            })
                                                            message.error(intl.get("nav.goodsManagement.promotionalgoods.three_people_cannot_equal_two_person"))
                                                            return false
                                                        }
                                                        this.setState({
                                                            three_discount_price: price,
                                                            default_three_percentage: three_percentage
                                                        })
                                                    }}
                                                    onChange={(e) => {
                                                        if ((/(^[1-99]\d*$)/.test(e))) {
                                                            this.setState({
                                                                three_percentage: e
                                                            })
                                                        }
                                                    }}
                                                />
                                            </Radio>
                                        </div>
                                    </Radio.Group>
                                    <div style={{ width: "150px", textAlign: "right" }}>
                                        <span>{intl.get("nav.goodsManagement.promotionalgoods.discount_price")}：</span>
                                        <span>{currency_symbol}{three_discount_price}</span>
                                    </div>
                                </div>
                            </div>

                        </Checkbox.Group>
                    </Form.Item>

                }

                <Form.Item
                    label={intl.get('category.list.isShow')}
                >
                    <Switch
                        checked={is_show}
                        onChange={() => {
                            this.setState({
                                is_show: !is_show
                            })
                        }}
                    />
                </Form.Item>
                {
                    !is_disable_language &&
                    <Form.Item label={<span>{intl.get('goods.list.disableLanguage')}</span>}>
                        <Switch checked={disable_language}
                            onChange={() => {
                                this.setState({
                                    disable_language: !disable_language
                                })
                            }} />
                    </Form.Item>
                }
                <Form.Item
                    label={<span>{intl.get('nav.goodsManagement.promotionalgoods.time')}</span>}
                >
                    {getFieldDecorator('date', {
                        rules: [{ required: true }],
                        initialValue: date
                    })(<RangePicker
                        disabled
                        format="DD/MM/YYYY"
                        disabledDate={(current) => {
                            return current && current < moment().subtract(1, "days")
                        }}
                    />)}
                </Form.Item>
                <Form.Item
                    label={intl.get('nav.goodsManagement.promotionalgoods.activity_service_rate_money')}
                >
                    <Input prefix={currency_symbol} value={data.activity_service_rate_money} disabled />
                </Form.Item>

                {
                    is_update_activity_service_rate &&
                    <div>
                        <Form.Item
                            label={intl.get('nav.goodsManagement.promotionalgoods.activity_service_rate')}
                        >
                            <Input value={data.activity_service_rate && parseFloat(data.activity_service_rate) * 100} disabled suffix="%" />
                        </Form.Item>
                        <Form.Item
                            label={intl.get('nav.goodsManagement.promotionalgoods.order_fixed_fee')}
                        >
                            <Input value={data.order_fixed_fee} disabled prefix={currency_symbol} />
                        </Form.Item>
                    </div>
                }
                <Form.Item
                    label={intl.get("subscription.type")}
                >
                    {getFieldDecorator('type', {
                        rules: [{ required: true }],
                        initialValue: data.type
                    })(<Select disabled>
                        {typeData && typeData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                    </Select>)}
                </Form.Item>
                <Form.Item
                    label={intl.get('nav.goodsManagement.promotionalgoods.stock')}
                >
                    {getFieldDecorator('stock', {
                        rules: [{ required: true, pattern: new RegExp(/^[1-9]\d*$/, "g"), message: intl.get("nav.goodsManagement.promotionalgoods.integer") }, { validator: (rule, value, callback) => { this.checkStock(rule, value, callback) } }],
                        initialValue: data.total_stock
                    })(<Input disabled />)}
                </Form.Item>
                <Form.Item
                    label={intl.get('nav.goodsManagement.promotionalgoods.sale_quantity')}
                >
                    <Input disabled value={data.sale_quantity} />
                </Form.Item>
                <Form.Item
                    label={intl.get('nav.goodsManagement.promotionalgoods.Remaining_inventory')}
                >
                    <Input disabled value={data && data.stock} />
                </Form.Item>
                <Form.Item
                    label={
                        <span>
                            {intl.get('nav.goodsManagement.promotionalgoods.quota')}
                            <Tooltip title={intl.get("nav.goodsManagement.promotionalgoods.total_quota")}>
                                <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                            </Tooltip>
                        </span>
                    }
                >
                    {getFieldDecorator('quota', {
                        rules: [{ required: false, pattern: new RegExp(/^[1-9]\d*$/, "g"), message: intl.get("nav.goodsManagement.promotionalgoods.integer") }],
                        initialValue: data.quota
                    })(<Input type="number" />)}
                </Form.Item>
                <Form.Item
                    label={
                        <span>
                            {intl.get('nav.goodsManagement.promotionalgoods.max_quantity')}
                            <Tooltip title={intl.get("nav.goodsManagement.promotionalgoods.Single_max_quantity")}>
                                <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                            </Tooltip>
                        </span>
                    }
                >
                    {getFieldDecorator('max_quantity', {
                        rules: [{ required: false, pattern: new RegExp(/^[1-9]\d*$/, "g"), message: intl.get("nav.goodsManagement.promotionalgoods.integer") }],
                        initialValue: data.max_quantity
                    })(<Input type="number" />)}
                </Form.Item>
                <Form.Item
                    label={
                        <span>
                            {intl.get('nav.goodsManagement.promotionalgoods.min_quantity')}
                            <Tooltip title={intl.get("nav.goodsManagement.promotionalgoods.Single_min_quantity")}>
                                <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                            </Tooltip>
                        </span>
                    }
                >
                    {getFieldDecorator('min_quantity', {
                        rules: [{ required: false, pattern: new RegExp(/^[1-9]\d*$/, "g"), message: intl.get("nav.goodsManagement.promotionalgoods.integer") }],
                        initialValue: data.min_quantity
                    })(<Input type="number" />)}
                </Form.Item>


                {/* <Form.Item
                    label={intl.get('nav.goodsManagement.promotionalgoods.expire_day')}
                >
                    {getFieldDecorator('expire_day', {
                        rules: [{ required: false }],
                        initialValue: data.expire_day
                    })(<Input />)}
                </Form.Item> */}
                <Form.Item
                    label="Qr Code"
                >
                    <img src={data.qrcode_url} style={{ width: "100px" }} />
                </Form.Item>
                <Form.Item
                    label="Share link"
                >
                    <span>{data.share_url}</span>
                </Form.Item>
                <Form.Item
                    label={intl.get('nav.goodsManagement.promotionalgoods.detail')}
                >
                    <div style={{
                        border: "1px solid #dedede",
                    }}>
                        <BraftEditor
                            value={editorState}
                            language={Number(getLangType()) == 1 ? "zh" : "en"}
                            onChange={(editorState) => {
                                this.setState({ editorState })
                            }} />
                    </div>
                </Form.Item>


                <Form.Item {...tailFormItemLayout}>

                    <Popconfirm
                        title={intl.get('seat.table.btn.cancel.placeHorder')}
                        onConfirm={() => {
                            this.props.history.goBack()
                        }}>
                        <Button
                            type="primary"
                            style={{ marginRight: "10px" }}
                        >
                            {intl.get('seat.table.btn.cancel')}
                        </Button>
                    </Popconfirm>
                    {
                        type !== 1 &&
                        <Button type="primary" htmlType="submit">
                            {intl.get('cashwithdrawal.form.submit')}
                        </Button>
                    }
                </Form.Item>


            </Form >
        );
    }
}

export default withRouter(Form.create()(EditPromotionalGoods))
